@charset "UTF-8";
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+TC:100,400,700&display=swap');

* {
  padding: 0;
  margin: 0;
  list-style: none;
  font-family: 'Noto Sans TC', sans-serif;
  /* color: #333333; */
}

html {
  overflow-y: auto;
}

ul {
  margin-bottom: 0;
}

li {
  /* display: inline-block;
  width: 100%;
  padding-top: 3px;
  padding-bottom: 3px; */
}

li:hover {
  /* background-color: #f5f5f5; */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* -------- common ----------- */
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.err-msg {
  color: red;
}

::-webkit-scrollbar {
  width: 12px;
}

/* this targets the default scrollbar (compulsory) */
::-webkit-scrollbar-track {
  background-color: #eeeeee;
  border-radius: 30px;
}

/* the new scrollbar will have a flat appearance with the set background color */
::-webkit-scrollbar-thumb {
  background-color: #e8e8e8;
  border-radius: 30px;
}

/* this will style the thumb, ignoring the track */
::-webkit-scrollbar-button {
  border-radius: 30px;
}

/* optionally, you can style the top and the bottom buttons (left and right for horizontal bars) */
::-webkit-scrollbar-corner {
  background-color: #e8e8e8;
}

/* if both the vertical and the horizontal bars appear, then perhaps the right bottom corner also needs to be styled */


/* -------- login-section ----------- */
.login-section {
  background-color: #f5f5f5;
  width: 100vw;
  height: 100vh;
  text-align: center;
}

.login-section .sk-box {
  height: 240px;
}

.login-logo {
  width: 288px;
  height: 51px;
  margin-top: 10%;
}

.login-logo-bg {
  position: fixed;
  bottom: -10em;
  right: 0;
  width: 200px;
  transform: rotate(45deg);
  opacity: 0.4;
}

.login-newmember {
  margin-top: 20px;
  /* display: flex;
  justify-content: space-between; */
  text-align: center;
  font-size: 14px;
  color: #104378;
}

.login-newmember a {
  color: rgb(36, 146, 235);
  text-decoration: none;
  font-size: 14px;
  display: inline-block;
  padding: 5px;
}

.login-form {
  text-align: left;
  width: 310px;
  margin: 0px auto;
  margin-top: 30px;
  display: grid;
}

.login-form-label {
  color: #104378;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  margin: 4px;
  margin-left: 10px;
  margin-top: 10px;
}

.login-form-input {
  border-radius: 20px;
  border: 1px solid #104378;
  margin: 4px;
  height: 30px;
  background-color: transparent;
  padding-left: 20px;
  font-size: 16px;
}

.login-form-input:focus {
  outline: none;
}

.login-form-bottom {
  margin-top: 20px;
  padding: 5px;
}

.login-form-forgotpw {
  font-size: 12px;
  font-weight: normal;
  color: #777777;
  text-decoration: none;
  float: right;
  margin-right: 5px;
}
.login-form-forgotpw:hover {
  color: #2492EB;
}
.login-form-forgotpw::after {
  clear: both;
}

.login-form-submit-btn {
  background-color: #2492EB;
  border-radius: 20px;
  border: 0px;
  padding: 5px;
  width: 100%;
  height: 35px;
  color: #FFFFFF;
  font-family: Roboto;
  font-size: 16px;
}

.login-form-submit-btn:hover {
  cursor: pointer;
  box-shadow: 0 10px 6px rgba(0, 0, 0, .2);
  transition: all .2s ease-in-out;
}

/* ------ profile ------- */
nav h4 {
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
}
#page-profile {
  min-height: 100vh
}
.profile_wrap {
  padding: 0 60px;
}
.profile_disable {
  border-right: 1px solid #dee2e6;
}
@media screen and (max-width: 768px) {
  .profile_wrap {
    padding: 0 15px;
  }
  .profile_disable {
    border-right: none;
  }
  .profile_disable ul li.profile-item {
    width: 60%;
  }
}
.profile_disable ul li.qrcode {
  text-align: center;
}
.profile_disable ul li {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}

.profile_info ul li:hover,
.profile_disable ul li:hover {
  background-color: transparent;
  cursor: default;
}

.profile_disable ul li div:first-child {
  display: inline-block;
  width: 64px;
  text-align: right;
}

.profile_disable ul li div:last-child {
  display: inline-block;
}
.profile_disable .profile-logo {
  /* margin-top: 65px; */
}
.profile_disable .profile-logo img {
  width: 100%;
  height: auto;
}
.upload-item {
  border-top: 1px solid #dddddd;
  padding-top: 15px;
}
.upload-logo .ant-btn > span {
  font-size: 1rem;
}
.upload-logo i {
  margin-right: 5px;
}
.upload-logo button[type="submit"] {
  flex-shrink: 0;
}

/* -------- docs-section ----------- */
/* .cases-section {
  width: 100%;
} */

.cases-header {
  display: inline-block;
  margin-left: 50px;
}

.cases-header > button {
  color: #2492EB;
  background-color: transparent;
  padding: 5px 35px;
  border-color: #2492EB;
  border-radius: 20px;
  margin-right: 10px;
}

.cases-header > button:hover {
  cursor: pointer;
}

.cases-header > button > a {
  text-decoration: none;
}

.cases-header > button > a:hover {
  color: #2492EB;
}

nav .set {
  float: right;
}

nav .set button {
  background-color: transparent;
  border: none;
}

.cases-generator-info {
  font-size: 12px;
  color: #999999;
}
/*
.cases-section .table-style {
  background-color: #FCFCFC;
  height: 100vh;
}

.cases-section .table-style .table-title {
  color: #104378;
  font-family: Roboto;
  font-size: 18px;
  margin: 10px;
  padding: 9px 0;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  list-style-type: none;
} */

/* .cases-section .table-style .table-item {
  color: #104378;
  font-family: Roboto;
  font-size: 16px;
  padding: 10px 5px 1px;
  border-radius: 2px;
  display: flex;
  list-style-type: none;
}

.cases-section .table-style .table-item:hover {
  background-color: #FAFAFA;
}

.cases-section .table .table-item-content {
  background-color: transparent;
  font-size: .875rem;
}

.cases-section .table .table-item-content ul {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}

.cases-section .table .table-item-content ul > li.case-edit-item {
  display: block;
  cursor: default;
  padding-left: 5px;
  padding-right: 5px;
}

.cases-section .table .table-item-content .case-content-title {
  display: inline-block;
  width: 9em;
}

.cases-section .table .table-item-content .case-content::after {
  content: '、';
}
.cases-section .table .table-item-content .case-content:last-child::after {
  content: '';
}
.cases-section .table .table-item-content .case-edit-content {
  width: calc(100% - 11em);
  display: inline-block;
  vertical-align: top;
}
.cases-section .table .table-item-content .case-edit-content-item {
  display: inline-block;
}
.cases-section .table .table-item-content .case-people {
  border: 1px solid #dddddd;
  box-sizing: border-box;
  padding: 10px;
  margin-top: 5px;
}
.cases-section .table .table-item-content .case-people .case-content-title {
  width: 8em;
}
.cases-section .table .table-item-content .case-people .item-edit {
  width: calc(100% - 9.5em);
}
.cases-section .table-style li {
  text-align: left;
  padding-right: 5px;
}

.cases-section .table-style li:first-of-type {
  width: 35%;
  text-align: left;
  padding-left: 20px;
}

.cases-section .table-style li:first-of-type:hover {
  cursor: pointer;
} */

.case_type {
  width: 45%;
}
tr.table-item > td > i {
  cursor: pointer;
}

/* meeting-section */
/* .meeting-section .meeting-wrap {
  box-sizing: border-box;
  padding: 5px;
  background-color: #ffffff;
  border: 1px solid #dddddd;
}
.meeting-section .meeting-wrap .meeting-col {
  display: inline-block;
  vertical-align: top;
}
.meeting-section .meeting-wrap .meeting-title {
  display: inline-block;
  vertical-align: top;
  width: 5em;
}
.meeting-section .meeting-wrap .meeting-select {
  width: 6em;
}
.meeting-section .meeting-wrap .meeting-input {
  width: calc(100% - 6em);
}
.meeting-section .edit .caseEdit-content-submit-btn {
  display: block;
  background-color: #ffffff;
  padding: 3px 10px;
  color: #80bdff;
  border: 1px solid #80bdff;
  border-radius: 3px;
  width: 100%;
}
.meeting-section .edit .caseEdit-content-submit-btn:focus {
  outline: none;
}
.meeting-section .edit .caseEdit-content-submit-btn:hover {
  background: #80bdff;
  color: #ffffff;
}
.meeting-section .edit .caseEdit-content-submit-btn.save {
  color: #28a745;
  border: 1px solid #28a745;
}
.meeting-section .edit .caseEdit-content-submit-btn.save:hover {
  background: #28a745;
  color: #ffffff;
}
.meeting-section .edit .caseEdit-content-submit-btn.cancel {
  color: #ffc107;
  border: 1px solid #ffc107;
}
.meeting-section .edit .caseEdit-content-submit-btn.cancel:hover {
  background: #ffc107;
  color: #ffffff;
} */

.modalbox {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
  z-index: 999;
}

.modalbox-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 1rem 1.5rem;
  width: 50%;
  border-radius: 0.5rem;
  color: #104378;
  font-family: Roboto;
  font-size: 14px;
}
.modalbox-content .tip {
  color: rgb(138, 138, 138);
  font-size: .75rem;
}
.modalbox-content .level {
  padding: 0 15px;
  margin-bottom: 10px;
}
.modalbox-content .level .item-col {
  display: inline-block;
  vertical-align: top;
}
.modalbox-content .level .item-col:first-child {
  width: 130px;
}
.modalbox-content .level .item-col:first-child .input-content {
  display: inline-block;
  vertical-align: top;
  width: 5em;
}
.modalbox-content .level .item-col .input-content {
  margin: 0 5px;
  width: 7em;
}
.modalbox-content .level .item-col .input-content-s {
  margin: 0 5px;
  width: 3em;
}
/* 新增案件-當事人(instance) */
.modalbox-content .instance {
  border: 1px solid #d6d6d6;
}
.modalbox-content .instance .title {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 10px;
}
.modalbox-content .input-item {
  margin-bottom: 7px;
}
.modalbox-content .input-item .input-title {
  display: inline-block;
  vertical-align: top;
  width: 5em;
}
.modalbox-content .input-item .input-title.title-long {
  padding-right: 1em;
}
.modalbox-content .input-item .input-content {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 5em);
}
/* 新增案件-上傳區 */
.modalbox-content .input-upload {
  border-top: 1px solid #104378;
  border-bottom: 1px solid #104378;
  padding: 10px 0;
  margin-top: 5px;
}
.modalbox-content .input-upload .upload {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  line-height: 32px;
}
.modalbox-content .input-upload .upload:hover {
  cursor: pointer;
}

.case-form .modalbox-content {
  width: 80%;
  max-height: 80vh;
  overflow-y: auto;
}
@media screen and (max-width: 768px) {
  .case-form .modalbox-content {
    width: 90%;
  }
}

.modalbox.show {
  opacity: 1;
  visibility: visible;
  transform: scale(1.0);
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}

.modalbox-content-input {
  font-family: Roboto;
  width: calc(100% - 90px) !important;
  float: right;
  height: 30px;
  border-radius: 20px !important;
  border: 1px solid #104378 !important;
  background-color: transparent !important;
  padding-left: 15px;
  padding-right: 15px;
}

.modalbox-content-input::after {
  clear: both;
}

.modalbox-content-input:hover {
  border-color: #1890ff !important;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.modalbox-content-input:focus {
  outline: none !important;
}

.modalbox-content-input .ant-select-selection {
  background-color: transparent !important;
  border-color: #1890ff !important;
  border: 0px !important;
  /* outline: none !important;
  border-top-width: 0px !important;
  border-radius: 20px !important; */
  outline: none !important;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

.modalbox-content-input .ant-select-selection:focus {
  outline: none !important;
}

.modalbox-content-input .ant-select-selection:active {
  outline: none !important;
}
#cases-generator-keyword > div > .ant-select-selection__rendered > ul,
#cases-generator-name > div > .ant-select-selection__rendered > ul {
  width: 100%;
}
.ant-select-selection__rendered > ul > li {
  width: auto;
}
.modalbox-content-input.radio-box {
  border-color: #FFFFFF !important;
}

.modalbox-content-input.radio-box .radio-label {
  width: 50px;
}
.modalbox-content-input.radio-box .radio-label input {
  margin-right: 5px;
}
.modalbox-content-input.radio-box .radio-label:hover {
  cursor: pointer;
}

p.cases-related {
  display: flex;
  justify-content: space-between;
}

p.cases-related .modalbox-content-input {
  height: auto;
}

.modalbox .modalbox-bottom {
  margin: 20px 0;
  margin-bottom: 0px;
  line-height: 1.5rem;
}
.modalbox .modalbox-bottom-close-btn,
.modalbox .modalbox-bottom-submit-btn {
  display: inline-block;
  vertical-align: top;
  margin: 1%;
  border: 1px solid #104378;
  border-radius: 3px;
  background-color: transparent;
  width: 48%;
  font-size: 1.25rem;
  color: #104378;
  padding: 10px;

}
.modalbox .modalbox-bottom-submit-btn {
  background-color: #104378;
  color: #ffffff;
}
.modalbox .modalbox-bottom-submit-btn:hover {
  border-color: #2492EB;
  background-color: #2492EB;
}
.modalbox .modalbox-bottom-close-btn:hover {
  border-color: #2492EB;
  color: #2492EB;
}

.fix_focus_shadow {
  padding-left: 0;
  padding-right: 0;
  margin-top: -2px;
}

/* -------- edit cases content ----------- */

form .caseEdit-content-input {
  width: calc(100% - 80px);
}

.table-item-content .caseEdit-content-submit-btn {
  padding: 3px 10px;
  color: #80bdff;
  border: 1px solid #80bdff;
  border-radius: 3px;
  background-color: #ffffff;
}
.table-item-content .caseEdit-content-submit-btn:focus {
  outline: none;
}
.table-item-content .caseEdit-content-submit-btn:hover {
  color: #ffffff;
  border: 1px solid #80bdff;
  background-color: #80bdff;
}
.table-item-content .caseEdit-content-submit-btn.save {
  color: #28a745;
  border: 1px solid #28a745;
}
.table-item-content .caseEdit-content-submit-btn.save:hover {
  background: #28a745;
  color: #ffffff;
}
.table-item-content .caseEdit-content-submit-btn.cancel {
  color: #ffc107;
  border: 1px solid #ffc107;
}
.table-item-content .caseEdit-content-submit-btn.cancel:hover {
  background: #ffc107;
  color: #ffffff;
}

/* -------- search ----------- */
.adv-search-section {
  display: none;
}
input.adv-search-section-visible {
  display: none;
  opacity: 0;
}
input.adv-search-section-visible + label {
  margin-top: 10px;
  margin-left: 90px;
  padding: 3px 15px;
  border: 1px solid #28a745;
  border-radius: 5px;
  font-size: 14px;
  color: #28a745;
  letter-spacing: 5px;
  text-indent: 5px;
}
input.adv-search-section-visible + label:hover,
input.adv-search-section-visible:checked + label {
  cursor: pointer;
  border-color: #28a745;
  background: #28a745;
  color: #ffffff;
}
input.adv-search-section-visible:checked ~ .adv-search-section {
  display: block;
}

.search_section .search_row {
  padding-top: 0;
  padding-right: 10px;
  padding-bottom: 7px;
  padding-left: 10px;
}

.search_col {
  display: inline-block;
  width: 33.3%;
}

.search_title {
  font-size: 20px;
  margin: 0;
  padding-left: 20px;
}

.search_item {
  font-size: 14px;
  float: left;
  margin: 0;
  width: 80px;
  padding: 2px 5px;
  text-align: right;
  vertical-align: top;
}

.search_content {
  margin-left: 80px;
}

.search_content label {
  margin: 0;
}

.input_text {
  font-size: 14px;
  display: inline-block;
  vertical-align: top;
  padding: 2px 5px;
  margin: 0;
}

.input_lengh_M {
  display: inline-block;
  width: 7%;
}

.input_lengh_L {
  display: inline-block;
  width: 15%;
}

.search_input {
  font-size: 14px;
  display: inline-block;
  box-sizing: border-box;
  width: calc(100% - 90px);
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 2px 5px;
  vertical-align: top;
  background-color: #fff;
  transition: border-color 0.15s ease-in-out;
  box-shadow: 0.15s ease-in-out;
}

.search_input:focus {
  /* width: auto; */
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.search_article {
  width: 145px;
}

.search_article > .search_input {
  width: 100px;
}

.select_item {
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  padding: 2px 5px;
}

.select_title {
  display: inline-block;
  vertical-align: top;
}

.select_item > label {
  margin: 0;
  padding: 0 5px;
}

.search_select {
  display: inline-block;
  padding-bottom: 3px;
  margin-bottom: 3px;
  border-bottom: 1px solid #ccc;
  width: 100%;
}

.search_select > .select_item:first-child {
  width: 123px;
  float: left;
}

.search_content > .search_select:nth-child(4) > .select_item:first-child,
.search_content > .search_select:nth-child(5) > .select_item:first-child {
  /* height: 54px; */
}

.search_section:nth-child(2) > .search_row:nth-child(5) > .search_content > .search_select:nth-child(2) > .select_item:first-child {
  height: 100px;
}

.search_wrap,
.search_result {
  width: 1200px;
  margin: auto;
  padding-bottom: 20px;
}

.search_filter,
.article_wrap {
  box-sizing: border-box;
  border: 1px solid #c9c9c9;
  border-radius: 10px;
  box-shadow: 0 3px 1px 0 rgba(20, 23, 28, .1);
  padding: 15px;
}

.search_tooltip {
  font-size: 12px;
  margin: 0;
  padding-left: 85px;
}

.prompt {
  font-size: 12px;
}

.prompt li:first-child ~ li {
  padding-left: 16px;
}

.prompt li:hover {
  background-color: transparent;
  cursor: auto;
}

.search_result .sk-box {
  height: 30px;
}

.search_result {
  min-height: 655px;
}

.result {
  position: relative;
}

.result_nav {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
}

.search_filter .checkbox,
.form-check .form-check-input {
  position: absolute;
  cursor: pointer;
  z-index: 1;
  opacity: 0;
  display: none;
}

.list-group-item {
  border: 1px solid #aaa;
}

.list-group-item > label {
  margin-bottom: 0;
  cursor: pointer;
}

.search_filter .more {
  max-height: 0px;
  overflow: hidden;
  transition: max-height 1s;
}

.search_filter .checkbox:checked ~ .more {
  max-height: 1200px;
  display: block;
}

.more .form-check {
  margin-right: 0;
}

.more .form-check-input:checked + label {
  color: #ffffff;
  background-color: #2492EB;
}

.list-group li:nth-child(2),
.list-group li:nth-child(3) {
  padding: .5rem .75rem;
}

.list-group li:nth-child(2) > label,
.list-group li:nth-child(3) > label {
  display: inline-block;
  padding: .25rem .5rem;
  border-radius: 5px;
}

.list-group li:nth-child(2) .checkbox:checked + label,
.list-group li:nth-child(3) .checkbox:checked + label {
  color: #ffffff;
  background-color: #2492EB;
}

.year_case {
  padding-left: .5rem;
  margin-bottom: 0.5rem;
}

.year_case_label {
  margin-bottom: 0;
  padding-top: 0.5rem;
}

.input_label {
  position: relative;
}

.input_label::before {
  content: '~';
  position: absolute;
  top: .5rem;
  left: -.5rem;
}

.table tbody > tr > td.add_case {
  padding: 0;
  width: 40px;
  display: inline-block;
  height: 48px;
}

.table tbody > tr > td.add_case button {
  border: none;
  background-color: transparent;
  display: inline-block;
  width: 100%;
  height: 100%;
  color: #aaa;
}

.table tbody > tr > td.add_case button:hover {
  color: #2492EB;
}

.table tbody tr td:nth-last-child(2) {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;
}

/* -------- case search ----------- */
.cases-section .search_wrap {
  width: auto;
}
.caseSearch_filter {
  padding: 10px 0;
}
.caseSearch_filter .caseSearch_col {
  width: 50%;
}
.caseSearch_filter .caseSearch_col .caseSearch_item {
  width: 6em;
}
.caseSearch_filter .caseSearch_col:first-child .caseSearch_item {
  width: 10em;
}
.caseSearch_filter .caseSearch_col:first-child .caseSearch_input {
  width: calc(100% - 10em);
}
.caseSearch_filter .caseSearch_col .caseSearch_input {
  width: calc(100% - 6em);
}
.caseSearch_filter .caseSearch_section button {
  width: 7em;
  margin-left: 15px;
  font-size: .75rem;
}
/* .table tbody tr td:last-child ul {
  max-height: 140px;
  overflow: auto;
} */

/* -------- search Extend----------- */
.article div:first-child {
  float: left;
  width: 100px;
  color: #2492EB;
}

.article div:last-child {
  margin-left: 100px;
  padding: 0 30px;
  border-left: 2px solid #aaa;
}

.back_search a {
  color: #ffffff;
  background-color: #2492EB;
  border-radius: 5px;
  padding: 15px;
  position: fixed;
  bottom: 0;
  margin-left: -130px;
  margin-bottom: 100px;
  text-decoration: none;
}

.back_search a:hover {
  background-color: #0f4377;
  box-shadow: 0 10px 6px rgba(0, 0, 0, .2);
}

.past_case .sk-box {
  height: 150px;
}

/* 新版 */
.edit_icon {
  /* display: inline-block;
  width: auto;
  float: right;
  margin-left: 5px; */
}

.button_icon {
  background-color: transparent;
  border: none;
}

.editTool > .edit_icon {
  /* margin-left: 0; */
}

.doc-left-box {
  position: absolute;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

table {
  margin-top: 10px;
}

nav > img {
  cursor: pointer;
}

th {
  cursor: pointer;
}

ul > li {
  /* cursor: pointer; */
}

.logo {
  width: 150px;
}
.logo:hover {
  cursor: pointer;
}

/* review */
mark {
  background-color: yellow;
}

.duplicateList {
  color: #b9b9b9;
  text-align: center;
  line-height: 55px;
}

/* common */
.wrap {
  font-size: 0;
  width: 100%;
  background-color: #fbfbfb;
}

.full-spinner-box {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-link {
  padding: .75rem 1rem;
}

.nav-item > a {
  position: relative;
  color: #cccccc;
  font-weight: 300;
}

.nav-item > a:hover {
  color: #ffffff;
  font-weight: 500;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #ffffff;
  font-weight: 500;
  background-color: transparent;
}

.nav-pills .nav-link.active::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  left: 0;
  bottom: 0;
  border-bottom: 8px solid #ffffff;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  margin: 0 30%;
}

.generator_tabContent {
  width: 100%;
  height: calc(100% - 115px);
  padding: 0 0 30px 0;
  overflow: auto;
}

.generator_tabContent .action {
  /* float: right; */
  color: #999999;
}

.addPagetype > .action:hover,
.generator_tabContent .action:hover {
  color: #007bff;
  cursor: pointer;
}

.generator_tabContent .page-type-break-input-error {
  border-color: red;
}

.generator_title .logo {
  width: 35px;
  margin: 0;
}

.generator_title h2 {
  font-size: 16px;
  line-height: 32px;
  margin-bottom: 0;
}

.file_title {
  display: inline-block;
  vertical-align: top;
  width: 96%;
}

.haveItem {
  width: 12px;
  line-height: 27px;
  flex: 0 0 12px;
}

.haveItemIcon {
  color: #104378;
}

.system_item {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  display: inline-flex;
  border-bottom: 1px solid #dee2e6;
  flex-wrap: wrap;
}

.system_item:hover {
  background-color: #f5f5f5;
}

.system_item:not(.breaking):hover .edit_tool {
  display: inline-flex;
}

.system_item:hover .migrate_edit_tool {
  display: inline-flex;
}

.system_item.breaking {
  background-color: #eee;
  padding-bottom: 15px;
}

.system_item.breaking .edit_tool{
  display: inline-flex;
}

.system_item.breaking .edit_tool p:not(.edit_tool_breaking){
  display: none;
}

.system_item > ul {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.tag_item > ul {
  width: calc(100% - 12px);
}

.system_item.system_child > ul {
  width: 100%;
}

.system_item.system_item_child > ul{
  width: calc(100% - 12px);
}

.system_item > .statusId_failure {
  flex-direction: column;
}

.system_item > .ul_col {
  flex-direction: column;
  padding-left: 15px;
}

.system_item > ul > .item_number {
  width: 75px;
  flex: 0 0 75px;
}

.system_item > ul > .item_number.empty {
  flex: 0 0 24px;
}

.item_number > span {
  display: inline-block;
  text-align: center;
  width: 32px;
}

.item_number > span:nth-of-type(2) {
  display: inline-block;
  width: 10px;
}

.system_item > .serial-number {
  width: 25px;
  margin-right: 2px;
}

.serial-number > select {
  width: 1.5rem;
  margin-top: 2px;
  background-color: transparent;
  border: none;
  margin-right: 5px;
  color: #17a2b8;
  text-align: center;
  text-align-last: center;
  -moz-outline-radius: 2px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.serial-number > select:focus {
  outline-color: transparent;
}
.serial-number > select:hover {
  cursor: pointer;
}
.serial-number select::-ms-expand {
  display: none;
}

.system_item > ul > li.item_title {
  width: 100%;
}

.statusId_wait {
  color: #b9b9b9;
}

.system_item > ul > .item_number_title {
  width: 100%;
}

.system_item > ul > .item_migrate_title {
  width: calc(100% - 75px - 22px);
}

.system_item > ul > .item_number_types {
  width: calc(100% - 75px);
}

.item_number_types > span:first-of-type {
  display: inline-block;
  width: 100%;
}

.edit_tool {
  width: auto;
  flex: 0;
  display: none;
  justify-content: flex-end;
}

.migrate_edit_tool {
  width: auto;
  flex: 0;
  display: none;
  justify-content: flex-end;
}

.migrate_edit_tool > p,
.edit_tool > p {
  text-align: center;
  color: #999999;
  width: 22px;
  padding-left: 5px;
  margin: 0;
}

.migrate_edit_tool > p:hover,
.edit_tool > p:hover {
  color: #007bff;
  cursor: pointer;
}

.last_item {
  margin-left: 38px;
}

.pageNos {
  word-wrap: break-word;
}

.table-item > li {
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 1px solid #dee2e6;
}

.table-item-title {
  display: inline-block;
  width: 100%;
}

.table-item-last {
  padding-left: 15px;
  padding-right: 15px;
}

.file_name {
  display: inline-block;
  vertical-align: top;
  width: 100%;
}

.items_number {
  display: inline-block;
  vertical-align: top;
  width: 26%;
  min-width: 70px;
}

.items_text {
  display: inline-block;
  vertical-align: top;
  width: 54%;
}

.pageType_items_text {
  display: inline-block;
  vertical-align: top;
  width: 73%;
}

.editTool {
  display: inline-block;
  vertical-align: top;
  width: 20%;
}

.item_icon {
  display: inline-block;
  vertical-align: top;
  width: 20px;
}

.items_info {
  padding-left: 80px;
}

.upload-feils .modalbox-content {
  width: 400px;
  max-height: 70vh;
  overflow-y: auto;
}

.upload-feils .modalbox-content .col-item-tool {
  top: 15px;
  right: 10px;
}

.items_third div {
  display: inline-block;
  padding-left: 63px;
}

.download_pdf,
.add_keyword {
  margin-bottom: 5px;
  float: right;
}

.download_pdf,
.add_keyword::after {
  content: '';
  clear: both;
}

/* review-view */
.view_block {
  width: 50%;
  /*top: 0;*/
  /*bottom: 0;*/
  /*left: 25%;*/
}

.view_page {
  display: inline-block;
  padding: 3px 0;
}

.view_page + input {
  display: inline-block;
  width: 60px;
}

.icon_tool_disabled i {
  cursor: default;
  color: #cccccc;
  border: 1px solid #cccccc;
}

.icon_tool_disabled i:hover {
  color: #cccccc;
  border: 1px solid #cccccc;
  cursor: default;
}

.icon_tool {
  height: 18px;
}

/* review-label */
.label-form .modalbox-content {
  width: 300px;
}
.label-form .form-item {
  margin-bottom: 10px;
  display: flex;
}
.label-form .form-item .item-title {
  text-align: right;
  display: inline-block;
  width: 5em;
}
.label-form .modalbox-content label {
  margin: 0px;
  line-height: 32px;
}
.label-form .form-item select {
  padding: 0 10px;
}
.label-form .col-item-tool {
  margin-top: 10px;
  position: relative;
  display: flex;
  justify-content: flex-end;
}
.label-form .col-item-tool button {
  flex-shrink: 0;
}
/* pdf-viewer sidebar */
.pdf-viewer .highlight-location {
  margin-top: 0.5rem;
  text-align: right;
  font-size: 10px;
}

.pdf-viewer .highlight-image {
  margin-top: 0.5rem;
  overflow: auto;
  max-width: 300px;
  border: 1px dashed;
}

.pdf-viewer blockquote {
  margin-top: 0.5rem;
  padding: 0;
  quotes: "\201c" "\201d";
}

.pdf-viewer blockquote:before {
  content: open-quote;
}

.pdf-viewer blockquote:after {
  content: close-quote;
}

/* doc-search-section */
.doc-search-section {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 3;
  width: 280px;
  max-height: 170px;
  font-size: 20px;
}

.doc-search-section .big-search-btn {
  background-color: #104378;
  color: #cccccc;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  position: absolute;
  top: 10px;
  right: 20px;
  opacity: 0.5;
}

.doc-search-section .big-search-btn:hover {
  opacity: 1;
  color: #ffffff;
}

.doc-search-section .big-search-btn .search-icon {
  left: 25%;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 25%;
}

.doc-search-box {
  background-color: rgb(16, 67, 120, 0.1);
  color: #b9b9b9;
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 3;
  width: 280px;
  border-radius: 10px;
  max-height: 170px;
}

.doc-search-box:hover {
  background-color: rgb(16, 67, 120, 1);
  color: #cccccc;
}

.doc-search-form {
  height: 40px;
  border-radius: 10px;
}

.doc-search-form.after-search {
  border-radius: 10px 10px 0px 0px;
  border-bottom: 2px solid #ffffff;
}

.doc-search-form .search-word {
  border: 0px;
  line-height: 40px;
  margin-left: 10px;
  width: 160px;
  background: none;
}

.doc-search-form .search-word:focus {
  color: #ffffff;
  outline: none;
}

.doc-search-form .action-box {
  height: 40px;
  width: 40px;
  position: absolute;
}

.doc-search-form .action-box:hover {
  color: #ffffff;
  cursor: pointer;
}

.action-box .action-icon {
  position: absolute;
  height: 20px;
  width: 20px;
  top: 25%;
  left: 25%;
}

.action-box.search-btn {
  box-shadow: none;
  border: none;
  text-shadow: none;
  background: none;
}

.action-box.search-btn:focus {
  outline: none;
}

.action-box.cancel-btn {
  margin-left: 40px;
}

.action-box.hide-btn {
  width: 30px;
  margin-left: 80px;
  padding-left: 5px;
  border-left: 1px solid #ffffff;
}

.search-btn .action-icon {
  left: 40%;
}

.hide-btn .action-icon {
  width: 20px;
}

.doc-search-result {
  overflow-y: auto;
  max-height: 120px;
}

.search-result-item .result-title {
  margin: 0px;
  padding-left: 10px;
  font-size: 16px;
}

.search-result-item .result-title.highlight {
  opacity: 0.5;
  color: #000000;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.search-result-item .result-pages {
  margin: 0px;
  padding-left: 10px;
  border-bottom: 1px solid #ffffff;
  word-wrap: normal;
  padding-bottom: 10px;
  font-size: 14px;
}

/* pdf-viewer spinner */
.sk-box {
  display: flex;
  align-items: center;
  height: 100vh;
}

.sk-fading-circle {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}

.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

@keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

@media (max-width: 767.98px) {
  .login-logo {
    margin-top: 150px;
  }

  .login-logo-bg {
    bottom: -18em;
    left: 0;
    width: 180px;
    transform: rotate(90deg);
  }
}

@media screen and (max-width: 1366px) {
  .system_item > ul {
    /*flex-wrap: wrap;*/
  }

  .migrate_edit_tool,
  .edit_tool {
    width: 100%;
    align-items: flex-end;
  }

  .system_item > ul > .item_migrate_title,
  .system_item > ul > .item_number_title {
    width: calc(100% - 75px);
  }

  .system_item > ul > .item_number_types {
    width: 100%;
  }

  .last_item {
    margin-left: 30px;
  }
}

@media screen and (max-width: 768px) {
  .system_item > ul {
    /*flex-direction: column;*/
  }

  .system_item > ul > .item_number_title {
    width: 100%;
  }

  .migrate_edit_tool,
  .edit_tool {
    display: inline-flex;
  }
}
