@charset "UTF-8";
/* line 1, ../sass/_global.scss */
#root, #root > div {
  width: 100%;
  height: 100%;
}

/* line 5, ../sass/_global.scss */
.required {
  position: relative;
}
/* line 7, ../sass/_global.scss */
.required::before {
  content: '*';
  position: absolute;
  top: 2px;
  left: -8px;
  line-height: 1em;
  color: red;
}

/* line 17, ../sass/_global.scss */
.popPage-background {
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
}

/* line 26, ../sass/_global.scss */
.popPage-section {
  position: fixed;
  display: block;
  top: 90px;
  left: calc(50% - 300px);
  background-color: #ffffff;
  padding: 40px;
  width: 600px;
  height: 80%;
  border-radius: 5px;
  border: 1px solid #c9c9c8;
  z-index: 1000;
  animation: zoom-in 0.3s linear 1;
}
/* line 39, ../sass/_global.scss */
.popPage-section iframe {
  width: 100%;
  height: 100%;
}
/* line 43, ../sass/_global.scss */
.popPage-section button.popPage-close {
  background-color: #ffffff;
  border-radius: 24px;
  position: absolute;
  top: -24px;
  right: -24px;
}
/* line 49, ../sass/_global.scss */
.popPage-section button.popPage-close svg {
  font-size: 3rem;
}

@media screen and (max-width: 576px) {
  /* line 56, ../sass/_global.scss */
  .popPage-section {
    width: 86%;
    left: calc(50% - 44%);
  }
}
@keyframes zoom-in {
  0% {
    transform: scale(0.3);
  }
  70% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
/* line 2, ../sass/_navbar.scss */
#page-navbar {
  background-color: #104378;
  position: relative;
}
/* line 5, ../sass/_navbar.scss */
#page-navbar.rwd-page-navbar {
  display: block;
}
/* line 8, ../sass/_navbar.scss */
#page-navbar .nav {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}
/* line 15, ../sass/_navbar.scss */
#page-navbar .nav-end-items {
  background-color: #104378;
}
/* line 18, ../sass/_navbar.scss */
#page-navbar .nav-item-link {
  display: inline-block;
  width: 260px;
  text-align: center;
  padding: 10px;
  color: #ffffff;
  letter-spacing: 1px;
  text-decoration: none;
  text-shadow: 0 0 0.2em #071d35;
}
/* line 27, ../sass/_navbar.scss */
#page-navbar .nav-item-link:hover {
  background-color: #2492eb;
}
/* line 30, ../sass/_navbar.scss */
#page-navbar .nav-item-link.active {
  background-color: #2492eb;
}
/* line 33, ../sass/_navbar.scss */
#page-navbar .nav-item-link i {
  margin-right: 7px;
}

@media screen and (max-width: 1260px) {
  /* line 40, ../sass/_navbar.scss */
  #page-navbar.rwd-page-navbar {
    position: absolute;
    top: 0;
    left: -260px;
    bottom: 0;
    z-index: 9;
    transition: left .3s ease-in;
  }
  /* line 47, ../sass/_navbar.scss */
  #page-navbar.rwd-page-navbar.open {
    left: 0;
    transition: left .3s ease-in;
  }
}
/* line 1, ../sass/_topbar.scss */
.topBar-container {
  width: 100%;
  height: 60px;
  flex: 0 0 auto;
  border-bottom: #104378 solid 2px;
  transition: height 0.3s ease-in;
}
/* line 8, ../sass/_topbar.scss */
.topBar-container.open {
  height: 60%;
  box-shadow: 0 2px 4px rgba(37, 40, 43, 0.2);
  position: relative;
}
/* line 13, ../sass/_topbar.scss */
.topBar-container.open .topBar-wrapper, .topBar-container.open .notification-container {
  z-index: 100;
  position: relative;
}
/* line 18, ../sass/_topbar.scss */
.topBar-container.open .notification-container {
  height: calc(100% - 60px);
  padding: 2% 5%;
}
/* line 23, ../sass/_topbar.scss */
.topBar-container.open .topBar-wrapper {
  height: 60px;
  border-bottom: #fafafa solid 1px;
}
/* line 28, ../sass/_topbar.scss */
.topBar-container.open .topBar-collapse-wrapper {
  display: flex;
  top: 100%;
}
/* line 33, ../sass/_topbar.scss */
.topBar-container.open:after {
  content: "";
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}
/* line 45, ../sass/_topbar.scss */
.topBar-container .logo-wrapper {
  width: 260px;
  height: 100%;
}
/* line 48, ../sass/_topbar.scss */
.topBar-container .logo-wrapper button {
  border: none;
  background-color: transparent;
}
/* line 51, ../sass/_topbar.scss */
.topBar-container .logo-wrapper button:focus {
  outline: none;
}
/* line 55, ../sass/_topbar.scss */
.topBar-container .logo-wrapper a {
  width: 100%;
  height: 100%;
  display: flex;
}
/* line 59, ../sass/_topbar.scss */
.topBar-container .logo-wrapper a img {
  display: block;
  margin: auto;
}
/* line 64, ../sass/_topbar.scss */
.topBar-container .logo-wrapper .rwd-nav {
  display: none;
  width: 50px;
}
@media screen and (max-width: 1300px) {
  /* line 70, ../sass/_topbar.scss */
  .topBar-container .logo-wrapper {
    display: flex;
  }
  /* line 72, ../sass/_topbar.scss */
  .topBar-container .logo-wrapper a {
    width: calc(100% - 50px);
  }
  /* line 75, ../sass/_topbar.scss */
  .topBar-container .logo-wrapper .rwd-nav {
    display: inline-block;
  }
}
@media screen and (max-width: 1024px) {
  /* line 82, ../sass/_topbar.scss */
  .topBar-container .logo-wrapper {
    width: auto;
    position: relative;
  }
  /* line 85, ../sass/_topbar.scss */
  .topBar-container .logo-wrapper .logo {
    width: 120px;
  }
}
/* line 91, ../sass/_topbar.scss */
.topBar-container .topBar-wrapper {
  width: 100%;
  height: 58px;
  display: flex;
  background-color: #ffffff;
}
/* line 97, ../sass/_topbar.scss */
.topBar-container .top-tool-wrapper {
  width: calc(100% - 260px);
  height: 100%;
  padding: 0 5%;
  text-align: right;
}
@media screen and (max-width: 1024px) {
  /* line 104, ../sass/_topbar.scss */
  .topBar-container .topBar-wrapper {
    justify-content: space-between;
  }
  /* line 107, ../sass/_topbar.scss */
  .topBar-container .top-tool-wrapper {
    width: auto;
  }
}
/* line 112, ../sass/_topbar.scss */
.topBar-container .tool-icon {
  font-size: 1.5rem;
  color: #104378;
  cursor: pointer;
}

/* line 119, ../sass/_topbar.scss */
.topBar-collapse-wrapper {
  width: 49px;
  height: 24.5px;
  position: absolute;
  z-index: 100;
  display: none;
  align-items: flex-end;
  justify-content: center;
  background-color: #104378;
  left: calc(50% - 22.5px);
  top: 60px;
  border-radius: 0 0 100px 100px;
  padding-bottom: 2px;
  transition: all 0.3s ease-in;
}
/* line 134, ../sass/_topbar.scss */
.topBar-collapse-wrapper .collapse-inner-circle {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  color: #757575;
  font-size: 1.2rem;
  border-radius: 100%;
  cursor: pointer;
}
/* line 146, ../sass/_topbar.scss */
.topBar-collapse-wrapper .collapse-inner-circle:hover {
  color: #104378;
}

/* line 152, ../sass/_topbar.scss */
.notification-wrapper,
.account-wrapper {
  width: 60px;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}
/* line 163, ../sass/_topbar.scss */
.notification-wrapper:not(.hasData) .tool-icon,
.account-wrapper:not(.hasData) .tool-icon {
  color: #999999;
}
/* line 167, ../sass/_topbar.scss */
.notification-wrapper:not(.hasData) .notification-tip,
.account-wrapper:not(.hasData) .notification-tip {
  display: none;
}
/* line 172, ../sass/_topbar.scss */
.notification-wrapper .notification-tip,
.account-wrapper .notification-tip {
  width: 7px;
  height: 7px;
  background-color: red;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  top: 15px;
  right: 15px;
}
/* line 182, ../sass/_topbar.scss */
.notification-wrapper .account-toolBar-popup,
.account-wrapper .account-toolBar-popup {
  position: absolute;
  width: 140px;
  right: 0;
  top: 60px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
  border-radius: 5px;
  border: #cccccc solid 1px;
  padding: 1% 0;
  z-index: 10;
  font-size: 0.9rem;
  color: #454545;
}
/* line 196, ../sass/_topbar.scss */
.notification-wrapper .account-toolBar-popup .toolBar-wrapper,
.account-wrapper .account-toolBar-popup .toolBar-wrapper {
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  margin: 0;
  cursor: pointer;
}
/* line 208, ../sass/_topbar.scss */
.notification-wrapper .account-toolBar-popup .toolBar-wrapper:hover,
.account-wrapper .account-toolBar-popup .toolBar-wrapper:hover {
  background-color: #dbe4ea;
}
/* line 212, ../sass/_topbar.scss */
.notification-wrapper .account-toolBar-popup .toolBar-wrapper .toolBar-label,
.account-wrapper .account-toolBar-popup .toolBar-wrapper .toolBar-label {
  padding: 0 10px;
}

/* line 219, ../sass/_topbar.scss */
.notification-container, .history-container {
  width: 100%;
  height: 0;
  padding: 0 5%;
  background: #fafafa;
  overflow: auto;
  transition: all 0.3s ease-in;
}

/* line 229, ../sass/_topbar.scss */
.message-item-wrapper .message-item {
  display: flex;
  border-radius: 5px;
  height: auto;
  min-height: 70px;
  align-items: center;
  margin-bottom: 25px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  background-color: #fff;
  padding-left: 10px;
  padding-right: 10px;
}
/* line 242, ../sass/_topbar.scss */
.message-item-wrapper .message-item:hover {
  opacity: 0.8;
}
/* line 245, ../sass/_topbar.scss */
.message-item-wrapper .message-item:hover .message-item-close {
  display: flex;
}
/* line 250, ../sass/_topbar.scss */
.message-item-wrapper .message-item:before {
  content: "";
  width: 5px;
  height: 52px;
  border-radius: 70px;
}
/* line 258, ../sass/_topbar.scss */
.message-item-wrapper .message-item.notification-alert .message-item-icon, .message-item-wrapper .message-item.notification-alert .message-title {
  color: #2492eb;
}
/* line 262, ../sass/_topbar.scss */
.message-item-wrapper .message-item.notification-alert:before {
  background-color: #2492eb;
}
/* line 268, ../sass/_topbar.scss */
.message-item-wrapper .message-item.notification-ads .message-item-icon, .message-item-wrapper .message-item.notification-ads .message-title {
  color: #f0a92e;
}
/* line 272, ../sass/_topbar.scss */
.message-item-wrapper .message-item.notification-ads:before {
  background-color: #f0a92e;
}
/* line 278, ../sass/_topbar.scss */
.message-item-wrapper .message-item.notification-info .message-item-icon, .message-item-wrapper .message-item.notification-info .message-title {
  color: #20a77a;
}
/* line 282, ../sass/_topbar.scss */
.message-item-wrapper .message-item.notification-info:before {
  background-color: #20a77a;
}
/* line 288, ../sass/_topbar.scss */
.message-item-wrapper .message-item-icon, .message-item-wrapper .message-item-close {
  margin: 0;
  width: 45px;
  text-align: center;
  font-size: 1.2rem;
}
/* line 295, ../sass/_topbar.scss */
.message-item-wrapper .message-item-close {
  width: 80px;
  font-size: 1rem;
  line-height: 2;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  background-color: #104378;
  color: #ffffff;
  display: none;
}
/* line 306, ../sass/_topbar.scss */
.message-item-wrapper .message-item-close:hover {
  background-color: #2492eb;
}
/* line 311, ../sass/_topbar.scss */
.message-item-wrapper .message-close-label {
  margin-left: 5px;
}
/* line 315, ../sass/_topbar.scss */
.message-item-wrapper .message-item-message {
  margin: 0;
  width: calc(100% - 80px);
  padding-left: 5px;
}
/* line 320, ../sass/_topbar.scss */
.message-item-wrapper .message-item-message .message-title {
  margin: 0;
  font-weight: 600;
  font-size: 1rem;
}
/* line 326, ../sass/_topbar.scss */
.message-item-wrapper .message-item-message .message-content {
  margin: 0;
  color: #757575;
  font-size: 0.9rem;
}

/* line 334, ../sass/_topbar.scss */
.notification-manager {
  text-align: right;
}

/* line 338, ../sass/_topbar.scss */
.notification-empty, .history-empty {
  width: 100%;
  height: 250px;
  text-align: center;
  padding-top: 25px;
}
/* line 345, ../sass/_topbar.scss */
.notification-empty .empty-icon, .history-empty .empty-icon {
  font-size: 6rem;
  color: #ccc;
}
/* line 350, ../sass/_topbar.scss */
.notification-empty .empty-content, .history-empty .empty-content {
  margin: 0;
  padding-top: 25px;
  font-size: 1.2rem;
  color: #757575;
}

/* line 358, ../sass/_topbar.scss */
.history-empty {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

/* line 366, ../sass/_topbar.scss */
.alert-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9999;
}
/* line 375, ../sass/_topbar.scss */
.alert-container .alert-wrapper {
  width: 400px;
  min-height: 300px;
  max-height: 700px;
  overflow: auto;
  position: absolute;
  top: calc(40% - 150px);
  left: calc(50% - 200px);
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
  border-radius: 25px;
  padding: 1.5% 2%;
}
/* line 389, ../sass/_topbar.scss */
.alert-container .alert-wrapper.notification-alert .alert-title, .alert-container .alert-wrapper.notification-alert .alert-icon {
  color: #2492eb;
}
/* line 395, ../sass/_topbar.scss */
.alert-container .alert-wrapper.notification-ads .alert-title, .alert-container .alert-wrapper.notification-ads .alert-icon {
  color: #f0a92e;
}
/* line 401, ../sass/_topbar.scss */
.alert-container .alert-wrapper.notification-info .alert-title, .alert-container .alert-wrapper.notification-info .alert-icon {
  color: #20a77a;
}
/* line 407, ../sass/_topbar.scss */
.alert-container .alert-title {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
}
/* line 413, ../sass/_topbar.scss */
.alert-container .alert-icon {
  font-size: 4rem;
  text-align: center;
  margin: 0;
  line-height: normal;
}
/* line 420, ../sass/_topbar.scss */
.alert-container .alert-btn {
  text-align: center;
  margin: 0;
}
/* line 424, ../sass/_topbar.scss */
.alert-container .alert-btn .btn-outline-primary {
  margin-right: 1rem;
}

/* line 430, ../sass/_topbar.scss */
.history-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.3);
}
/* line 439, ../sass/_topbar.scss */
.history-popup .history-container {
  position: absolute;
  top: 10%;
  left: 15%;
  width: 70%;
  height: 80%;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
  border-radius: 5px;
  padding: 2% 3%;
}
/* line 451, ../sass/_topbar.scss */
.history-popup .history-content {
  width: 100%;
  height: calc(100% - 130px);
  padding: 0 2%;
  overflow: auto;
}
/* line 458, ../sass/_topbar.scss */
.history-popup .history-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 20px;
  height: 60px;
}
/* line 466, ../sass/_topbar.scss */
.history-popup .history-footer .ant-pagination {
  margin-right: 1%;
}
/* line 470, ../sass/_topbar.scss */
.history-popup .history-footer .ant-pagination-item-link {
  line-height: 2.1;
}
/* line 475, ../sass/_topbar.scss */
.history-popup .history-top {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
}
/* line 481, ../sass/_topbar.scss */
.history-popup .history-top .history-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #104378;
}
/* line 488, ../sass/_topbar.scss */
.history-popup .history-close-wrapper {
  font-size: 1.5rem;
  color: #606060;
  cursor: pointer;
}
/* line 493, ../sass/_topbar.scss */
.history-popup .history-close-wrapper:hover {
  color: #dc3545;
}

/* line 1, ../sass/_mainSection.scss */
.bottom-container {
  width: 100%;
  height: calc(100vh - 60px);
  position: relative;
  display: flex;
}

@media screen and (max-width: 1300px) {
  /* line 9, ../sass/_mainSection.scss */
  .bottom-container .main-section {
    width: 100%;
  }
}
/* line 14, ../sass/_mainSection.scss */
.main-section {
  width: calc(100% - 260px);
  height: calc(100vh - 60px);
}
/* line 17, ../sass/_mainSection.scss */
.main-section button {
  border: none;
  background-color: transparent;
}
/* line 20, ../sass/_mainSection.scss */
.main-section button:focus {
  outline: none;
}
/* line 24, ../sass/_mainSection.scss */
.main-section .tool-bar {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin: 10px;
}
/* line 29, ../sass/_mainSection.scss */
.main-section .tool-bar button {
  border: none;
  background-color: transparent;
  padding: 0 7px;
  margin-left: 5px;
  line-height: 32px;
  color: #989898;
  border-radius: 3px;
}
/* line 37, ../sass/_mainSection.scss */
.main-section .tool-bar button:focus {
  outline: none;
}
/* line 40, ../sass/_mainSection.scss */
.main-section .tool-bar button:hover {
  color: #ffffff;
  background-color: #2492eb;
}
/* line 45, ../sass/_mainSection.scss */
.main-section .tool-bar a,
.main-section .tool-bar button[class$='-add'] {
  display: inline-block;
  padding: 0 7px;
  line-height: 32px;
  color: #ffffff;
  background-color: #2492eb;
  border-radius: 3px;
}
/* line 53, ../sass/_mainSection.scss */
.main-section .tool-bar a:hover,
.main-section .tool-bar button[class$='-add']:hover {
  text-decoration: none;
  box-shadow: 0 3px 6px rgba(36, 146, 235, 0.16), 0 3px 6px rgba(36, 146, 235, 0.23);
}
/* line 58, ../sass/_mainSection.scss */
.main-section .tool-bar i {
  padding-right: 3px;
}
/* line 61, ../sass/_mainSection.scss */
.main-section .tool-bar .search {
  display: flex;
}
/* line 63, ../sass/_mainSection.scss */
.main-section .tool-bar .search .search-col {
  display: flex;
  margin-right: 7px;
}
/* line 66, ../sass/_mainSection.scss */
.main-section .tool-bar .search .search-col label {
  flex-shrink: 0;
  margin: 0;
  line-height: 32px;
}
/* line 71, ../sass/_mainSection.scss */
.main-section .tool-bar .search .search-col input.ant-input-number-input {
  line-height: 32px;
}
/* line 74, ../sass/_mainSection.scss */
.main-section .tool-bar .search .search-col .searchYear {
  min-height: 34px;
}
/* line 76, ../sass/_mainSection.scss */
.main-section .tool-bar .search .search-col .searchYear .ant-input-number-handler-wrap {
  display: none;
}
/* line 79, ../sass/_mainSection.scss */
.main-section .tool-bar .search .search-col .searchYear .ant-input-number-input {
  min-height: 34px;
}
/* line 84, ../sass/_mainSection.scss */
.main-section .tool-bar .search .search-col.search-mailroom input {
  min-width: 300px;
}
/* line 90, ../sass/_mainSection.scss */
.main-section .tool-bar input[type="checkbox"] {
  display: none;
  opacity: 0;
}
/* line 93, ../sass/_mainSection.scss */
.main-section .tool-bar input[type="checkbox"]:checked + label[class^='taxable-'] {
  border: 1px solid #2492eb;
  background-color: #2492eb;
  color: #ffffff;
}
/* line 99, ../sass/_mainSection.scss */
.main-section .tool-bar label[class^='taxable-'] {
  margin-right: 10px;
  margin-bottom: 0;
  padding: 0 10px;
  line-height: 22px;
  border: 1px solid #dddddd;
  border-radius: 3px;
  color: #949494;
}
/* line 107, ../sass/_mainSection.scss */
.main-section .tool-bar label[class^='taxable-']:hover {
  cursor: pointer;
  color: #2492eb;
  border: 1px solid #2492eb;
}
/* line 113, ../sass/_mainSection.scss */
.main-section .tool-bar label[class^='taxable-'].disable {
  background-color: #dddddd;
  color: #949494;
}
/* line 116, ../sass/_mainSection.scss */
.main-section .tool-bar label[class^='taxable-'].disable:hover {
  cursor: not-allowed;
  border-color: #dddddd;
}
/* line 122, ../sass/_mainSection.scss */
.main-section .section-row {
  display: flex;
}
/* line 125, ../sass/_mainSection.scss */
.main-section .section-col {
  width: 50%;
  margin: 10px;
  height: calc(100vh - 60px - 100px);
  overflow: hidden;
}
/* line 131, ../sass/_mainSection.scss */
.main-section .section-list {
  height: calc( 100vh - 60px - 100px);
}
/* line 134, ../sass/_mainSection.scss */
.main-section .section-list .case-list-body-wrap {
  overflow-y: auto;
  height: calc(100% - 60px);
}
/* line 137, ../sass/_mainSection.scss */
.main-section .section-list .case-list-body-wrap .case-item-tip {
  padding: 10px;
}
/* line 141, ../sass/_mainSection.scss */
.main-section .section-list ul.case-list-head,
.main-section .section-list ul.case-list-body {
  display: flex;
}
/* line 144, ../sass/_mainSection.scss */
.main-section .section-list ul.case-list-head li,
.main-section .section-list ul.case-list-body li {
  padding: 5px;
}
/* line 146, ../sass/_mainSection.scss */
.main-section .section-list ul.case-list-head li[class^='head-'], .main-section .section-list ul.case-list-head li[class^='body-'],
.main-section .section-list ul.case-list-body li[class^='head-'],
.main-section .section-list ul.case-list-body li[class^='body-'] {
  width: 100px;
  text-align: center;
}
/* line 151, ../sass/_mainSection.scss */
.main-section .section-list ul.case-list-head li[class$='-fileSize'],
.main-section .section-list ul.case-list-body li[class$='-fileSize'] {
  width: 150px;
}
/* line 154, ../sass/_mainSection.scss */
.main-section .section-list ul.case-list-head li[class$='-action'],
.main-section .section-list ul.case-list-body li[class$='-action'] {
  text-align: right;
}
/* line 156, ../sass/_mainSection.scss */
.main-section .section-list ul.case-list-head li[class$='-action'] button,
.main-section .section-list ul.case-list-body li[class$='-action'] button {
  margin-left: 3px;
  height: 100%;
}
/* line 160, ../sass/_mainSection.scss */
.main-section .section-list ul.case-list-head li[class$='-action'] svg,
.main-section .section-list ul.case-list-body li[class$='-action'] svg {
  display: block;
  margin: auto;
}
/* line 166, ../sass/_mainSection.scss */
.main-section .section-list ul.case-list-head button,
.main-section .section-list ul.case-list-body button {
  width: 30px;
}
/* line 170, ../sass/_mainSection.scss */
.main-section .section-list ul.case-list-head {
  background-color: #f5f5f5;
  border: 1px solid #cecece;
  font-weight: bolder;
  margin-bottom: 5px;
  line-height: 30px;
}
/* line 177, ../sass/_mainSection.scss */
.main-section .section-list ul.case-list-body {
  border: 1px solid #e8e8e8;
  border-radius: 3px;
}
/* line 181, ../sass/_mainSection.scss */
.main-section .section-list .case-item {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 3px;
  margin-bottom: 5px;
}
/* line 185, ../sass/_mainSection.scss */
.main-section .section-list .case-item:hover {
  border-color: #2492eb;
}
/* line 188, ../sass/_mainSection.scss */
.main-section .section-list .case-item.active ul.case-list-body {
  color: #ffffff;
  background-color: #2492eb;
}

/* line 195, ../sass/_mainSection.scss */
.col-item-tool {
  position: absolute;
  top: 0;
  right: 0;
}
/* line 199, ../sass/_mainSection.scss */
.col-item-tool button {
  margin-right: 20px;
  padding: 5px 10px;
  border: 1px solid #ffffff;
  border-radius: 5px;
  color: #2492eb;
}
/* line 205, ../sass/_mainSection.scss */
.col-item-tool button[class$='-edit'], .col-item-tool button[class$='-update'] {
  color: #ffffff;
  background-color: #2492eb;
}
/* line 209, ../sass/_mainSection.scss */
.col-item-tool button[class$='-edit']:hover, .col-item-tool button[class$='-update']:hover {
  background-color: #53a9ef;
}
/* line 214, ../sass/_mainSection.scss */
.col-item-tool button[class$='-cancel']:hover {
  border-color: #2492eb;
}

/* line 220, ../sass/_mainSection.scss */
.section-col-item {
  overflow-y: auto;
  position: relative;
}
/* line 224, ../sass/_mainSection.scss */
.section-col-item .creat-consultations,
.section-col-item .creat-case {
  background-color: #eef7ff;
  min-height: calc(100vh - 60px);
}
/* line 229, ../sass/_mainSection.scss */
.section-col-item .creat-case-head {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
/* line 234, ../sass/_mainSection.scss */
.section-col-item .creat-title {
  font-weight: bolder;
  font-size: 1rem;
  display: flex;
  line-height: 34px;
}
/* line 239, ../sass/_mainSection.scss */
.section-col-item .creat-title .tip {
  color: red;
  font-weight: lighter;
  display: inline-block;
  margin-left: 10px;
}
/* line 246, ../sass/_mainSection.scss */
.section-col-item button.btn-submit {
  padding: 0 10px;
  margin-right: 5px;
  border-radius: 3px;
  background-color: #104378;
  color: #ffffff;
}
/* line 253, ../sass/_mainSection.scss */
.section-col-item .case-item-tip {
  text-align: center;
  padding: 30px;
  color: #2492eb;
}
/* line 259, ../sass/_mainSection.scss */
.section-col-item .case-file-extend {
  padding: 5px;
}
/* line 262, ../sass/_mainSection.scss */
.section-col-item .case-file-extend-head {
  padding: 5px;
  font-size: 1.25em;
  display: flex;
}
/* line 266, ../sass/_mainSection.scss */
.section-col-item .case-file-extend-head button {
  background-color: #2492eb;
  color: #ffffff;
  border-radius: 3px;
  margin-right: 10px;
}
/* line 271, ../sass/_mainSection.scss */
.section-col-item .case-file-extend-head button:hover {
  background-color: #53a9ef;
}
/* line 274, ../sass/_mainSection.scss */
.section-col-item .case-file-extend-head button i {
  margin-right: 10px;
}
/* line 278, ../sass/_mainSection.scss */
.section-col-item .case-file-extend-head form {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}
/* line 282, ../sass/_mainSection.scss */
.section-col-item .case-file-extend-head form button {
  padding: 5px 15px;
  margin: 0 5px;
  align-self: flex-start;
}
/* line 289, ../sass/_mainSection.scss */
.section-col-item .case-file-extend-title {
  padding: 5px;
  flex-grow: 1;
}
/* line 293, ../sass/_mainSection.scss */
.section-col-item .case-file-list {
  padding: 5px;
}
/* line 296, ../sass/_mainSection.scss */
.section-col-item .case-file-group-title {
  padding: 5px;
  font-size: 1.15em;
  font-weight: bold;
  color: #2492eb;
}
/* line 301, ../sass/_mainSection.scss */
.section-col-item .case-file-group-title i {
  display: inline-block;
  margin-right: 5px;
}
/* line 306, ../sass/_mainSection.scss */
.section-col-item .case-file {
  display: flex;
  padding: 5px;
}
/* line 309, ../sass/_mainSection.scss */
.section-col-item .case-file:hover {
  cursor: pointer;
  background-color: #eef7ff;
}
/* line 312, ../sass/_mainSection.scss */
.section-col-item .case-file:hover .case-file-tool {
  display: block;
}
/* line 317, ../sass/_mainSection.scss */
.section-col-item .case-file-title {
  padding-left: 1.5em;
  flex-grow: 1;
}
/* line 321, ../sass/_mainSection.scss */
.section-col-item .case-file-tool {
  display: none;
  flex-shrink: 0;
}
/* line 324, ../sass/_mainSection.scss */
.section-col-item .case-file-tool button {
  width: 30px;
  margin-left: 7px;
  border-radius: 3px;
}
/* line 328, ../sass/_mainSection.scss */
.section-col-item .case-file-tool button:hover {
  background-color: #2492eb;
  color: #ffffff;
}
/* line 335, ../sass/_mainSection.scss */
.section-col-item .case-item-extend {
  background-color: #eef7ff;
}
/* line 338, ../sass/_mainSection.scss */
.section-col-item .col-item-column {
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
/* line 343, ../sass/_mainSection.scss */
.section-col-item .col-item-wrap {
  padding: 5px;
  box-sizing: border-box;
  font-size: 0.85rem;
}
/* line 347, ../sass/_mainSection.scss */
.section-col-item .col-item-wrap.save {
  text-align: right;
  padding: 15px;
}
/* line 351, ../sass/_mainSection.scss */
.section-col-item .col-item-wrap .btn-submit {
  padding: 5px 30px;
  font-size: 16px;
}
/* line 356, ../sass/_mainSection.scss */
.section-col-item .col-item-group {
  border-radius: 3px;
  background-color: #fff;
}
/* line 360, ../sass/_mainSection.scss */
.section-col-item .col-item-title {
  padding: 5px 10px;
  font-weight: bolder;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}
/* line 366, ../sass/_mainSection.scss */
.section-col-item .col-item-title .col-item {
  font-weight: normal;
  padding: 0;
}
/* line 369, ../sass/_mainSection.scss */
.section-col-item .col-item-title .col-item .col-title {
  width: auto;
}
/* line 373, ../sass/_mainSection.scss */
.section-col-item .col-item-title .tip {
  color: red;
  font-weight: lighter;
  display: inline-block;
  margin-left: 10px;
}
/* line 380, ../sass/_mainSection.scss */
.section-col-item .col-item {
  padding: 5px 10px;
  line-height: 1.5rem;
  display: flex;
}
/* line 384, ../sass/_mainSection.scss */
.section-col-item .col-item:first-child {
  width: calc(100% - 150px);
}
/* line 387, ../sass/_mainSection.scss */
.section-col-item .col-item .col-title {
  flex-shrink: 0;
  text-align: right;
  width: 9em;
}
/* line 392, ../sass/_mainSection.scss */
.section-col-item .col-item .col-title.required::before {
  content: '*';
  margin-top: 3px;
}
/* line 398, ../sass/_mainSection.scss */
.section-col-item .col-item .col-text {
  word-break: break-all;
  flex-grow: 1;
}
/* line 403, ../sass/_mainSection.scss */
.section-col-item .col-item .col-text span ~ span::before {
  content: '、';
}
/* line 408, ../sass/_mainSection.scss */
.section-col-item .col-item .col-text button {
  font-size: .875rem;
  padding: 0 7px;
  margin-right: 3px;
  border: 1px solid #2492eb;
  border-radius: 5px;
  color: #2492eb;
}
/* line 415, ../sass/_mainSection.scss */
.section-col-item .col-item .col-text button:hover {
  color: #ffffff;
  background-color: #2492eb;
}
/* line 420, ../sass/_mainSection.scss */
.section-col-item .col-item .col-text .docketList {
  display: flex;
  flex-wrap: wrap;
}
/* line 423, ../sass/_mainSection.scss */
.section-col-item .col-item .col-text .docketList ~ .docketList {
  border-top: 1px solid #dddddd;
}
/* line 426, ../sass/_mainSection.scss */
.section-col-item .col-item .col-text .docketList .docketCol {
  margin-right: 7px;
}
/* line 429, ../sass/_mainSection.scss */
.section-col-item .col-item .col-text .docketList span {
  display: inline-block;
  color: #000000;
  margin-left: 3px;
}
/* line 435, ../sass/_mainSection.scss */
.section-col-item .col-item .col-text .docket-col-edit {
  display: flex;
  flex-wrap: wrap;
  margin-right: 10px;
  min-width: 82px;
}
/* line 440, ../sass/_mainSection.scss */
.section-col-item .col-item .col-text .docket-col-edit .docket-col-title {
  color: #000000;
  flex-shrink: 0;
}
/* line 444, ../sass/_mainSection.scss */
.section-col-item .col-item .col-text .docket-col-edit .docket-col-txt {
  flex-grow: 1;
}
/* line 449, ../sass/_mainSection.scss */
.section-col-item .col-item .col-edit {
  flex-grow: 1;
  width: 100%;
}
/* line 452, ../sass/_mainSection.scss */
.section-col-item .col-item .col-edit input[type="radio"],
.section-col-item .col-item .col-edit input[type="checkbox"] {
  display: none;
  opacity: 0;
}
/* line 456, ../sass/_mainSection.scss */
.section-col-item .col-item .col-edit input[type="radio"]:checked + label[class^='edit-'],
.section-col-item .col-item .col-edit input[type="checkbox"]:checked + label[class^='edit-'] {
  border: 1px solid #2492eb;
  background-color: #2492eb;
  color: #ffffff;
}
/* line 462, ../sass/_mainSection.scss */
.section-col-item .col-item .col-edit label[class^='edit-'] {
  margin-right: 10px;
  margin-bottom: 0;
  padding: 0 10px;
  line-height: 22px;
  border: 1px solid #dddddd;
  border-radius: 3px;
  color: #949494;
}
/* line 470, ../sass/_mainSection.scss */
.section-col-item .col-item .col-edit label[class^='edit-']:hover {
  cursor: pointer;
  color: #2492eb;
  border: 1px solid #2492eb;
}
/* line 476, ../sass/_mainSection.scss */
.section-col-item .col-item .col-edit label[class^='edit-'].disable {
  background-color: #dddddd;
  color: #949494;
}
/* line 479, ../sass/_mainSection.scss */
.section-col-item .col-item .col-edit label[class^='edit-'].disable:hover {
  cursor: not-allowed;
  border-color: #dddddd;
}
/* line 484, ../sass/_mainSection.scss */
.section-col-item .col-item .col-edit .docketList {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
/* line 488, ../sass/_mainSection.scss */
.section-col-item .col-item .col-edit .docketList ~ .docketList {
  border-top: 1px solid #dddddd;
  padding-top: 3px;
}
/* line 493, ../sass/_mainSection.scss */
.section-col-item .col-item .col-edit .docket-col {
  display: flex;
  flex-wrap: wrap;
  min-width: 33.3%;
}
/* line 497, ../sass/_mainSection.scss */
.section-col-item .col-item .col-edit .docket-col .docket-col-title {
  color: #000000;
  flex-shrink: 0;
  min-width: 41px;
  margin-bottom: 3px;
  text-align: right;
}
/* line 504, ../sass/_mainSection.scss */
.section-col-item .col-item .col-edit .docket-col .docket-col-txt {
  flex-grow: 1;
}
/* line 507, ../sass/_mainSection.scss */
.section-col-item .col-item .col-edit .docket-col .docket-col-edit {
  display: inline-block;
  max-width: auto;
  width: 85px;
}

/* line 517, ../sass/_mainSection.scss */
.RWDDetailModal .section-col-item {
  padding-top: 50px;
}
/* line 520, ../sass/_mainSection.scss */
.RWDDetailModal .col-item-tool {
  right: 30px;
}
/* line 523, ../sass/_mainSection.scss */
.RWDDetailModal .ant-modal-footer {
  display: none;
}
/* line 528, ../sass/_mainSection.scss */
.RWDDetailModal .appointmentdates-section .col-item-group .col-item {
  padding: 5px 0;
  margin-bottom: 5px;
}
/* line 531, ../sass/_mainSection.scss */
.RWDDetailModal .appointmentdates-section .col-item-group .col-item:first-child {
  width: 100%;
  margin-top: 34px;
}
/* line 535, ../sass/_mainSection.scss */
.RWDDetailModal .appointmentdates-section .col-item-group .col-item.courtDayOpenData .col-title {
  width: 115px;
  text-align: center;
  margin-left: 35px;
}
/* line 540, ../sass/_mainSection.scss */
.RWDDetailModal .appointmentdates-section .col-item-group .col-item .col-title {
  width: 150px;
}
/* line 544, ../sass/_mainSection.scss */
.RWDDetailModal .appointmentdates-section .col-item-group .col-item .col-text button {
  background-color: transparent;
}
/* line 546, ../sass/_mainSection.scss */
.RWDDetailModal .appointmentdates-section .col-item-group .col-item .col-text button:hover {
  background-color: #2492eb;
}
/* line 551, ../sass/_mainSection.scss */
.RWDDetailModal .appointmentdates-section .col-item-group .col-item .col-edit {
  width: 100%;
  flex-grow: 1;
}

@media screen and (max-width: 1024px) {
  /* line 561, ../sass/_mainSection.scss */
  .main-section .section-col {
    width: 100%;
  }
  /* line 564, ../sass/_mainSection.scss */
  .main-section .tool-bar {
    flex-direction: column;
  }
  /* line 566, ../sass/_mainSection.scss */
  .main-section .tool-bar .search {
    flex-wrap: wrap;
  }

  /* line 571, ../sass/_mainSection.scss */
  .col-item-tool {
    justify-content: start;
  }
}
@media screen and (max-width: 768px) {
  /* line 578, ../sass/_mainSection.scss */
  .main-section .tool-bar .search {
    border-bottom: 1px solid #dddddd;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  /* line 582, ../sass/_mainSection.scss */
  .main-section .tool-bar .search .search-col {
    width: 100%;
  }
  /* line 585, ../sass/_mainSection.scss */
  .main-section .tool-bar .search .search-col.search-mailroom input {
    min-width: 100%;
  }
  /* line 591, ../sass/_mainSection.scss */
  .main-section .tool-bar .btn-tool {
    text-align: right;
  }

  /* line 597, ../sass/_mainSection.scss */
  .section-col-item .case-file-extend-head {
    flex-direction: column;
  }
  /* line 599, ../sass/_mainSection.scss */
  .section-col-item .case-file-extend-head form {
    width: 100%;
  }
  /* line 604, ../sass/_mainSection.scss */
  .section-col-item .col-item:first-child {
    padding-top: 15px;
    border-top: 1px solid #dddddd;
    width: 100%;
  }
}
/* line 2, ../sass/_section.scss */
.section .btn-empty {
  background-color: transparent;
  margin: 0;
  padding: 0 7px;
  border: none;
  text-align: center;
}
/* line 8, ../sass/_section.scss */
.section .btn-empty:focus {
  outline: none;
}

/* line 15, ../sass/_section.scss */
.case-file-upload .case-file-upload-item {
  display: flex;
  margin-bottom: 30px;
}
/* line 18, ../sass/_section.scss */
.case-file-upload .case-file-upload-item i {
  margin-right: 10px;
}
/* line 22, ../sass/_section.scss */
.case-file-upload .case-file-upload-title {
  width: 20%;
  font-size: 1em;
  line-height: 32px;
}
/* line 28, ../sass/_section.scss */
.case-file-upload .case-file-upload-select {
  width: 80%;
  line-height: 32px;
  color: #2492eb;
}

@media screen and (max-width: 576px) {
  /* line 39, ../sass/_section.scss */
  .main-section .section .toolBar-section {
    position: relative;
    left: 0;
  }
  /* line 43, ../sass/_section.scss */
  .main-section .section .toolBar {
    flex-direction: column;
  }
  /* line 45, ../sass/_section.scss */
  .main-section .section .toolBar .search-form {
    width: 100%;
    flex-wrap: wrap;
  }
  /* line 49, ../sass/_section.scss */
  .main-section .section .toolBar .search-col {
    width: 100%;
    margin-bottom: 10px;
  }
  /* line 53, ../sass/_section.scss */
  .main-section .section .toolBar .btn-tool {
    padding: 5px;
    justify-content: flex-end;
    background-color: #eef7ff;
  }
  /* line 59, ../sass/_section.scss */
  .main-section .section ul.case-list-head {
    display: none;
  }
  /* line 62, ../sass/_section.scss */
  .main-section .section ul.case-list-body {
    flex-wrap: wrap;
  }
  /* line 65, ../sass/_section.scss */
  .main-section .section ul.case-list-body li[class^='head-'], .main-section .section ul.case-list-body li[class^='body-'] {
    text-align: left;
    width: 50%;
    border-bottom: 1px solid #dddddd;
    text-align: left;
    color: #2492eb;
  }
  /* line 70, ../sass/_section.scss */
  .main-section .section ul.case-list-body li[class^='head-']:last-child, .main-section .section ul.case-list-body li[class^='body-']:last-child {
    border-bottom: none;
  }
  /* line 75, ../sass/_section.scss */
  .main-section .section ul.case-list-body li[class^='head-']::before, .main-section .section ul.case-list-body li[class^='body-']::before {
    color: #0f0f0f;
    content: attr(data-th);
  }
  /* line 80, ../sass/_section.scss */
  .main-section .section ul.case-list-body li[class$='-fileSize'], .main-section .section ul.case-list-body li[class$='-statusId'], .main-section .section ul.case-list-body li[class$='-name'], .main-section .section ul.case-list-body li[class$='-consultations-typeId'], .main-section .section ul.case-list-body li[class$='-content'], .main-section .section ul.case-list-body li[class$='-tag'], .main-section .section ul.case-list-body li[class$='-sentDate'], .main-section .section ul.case-list-body li[class$='-mailroomFile'], .main-section .section ul.case-list-body li[class$='-action'] {
    width: 100%;
  }
  /* line 94, ../sass/_section.scss */
  .main-section .col-item {
    flex-direction: column;
  }
  /* line 96, ../sass/_section.scss */
  .main-section .col-item .col-title {
    padding: 0 5px;
    text-align: left;
    width: auto;
  }
  /* line 101, ../sass/_section.scss */
  .main-section .col-item .col-text {
    padding: 0 5px;
  }
  /* line 105, ../sass/_section.scss */
  .main-section .col-item.instance {
    flex-direction: row;
    flex-wrap: wrap;
  }
  /* line 108, ../sass/_section.scss */
  .main-section .col-item.instance .instance-item {
    width: 50%;
    border-bottom: 1px solid #eef7ff;
  }
  /* line 112, ../sass/_section.scss */
  .main-section .col-item.instance .instance-item ~ .instance-item::before {
    content: '';
  }
  /* line 116, ../sass/_section.scss */
  .main-section .col-item.instance .instance-item:nth-child(1), .main-section .col-item.instance .instance-item:nth-child(2) {
    width: 100%;
  }
  /* line 120, ../sass/_section.scss */
  .main-section .col-item.instance .instance-item .col-text {
    flex-grow: 1;
  }
  /* line 125, ../sass/_section.scss */
  .main-section .col-item.instance-edit {
    flex-direction: row;
    flex-wrap: wrap;
  }
  /* line 128, ../sass/_section.scss */
  .main-section .col-item.instance-edit .instance-item {
    width: 50%;
  }
  /* line 130, ../sass/_section.scss */
  .main-section .col-item.instance-edit .instance-item:nth-child(1), .main-section .col-item.instance-edit .instance-item:nth-child(2) {
    width: 100%;
  }
}
/* line 2, ../sass/_indexPage.scss */
.index-section .community {
  background-color: #ffffffef;
  position: fixed;
  left: 0;
  bottom: 300px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 0 10px 10px 0;
}
/* line 9, ../sass/_indexPage.scss */
.index-section .community img {
  margin: 0;
  display: block;
}
/* line 13, ../sass/_indexPage.scss */
.index-section .community a {
  display: inline-block;
  vertical-align: top;
  padding: 5px;
}
/* line 19, ../sass/_indexPage.scss */
.index-section .index-header {
  background: white;
  display: flex;
}
/* line 22, ../sass/_indexPage.scss */
.index-section .index-header .index-header-logo {
  width: 150px;
}
/* line 25, ../sass/_indexPage.scss */
.index-section .index-header img.logo {
  width: 100%;
}
/* line 28, ../sass/_indexPage.scss */
.index-section .index-header .index-header-list {
  flex-grow: 1;
  line-height: 64px;
}
/* line 32, ../sass/_indexPage.scss */
.index-section .index-header .index-header-tool {
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  height: 70%;
  align-self: center;
  display: flex;
  color: rgba(0, 0, 0, 0.25);
}
/* line 39, ../sass/_indexPage.scss */
.index-section .index-header .index-header-tool a {
  align-items: center;
  padding: 0 15px;
  align-self: center;
}
/* line 44, ../sass/_indexPage.scss */
.index-section .index-header .index-header-tool span {
  align-self: center;
}
@media screen and (max-width: 576px) {
  /* line 50, ../sass/_indexPage.scss */
  .index-section .index-header {
    padding: 0;
  }
  /* line 52, ../sass/_indexPage.scss */
  .index-section .index-header .index-header-logo {
    flex-grow: 1;
  }
  /* line 55, ../sass/_indexPage.scss */
  .index-section .index-header .index-header-list {
    order: -1;
    flex-grow: 0;
    width: 70px;
  }
  /* line 60, ../sass/_indexPage.scss */
  .index-section .index-header .index-header-tool {
    border: none;
    height: 70%;
  }
}
/* line 67, ../sass/_indexPage.scss */
.index-section #index-section .index-description-bg {
  height: 400px;
  background-image: url(../img/index-img.jpeg);
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* line 75, ../sass/_indexPage.scss */
.index-section #index-section .index-description-text {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  width: 500px;
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  text-align: center;
  color: #104378;
  font-size: 1.25rem;
  line-height: 3rem;
}
/* line 86, ../sass/_indexPage.scss */
.index-section #index-section .index-item {
  width: 700px;
  margin: auto;
  padding: 30px;
  font-size: 1.25rem;
  font-weight: lighter;
  line-height: 2rem;
  text-align: center;
}
/* line 95, ../sass/_indexPage.scss */
.index-section #index-section .index-item-focus {
  color: #2492eb;
  font-size: 1.5rem;
  padding: 10px;
  font-weight: 400;
  margin: 50px 0;
}
@media screen and (max-width: 576px) {
  /* line 105, ../sass/_indexPage.scss */
  .index-section #index-section .index-description-text {
    width: 75%;
  }
  /* line 108, ../sass/_indexPage.scss */
  .index-section #index-section .index-item {
    padding: 30px 15px;
    width: 100%;
    font-size: 1rem;
  }
  /* line 112, ../sass/_indexPage.scss */
  .index-section #index-section .index-item-focus {
    font-size: 1.25rem;
  }
}
/* line 117, ../sass/_indexPage.scss */
.index-section #catalog-section {
  padding: 120px 65px;
  background: #f0f2f5;
}
/* line 121, ../sass/_indexPage.scss */
.index-section #catalog-section .catalog-item.image {
  background-color: white;
  display: flex;
  align-items: center;
}
/* line 125, ../sass/_indexPage.scss */
.index-section #catalog-section .catalog-item.image div {
  width: 50%;
  padding: 30px;
}
/* line 128, ../sass/_indexPage.scss */
.index-section #catalog-section .catalog-item.image div img {
  width: 100%;
}
/* line 133, ../sass/_indexPage.scss */
.index-section #catalog-section .catalog-item ul {
  width: 300px;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  color: #2492eb;
  padding: 30px 0;
}
/* line 140, ../sass/_indexPage.scss */
.index-section #catalog-section .catalog-item ul li {
  width: 50%;
  list-style: disc;
  line-height: 2rem;
}
/* line 147, ../sass/_indexPage.scss */
.index-section #catalog-section .catalog-item-focus {
  text-align: center;
  width: 600px;
  border: 3px solid #2492eb;
  border-radius: 15px;
  padding: 30px;
  margin: auto;
  margin-bottom: 50px;
  color: #2492eb;
  font-size: 1.5rem;
  line-height: 3rem;
  position: relative;
  background-color: white;
}
/* line 160, ../sass/_indexPage.scss */
.index-section #catalog-section .catalog-item-focus::after {
  position: absolute;
  content: "";
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-width: 20px;
  border-style: solid;
  border-top-color: #2492eb;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}
@media screen and (max-width: 576px) {
  /* line 178, ../sass/_indexPage.scss */
  .index-section #catalog-section {
    padding: 80px 10px;
  }
  /* line 181, ../sass/_indexPage.scss */
  .index-section #catalog-section .catalog-item.image {
    flex-direction: column;
  }
  /* line 183, ../sass/_indexPage.scss */
  .index-section #catalog-section .catalog-item.image div {
    width: 100%;
  }
  /* line 187, ../sass/_indexPage.scss */
  .index-section #catalog-section .catalog-item ul {
    width: 50%;
  }
  /* line 189, ../sass/_indexPage.scss */
  .index-section #catalog-section .catalog-item ul li {
    width: 100%;
  }
  /* line 194, ../sass/_indexPage.scss */
  .index-section #catalog-section .catalog-item-focus {
    width: 100%;
    padding: 15px;
    font-size: 1.25rem;
  }
}
/* line 201, ../sass/_indexPage.scss */
.index-section #relationship-section {
  background-color: white;
  padding: 120px 0;
}
/* line 204, ../sass/_indexPage.scss */
.index-section #relationship-section .carouse-item {
  padding: 60px;
  display: flex;
}
/* line 208, ../sass/_indexPage.scss */
.index-section #relationship-section .carouse-item.relationship img {
  width: 70%;
  margin: auto;
}
/* line 214, ../sass/_indexPage.scss */
.index-section #relationship-section .carouse-item-text {
  width: 50%;
  padding: 60px;
  text-align: center;
}
/* line 219, ../sass/_indexPage.scss */
.index-section #relationship-section .carouse-item-text-title {
  font-size: 2rem;
  margin: 15px 0;
  color: #2492eb;
}
/* line 224, ../sass/_indexPage.scss */
.index-section #relationship-section .carouse-item-text-text {
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: lighter;
}
/* line 229, ../sass/_indexPage.scss */
.index-section #relationship-section .carouse-item-img {
  width: 50%;
  padding-top: 30px;
}
/* line 232, ../sass/_indexPage.scss */
.index-section #relationship-section .carouse-item-img img {
  display: block;
  width: 100%;
}
@media screen and (max-width: 576px) {
  /* line 239, ../sass/_indexPage.scss */
  .index-section #relationship-section {
    padding: 65px 0;
  }
  /* line 241, ../sass/_indexPage.scss */
  .index-section #relationship-section .carouse-item {
    padding: 60px 10px;
    flex-direction: column;
  }
  /* line 245, ../sass/_indexPage.scss */
  .index-section #relationship-section .carouse-item-text {
    width: 100%;
    padding: 10px;
  }
  /* line 249, ../sass/_indexPage.scss */
  .index-section #relationship-section .carouse-item-text-title {
    font-size: 1.25rem;
  }
  /* line 252, ../sass/_indexPage.scss */
  .index-section #relationship-section .carouse-item-text-text {
    font-size: 1rem;
    padding: 0 30px;
  }
  /* line 256, ../sass/_indexPage.scss */
  .index-section #relationship-section .carouse-item-img {
    width: 100%;
  }
}

/* line 262, ../sass/_indexPage.scss */
.ant-carousel .slick-dots li.slick-active button,
.ant-carousel .slick-dots li button {
  background: #2492eb;
  height: 7px;
}

/* line 7, ../sass/_case.scss */
.cases ul.case-list-head li[class$='-name'],
.cases ul.case-list-body li[class$='-name'] {
  flex-grow: 1;
  text-align: left;
}
/* line 11, ../sass/_case.scss */
.cases ul.case-list-head li[class$='-fileSize'],
.cases ul.case-list-body li[class$='-fileSize'] {
  word-break: break-all;
  min-width: 120px;
}
/* line 15, ../sass/_case.scss */
.cases ul.case-list-head li[class$='-action'],
.cases ul.case-list-body li[class$='-action'] {
  min-width: 208px;
}

/* line 23, ../sass/_case.scss */
.creat-docket .docket-col {
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  align-self: center;
  margin: 0 auto;
  margin-bottom: 10px;
  justify-content: flex-end;
}
/* line 31, ../sass/_case.scss */
.creat-docket .docket-col .docket-col-title {
  color: #000000;
  width: 45px;
  flex-shrink: 0;
}
/* line 36, ../sass/_case.scss */
.creat-docket .docket-col .docket-col-txt {
  width: calc(100% - 45px);
}

/* line 41, ../sass/_case.scss */
.ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 40px;
}

/* line 45, ../sass/_case.scss */
.newcase {
  padding: 10px;
  display: flex;
}
/* line 48, ../sass/_case.scss */
.newcase .newcase-list,
.newcase .newcase-content {
  padding: 10px;
  width: 50%;
}
/* line 53, ../sass/_case.scss */
.newcase .case-item {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.07), 0 1px 2px rgba(0, 0, 0, 0.07);
}
/* line 55, ../sass/_case.scss */
.newcase .case-item:hover {
  border: 1px solid #2492eb;
}
/* line 58, ../sass/_case.scss */
.newcase .case-item.currentCaseId {
  border-color: #2492eb;
  background-color: #2492eb;
}
/* line 61, ../sass/_case.scss */
.newcase .case-item.currentCaseId ul.case-list-body {
  color: #ffffff;
}
/* line 66, ../sass/_case.scss */
.newcase .newcase-content {
  border: 1px solid #dddddd;
  border-radius: 3px;
}
/* line 70, ../sass/_case.scss */
.newcase .newcase-content-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
/* line 75, ../sass/_case.scss */
.newcase .header-title {
  display: flex;
  font-weight: bold;
}
/* line 78, ../sass/_case.scss */
.newcase .header-title div[class^='title-'] {
  margin-right: 10px;
}
/* line 83, ../sass/_case.scss */
.newcase .header-tool button {
  font-size: .85rem;
  margin-left: 5px;
  padding: 1px 7px;
  border: 1px solid #ffffff;
  border-radius: 3px;
}
/* line 89, ../sass/_case.scss */
.newcase .header-tool button svg {
  color: #929292;
}
/* line 92, ../sass/_case.scss */
.newcase .header-tool button:hover {
  border-color: #2492eb;
}
/* line 94, ../sass/_case.scss */
.newcase .header-tool button:hover svg {
  color: #2492eb;
}
/* line 98, ../sass/_case.scss */
.newcase .header-tool button.cancel {
  color: #104378;
}
/* line 100, ../sass/_case.scss */
.newcase .header-tool button.cancel:hover {
  border-color: #104378;
}
/* line 104, ../sass/_case.scss */
.newcase .header-tool button.save {
  border-color: #20a77a;
}
/* line 105, ../sass/_case.scss */
.newcase .header-tool button.save svg {
  color: #20a77a;
}
/* line 109, ../sass/_case.scss */
.newcase .header-tool button.save:hover {
  background-color: #20a77a;
}
/* line 110, ../sass/_case.scss */
.newcase .header-tool button.save:hover svg {
  color: #ffffff;
}
/* line 118, ../sass/_case.scss */
.newcase .newcase-docketList {
  padding-bottom: 15px;
}
/* line 121, ../sass/_case.scss */
.newcase .newcase-content-nav {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
/* line 126, ../sass/_case.scss */
.newcase .newcase-content-nav-item {
  flex-grow: 1;
  font-weight: lighter;
}
/* line 129, ../sass/_case.scss */
.newcase .newcase-content-nav-item button {
  padding: 10px;
  color: #797979;
  width: 100%;
  background-color: #f3f3f3;
  border-bottom: 1px solid #dddddd;
}
/* line 135, ../sass/_case.scss */
.newcase .newcase-content-nav-item button:hover {
  color: #2492eb;
  background-color: #ffffff;
  transition: all .2s ease-in;
}
/* line 140, ../sass/_case.scss */
.newcase .newcase-content-nav-item button.active {
  position: relative;
  color: #2492eb;
  background-color: #ffffff;
  border-color: #ffffff;
}
/* line 145, ../sass/_case.scss */
.newcase .newcase-content-nav-item button.active::after {
  content: '';
  position: absolute;
  left: 10%;
  bottom: -2px;
  padding-bottom: 3px;
  width: 80%;
  background-color: #2492eb;
}

@media screen and (max-width: 1024px) {
  /* line 165, ../sass/_case.scss */
  .cases .toolBar .case-search .btn-group button::after {
    content: '';
  }
}
@media screen and (max-width: 768px) {
  /* line 177, ../sass/_case.scss */
  .cases .toolBar .case-search {
    font-size: .875rem;
    flex-direction: column;
    bottom: 0;
  }
  /* line 181, ../sass/_case.scss */
  .cases .toolBar .case-search .item {
    width: 80%;
  }
  /* line 186, ../sass/_case.scss */
  .cases .toolBar .case-search .item.searchName input,
  .cases .toolBar .case-search .item.searchText input {
    height: 24px;
  }
  /* line 190, ../sass/_case.scss */
  .cases .toolBar .case-search .btn-group {
    position: absolute;
    bottom: 5px;
    right: 0;
  }
}
@media screen and (max-width: 576px) {
  /* line 201, ../sass/_case.scss */
  .cases .toolBar {
    left: 0;
  }
  /* line 205, ../sass/_case.scss */
  .cases .content-section .case-search {
    flex-direction: column;
  }
  /* line 207, ../sass/_case.scss */
  .cases .content-section .case-search .item {
    width: 100%;
    margin-bottom: 10px;
  }
  /* line 212, ../sass/_case.scss */
  .cases .content-section .case-search .item.searchName input {
    width: calc(100% - 9em);
  }
  /* line 217, ../sass/_case.scss */
  .cases .content-section .case-search .item.searchText input {
    width: calc(100% - 5.5em);
  }
  /* line 221, ../sass/_case.scss */
  .cases .content-section .case-search .btn-group {
    width: 100%;
    padding: 0 15px;
  }
  /* line 224, ../sass/_case.scss */
  .cases .content-section .case-search .btn-group button {
    width: 50%;
  }
  /* line 226, ../sass/_case.scss */
  .cases .content-section .case-search .btn-group button.btn-search {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  /* line 235, ../sass/_case.scss */
  .cases .content-section .case-item .case-set .set-item-row {
    flex-direction: column;
  }
  /* line 237, ../sass/_case.scss */
  .cases .content-section .case-item .case-set .set-item-row .content {
    flex-wrap: wrap;
    width: 100%;
  }
  /* line 240, ../sass/_case.scss */
  .cases .content-section .case-item .case-set .set-item-row .content .level {
    width: 50%;
    margin-right: 0;
    text-align: right;
    border-top: 1px solid #dddddd;
  }
  /* line 245, ../sass/_case.scss */
  .cases .content-section .case-item .case-set .set-item-row .content .level:nth-child(odd) {
    border-right: 1px solid #dddddd;
    padding-right: 5px;
  }
  /* line 249, ../sass/_case.scss */
  .cases .content-section .case-item .case-set .set-item-row .content .level.courts, .cases .content-section .case-item .case-set .set-item-row .content .level.ext {
    width: 50%;
  }
  /* line 260, ../sass/_case.scss */
  .cases .content-section .case-item .case-set-edit .set-item-row .content .level-edit {
    width: 50%;
    padding: 3px;
  }
  /* line 263, ../sass/_case.scss */
  .cases .content-section .case-item .case-set-edit .set-item-row .content .level-edit .content-edit {
    margin-right: 0px;
  }
  /* line 266, ../sass/_case.scss */
  .cases .content-section .case-item .case-set-edit .set-item-row .content .level-edit.courts {
    width: 50%;
  }
}
/* line 1, ../sass/_casesPage.scss */
#page-cases {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
/* line 8, ../sass/_casesPage.scss */
#page-cases .sideBar-section {
  width: 260px;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}

@media screen and (max-width: 1024px) {
  /* line 19, ../sass/_casesPage.scss */
  #page-cases .main-section {
    width: 100%;
    margin-left: 0px;
  }
}
@media screen and (max-width: 576px) {
  /* line 27, ../sass/_casesPage.scss */
  #page-cases {
    flex-direction: column;
    width: 100%;
  }
  /* line 30, ../sass/_casesPage.scss */
  #page-cases .sideBar-section {
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
  }
}
/* line 3, ../sass/_creatCase.scss */
.creatCase .content-section {
  background-color: #f7f7f7;
  height: calc(100vh - 60px);
  overflow-y: auto;
}
/* line 9, ../sass/_creatCase.scss */
.creatCase .col-item.creat-appointment .docketList {
  display: flex;
  flex-wrap: wrap;
}
/* line 14, ../sass/_creatCase.scss */
.creatCase .col-item.creat-appointment .col-edit .docket-col {
  min-width: 25%;
  padding: 0 5px;
  margin-bottom: 5px;
  display: flex;
}
/* line 19, ../sass/_creatCase.scss */
.creatCase .col-item.creat-appointment .col-edit .docket-col .ant-select,
.creatCase .col-item.creat-appointment .col-edit .docket-col .ant-input:last-child {
  width: auto;
}
@media screen and (max-width: 1400px) {
  /* line 25, ../sass/_creatCase.scss */
  .creatCase .col-item.creat-appointment .col-edit .docket-col {
    min-width: 33.3%;
  }
}
@media screen and (max-width: 920px) {
  /* line 30, ../sass/_creatCase.scss */
  .creatCase .col-item.creat-appointment .col-edit .docket-col {
    min-width: 50%;
  }
  /* line 32, ../sass/_creatCase.scss */
  .creatCase .col-item.creat-appointment .col-edit .docket-col .docket-col-txt {
    position: relative;
  }
  /* line 35, ../sass/_creatCase.scss */
  .creatCase .col-item.creat-appointment .col-edit .docket-col .ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-right: 0px;
    width: calc(100% - 35px);
  }
  /* line 39, ../sass/_creatCase.scss */
  .creatCase .col-item.creat-appointment .col-edit .docket-col .ant-input-suffix {
    width: 28px;
    display: inline-block;
    text-align: right;
    position: absolute;
    top: 0;
    right: 5px;
  }
}
/* line 51, ../sass/_creatCase.scss */
.creatCase .creat-case {
  padding-bottom: 30px;
}
/* line 53, ../sass/_creatCase.scss */
.creatCase .creat-case .col-item-column {
  width: 50%;
}

@media screen and (max-width: 576px) {
  /* line 61, ../sass/_creatCase.scss */
  .creatCase .toolBar {
    left: 0;
  }
  /* line 65, ../sass/_creatCase.scss */
  .creatCase .col-item-column .col-item-title {
    flex-direction: column;
    align-items: flex-start;
  }
  /* line 72, ../sass/_creatCase.scss */
  .creatCase .content-section form.creat-case-from .creatCase-btn {
    width: 100%;
    flex-direction: column;
    text-align: left;
  }
  /* line 76, ../sass/_creatCase.scss */
  .creatCase .content-section form.creat-case-from .creatCase-btn .historyCase {
    height: auto;
    flex-direction: column;
    margin-bottom: 10px;
  }
  /* line 80, ../sass/_creatCase.scss */
  .creatCase .content-section form.creat-case-from .creatCase-btn .historyCase .title {
    font-size: 1rem;
  }
  /* line 83, ../sass/_creatCase.scss */
  .creatCase .content-section form.creat-case-from .creatCase-btn .historyCase .appointedHistory {
    width: auto;
  }
  /* line 89, ../sass/_creatCase.scss */
  .creatCase .content-section form.creat-case-from .set-group .set-group-col {
    display: block;
    width: 100%;
  }
  /* line 94, ../sass/_creatCase.scss */
  .creatCase .content-section form.creat-case-from .set-group .set-item.fileTypeId {
    width: 100%;
  }
  /* line 98, ../sass/_creatCase.scss */
  .creatCase .content-section form.creat-case-from .set-group .set-item-row {
    display: block;
  }
  /* line 100, ../sass/_creatCase.scss */
  .creatCase .content-section form.creat-case-from .set-group .set-item-row .instance-item {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    line-height: 32px;
    margin-bottom: 5px;
  }
  /* line 106, ../sass/_creatCase.scss */
  .creatCase .content-section form.creat-case-from .set-group .set-item-row .instance-item .title {
    float: none;
    display: inline-block;
    vertical-align: top;
  }
  /* line 111, ../sass/_creatCase.scss */
  .creatCase .content-section form.creat-case-from .set-group .set-item-row .instance-item .content {
    width: 5em;
    margin: 0 3px;
  }
  /* line 115, ../sass/_creatCase.scss */
  .creatCase .content-section form.creat-case-from .set-group .set-item-row .instance-item.level, .creatCase .content-section form.creat-case-from .set-group .set-item-row .instance-item.courts, .creatCase .content-section form.creat-case-from .set-group .set-item-row .instance-item.ext {
    width: 50%;
  }
  /* line 121, ../sass/_creatCase.scss */
  .creatCase .content-section form.creat-case-from .set-group .set-item-row .instance-item.courts .content {
    width: 8em;
  }
  /* line 129, ../sass/_creatCase.scss */
  .creatCase .content-section form.creat-case-from .set-group .upload .input-upload .ant-upload {
    flex-direction: column;
  }
}
/* line 2, ../sass/_creatConsultations.scss */
.creatConsultations .content-section {
  background-color: #f7f7f7;
  height: calc(100vh - 60px);
  overflow-y: auto;
}
/* line 7, ../sass/_creatConsultations.scss */
.creatConsultations .creat-consultations {
  padding-bottom: 30px;
}
/* line 9, ../sass/_creatConsultations.scss */
.creatConsultations .creat-consultations .col-item-column {
  width: 50%;
}

@media screen and (max-width: 576px) {
  /* line 18, ../sass/_creatConsultations.scss */
  .creatConsultations .content-section .creat-case-head {
    flex-direction: column;
  }
  /* line 20, ../sass/_creatConsultations.scss */
  .creatConsultations .content-section .creat-case-head .creat-title {
    flex-wrap: wrap;
  }
  /* line 22, ../sass/_creatConsultations.scss */
  .creatConsultations .content-section .creat-case-head .creat-title .col-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-left: 0;
    padding-right: 0;
  }
  /* line 30, ../sass/_creatConsultations.scss */
  .creatConsultations .content-section .creat-case-head .btn-submit {
    width: 84px;
    line-height: 34px;
    margin-left: auto;
    margin-top: 10px;
  }
}
/* line 2, ../sass/_consultations.scss */
.consultations ul.RWD-consultationdetail-section {
  display: none;
}
/* line 8, ../sass/_consultations.scss */
.consultations ul.case-list-head li[class$='-content'],
.consultations ul.case-list-body li[class$='-content'] {
  text-align-last: left;
  flex-grow: 1;
}
/* line 14, ../sass/_consultations.scss */
.consultations .consultationdetail-section {
  border-radius: 3px;
  border: 1px solid #cecece;
  overflow-y: auto;
}
/* line 19, ../sass/_consultations.scss */
.consultations .consultationdetail-header-title {
  background-color: #f5f5f5;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
}
/* line 26, ../sass/_consultations.scss */
.consultations .detail-tool a {
  margin-right: 5px;
}
/* line 29, ../sass/_consultations.scss */
.consultations .detail-tool i {
  display: inline-block;
  width: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 3px;
  color: #989898;
}
/* line 36, ../sass/_consultations.scss */
.consultations .detail-tool i:hover {
  color: #ffffff;
  background-color: #2492eb;
}
/* line 42, ../sass/_consultations.scss */
.consultations .col-item-tool {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
/* line 46, ../sass/_consultations.scss */
.consultations .col-item-tool button {
  margin-right: 20px;
  padding: 5px 10px;
  border: 1px solid #ffffff;
  border-radius: 5px;
  color: #2492eb;
}
/* line 52, ../sass/_consultations.scss */
.consultations .col-item-tool button[class$='-edit'], .consultations .col-item-tool button[class$='-update'] {
  color: #ffffff;
  background-color: #2492eb;
}
/* line 56, ../sass/_consultations.scss */
.consultations .col-item-tool button[class$='-edit']:hover, .consultations .col-item-tool button[class$='-update']:hover {
  background-color: #53a9ef;
}
/* line 61, ../sass/_consultations.scss */
.consultations .col-item-tool button[class$='-cancel']:hover {
  border-color: #2492eb;
}
/* line 68, ../sass/_consultations.scss */
.consultations .consultationdetail-tabpane .ant-tabs-nav-wrap {
  background-color: #f0f0f0;
}
/* line 71, ../sass/_consultations.scss */
.consultations .consultationdetail-tabpane .ant-tabs-tab {
  padding-left: 10px;
  padding-right: 10px;
  margin: 0;
}
/* line 75, ../sass/_consultations.scss */
.consultations .consultationdetail-tabpane .ant-tabs-tab + .ant-tabs-tab {
  margin: 0;
  margin-right: 5px;
}
/* line 79, ../sass/_consultations.scss */
.consultations .consultationdetail-tabpane .ant-tabs-tab.ant-tabs-tab-active {
  color: #ffffff;
  background-color: #2492eb;
}
/* line 82, ../sass/_consultations.scss */
.consultations .consultationdetail-tabpane .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffffff;
}
/* line 87, ../sass/_consultations.scss */
.consultations .consultationdetail-tabpane .disabled {
  padding: 15px;
  display: inline-block;
}
/* line 93, ../sass/_consultations.scss */
.consultations .case-file-extend-head .btn-empty {
  padding: 5px 7px;
}

@media screen and (max-width: 1024px) {
  /* line 104, ../sass/_consultations.scss */
  .tool-bar .search .search-col:first-child {
    margin-bottom: 10px;
  }
  /* line 107, ../sass/_consultations.scss */
  .tool-bar .search .search-col .ant-picker {
    width: 100%;
  }

  /* line 116, ../sass/_consultations.scss */
  .consultations .section-list ul.case-list-head {
    display: none;
  }
  /* line 119, ../sass/_consultations.scss */
  .consultations .section-list ul.case-list-body {
    flex-wrap: wrap;
  }
  /* line 121, ../sass/_consultations.scss */
  .consultations .section-list ul.case-list-body li[class^='body-'] {
    width: 50%;
    text-align: left;
  }
  /* line 124, ../sass/_consultations.scss */
  .consultations .section-list ul.case-list-body li[class^='body-']::before {
    content: attr(data-th);
  }
  /* line 127, ../sass/_consultations.scss */
  .consultations .section-list ul.case-list-body li[class^='body-']:last-child {
    width: 100%;
  }
  /* line 132, ../sass/_consultations.scss */
  .consultations .section-list .RWD-appointmentdetail {
    display: block;
    border-left: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
  }
  /* line 137, ../sass/_consultations.scss */
  .consultations .section-list .RWD-detail-tool {
    display: block;
    border-top: 1px solid #e8e8e8;
  }
  /* line 140, ../sass/_consultations.scss */
  .consultations .section-list .RWD-detail-tool a,
  .consultations .section-list .RWD-detail-tool button {
    position: relative;
    display: inline-block;
    width: 25%;
    text-align: center;
    padding: 5px;
  }
  /* line 148, ../sass/_consultations.scss */
  .consultations .section-list .RWD-detail-tool a::after {
    content: '';
    padding-right: 1px;
    position: absolute;
    right: 0;
    height: 24px;
    background-color: #104378;
  }
  /* line 158, ../sass/_consultations.scss */
  .consultations ul.RWD-consultationdetail-section {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    color: #ffffff;
    background-color: #104378;
    display: flex;
    flex-wrap: wrap;
  }
  /* line 167, ../sass/_consultations.scss */
  .consultations ul.RWD-consultationdetail-section li {
    width: 25%;
  }
  /* line 170, ../sass/_consultations.scss */
  .consultations ul.RWD-consultationdetail-section button {
    display: inline-block;
    width: 100%;
    padding: 15px 0;
    position: relative;
  }
  /* line 175, ../sass/_consultations.scss */
  .consultations ul.RWD-consultationdetail-section button::after {
    content: '';
    width: 50%;
    padding-bottom: 1px;
    background-color: #ffffff;
    position: absolute;
    left: 25%;
    bottom: 7px;
  }
  /* line 186, ../sass/_consultations.scss */
  .consultations .consultationdetail-section {
    display: none;
  }
}
/* line 2, ../sass/_powerOfAttorney.scss */
.powerOfAttorney,
.casesContract {
  background-color: #ececec;
  min-height: 100vh;
  padding: 15px;
}
/* line 7, ../sass/_powerOfAttorney.scss */
.powerOfAttorney button,
.casesContract button {
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: none;
  color: #104378;
}
/* line 13, ../sass/_powerOfAttorney.scss */
.powerOfAttorney button:focus,
.casesContract button:focus {
  outline: none;
}
/* line 17, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .powerOfAttorney-report,
.powerOfAttorney .casesContract-report,
.casesContract .powerOfAttorney-report,
.casesContract .casesContract-report {
  width: 900px;
  margin: auto;
}
/* line 22, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .powerOfAttorney-report .navBar .title,
.powerOfAttorney .casesContract-report .navBar .title,
.casesContract .powerOfAttorney-report .navBar .title,
.casesContract .casesContract-report .navBar .title {
  text-align: center;
  font-size: 1.25rem;
  margin-bottom: 10px;
}
/* line 28, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .powerOfAttorney-report .case-set,
.powerOfAttorney .casesContract-report .case-set,
.casesContract .powerOfAttorney-report .case-set,
.casesContract .casesContract-report .case-set {
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
}
/* line 33, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .powerOfAttorney-report .case-set .set-item,
.powerOfAttorney .casesContract-report .case-set .set-item,
.casesContract .powerOfAttorney-report .case-set .set-item,
.casesContract .casesContract-report .case-set .set-item {
  width: calc(50% - 5px);
  padding: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  border-radius: 3px;
}
/* line 40, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .powerOfAttorney-report .case-set .set-item:nth-child(odd),
.powerOfAttorney .casesContract-report .case-set .set-item:nth-child(odd),
.casesContract .powerOfAttorney-report .case-set .set-item:nth-child(odd),
.casesContract .casesContract-report .case-set .set-item:nth-child(odd) {
  margin-right: 0;
}
/* line 43, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .powerOfAttorney-report .case-set .set-item .content,
.powerOfAttorney .casesContract-report .case-set .set-item .content,
.casesContract .powerOfAttorney-report .case-set .set-item .content,
.casesContract .casesContract-report .case-set .set-item .content {
  font-weight: bold;
}
/* line 47, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .powerOfAttorney-report .case-set .set-item-row,
.powerOfAttorney .casesContract-report .case-set .set-item-row,
.casesContract .powerOfAttorney-report .case-set .set-item-row,
.casesContract .casesContract-report .case-set .set-item-row {
  width: 100%;
}
/* line 49, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .powerOfAttorney-report .case-set .set-item-row .content,
.powerOfAttorney .casesContract-report .case-set .set-item-row .content,
.casesContract .powerOfAttorney-report .case-set .set-item-row .content,
.casesContract .casesContract-report .case-set .set-item-row .content {
  width: calc(100% - 5em);
}
@media screen and (max-width: 1024px) {
  /* line 56, ../sass/_powerOfAttorney.scss */
  .powerOfAttorney .powerOfAttorney-report,
  .powerOfAttorney .casesContract-report,
  .casesContract .powerOfAttorney-report,
  .casesContract .casesContract-report {
    width: 100%;
  }
}

/* line 62, ../sass/_powerOfAttorney.scss */
.powerOfAttorney,
.casesContract {
  font-size: 1.5rem;
}
/* line 65, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .case-receipt-title,
.powerOfAttorney .item-col-title,
.powerOfAttorney .item .title,
.powerOfAttorney .item .content,
.powerOfAttorney .item-caseDocket .title,
.powerOfAttorney .item-caseDocket .txt,
.powerOfAttorney .item-content div,
.powerOfAttorney .item-content span,
.powerOfAttorney .item-textArea span,
.powerOfAttorney .item-content .textArea .itemNo,
.powerOfAttorney .item-content .textArea .itemTxt,
.powerOfAttorney .item-content .textArea .itemTxt .txt,
.powerOfAttorney .item-signature,
.powerOfAttorney .item-date span,
.casesContract .case-receipt-title,
.casesContract .item-col-title,
.casesContract .item .title,
.casesContract .item .content,
.casesContract .item-caseDocket .title,
.casesContract .item-caseDocket .txt,
.casesContract .item-content div,
.casesContract .item-content span,
.casesContract .item-textArea span,
.casesContract .item-content .textArea .itemNo,
.casesContract .item-content .textArea .itemTxt,
.casesContract .item-content .textArea .itemTxt .txt,
.casesContract .item-signature,
.casesContract .item-date span {
  font-family: 'DFKai-sb', 'STKaiti';
}
/* line 81, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .case-receipt-tip,
.casesContract .case-receipt-tip {
  text-align: center;
  font-size: 2em;
  margin-top: 50px;
}
/* line 86, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .case-receipt-wrap,
.casesContract .case-receipt-wrap {
  background-color: #ffffff;
  padding: 10px;
}
/* line 90, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .tool,
.casesContract .tool {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
/* line 94, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .tool button,
.casesContract .tool button {
  padding: 5px 10px;
  margin-left: 10px;
  color: #104378;
  border: 1px solid #104378;
  border-radius: 3px;
}
/* line 100, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .tool button:hover,
.casesContract .tool button:hover {
  color: #2492eb;
  border: 1px solid #2492eb;
}
/* line 106, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .case-receipt,
.casesContract .case-receipt {
  border: 3px solid #171717;
}
/* line 109, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .case-receipt-title,
.casesContract .case-receipt-title {
  width: 100%;
  font-size: 2rem;
  text-align: center;
  letter-spacing: 30px;
  border-bottom: 2px solid #171717;
}
/* line 116, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .case-receipt-row,
.casesContract .case-receipt-row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
/* line 121, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .item-textArea,
.casesContract .item-textArea {
  padding: 15px;
  border-top: 2px solid #171717;
}
/* line 125, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .item-col,
.casesContract .item-col {
  width: 50%;
}
/* line 127, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .item-col + .item-col,
.casesContract .item-col + .item-col {
  border-left: 2px solid #171717;
}
/* line 130, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .item-col .item-col-title,
.casesContract .item-col .item-col-title {
  text-align: center;
  letter-spacing: 15px;
}
/* line 134, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .item-col .item-group,
.casesContract .item-col .item-group {
  border-top: 2px solid #171717;
}
/* line 137, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .item-col .item,
.casesContract .item-col .item {
  width: 100%;
}
@media screen and (max-width: 768px) {
  /* line 142, ../sass/_powerOfAttorney.scss */
  .powerOfAttorney .item-col,
  .casesContract .item-col {
    width: 100%;
  }
  /* line 144, ../sass/_powerOfAttorney.scss */
  .powerOfAttorney .item-col + .item-col,
  .casesContract .item-col + .item-col {
    border-left: none;
    border-top: 2px solid #171717;
  }
  /* line 148, ../sass/_powerOfAttorney.scss */
  .powerOfAttorney .item-col .item-group,
  .casesContract .item-col .item-group {
    border-top: none;
  }
}
/* line 153, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .item-title,
.casesContract .item-title {
  width: 100%;
}
/* line 156, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .item,
.casesContract .item {
  display: flex;
  padding: 3px 15px;
  min-height: 42px;
}
/* line 160, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .item:last-child,
.casesContract .item:last-child {
  border-bottom: none;
}
/* line 163, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .item .title,
.casesContract .item .title {
  width: 6em;
  text-align: right;
  flex-shrink: 0;
}
/* line 168, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .item .content,
.powerOfAttorney .item .edit,
.casesContract .item .content,
.casesContract .item .edit {
  width: calc(100% - 6em);
}
/* line 172, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .item .content.content-center,
.powerOfAttorney .item .edit.content-center,
.casesContract .item .content.content-center,
.casesContract .item .edit.content-center {
  text-align: center;
  padding: 0;
  flex-grow: 1;
}
/* line 179, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .item-address,
.casesContract .item-address {
  border-top: 2px solid #171717;
}
/* line 181, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .item-address .item,
.casesContract .item-address .item {
  width: 100%;
}
/* line 184, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .item-address .content,
.casesContract .item-address .content {
  width: 100%;
}
/* line 188, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .item-caseDocket,
.casesContract .item-caseDocket {
  justify-content: center;
  border-bottom: 2px solid #171717;
}
/* line 191, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .item-caseDocket .input-docket,
.casesContract .item-caseDocket .input-docket {
  padding: 5px 0;
  width: 70%;
}
/* line 196, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .item-content,
.casesContract .item-content {
  padding: 30px;
  line-height: 3rem;
  min-height: 700px;
  border-top: 2px solid #171717;
  display: inline-block;
}
/* line 202, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .item-content .input,
.casesContract .item-content .input {
  width: 100%;
}
/* line 209, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .item-content .textArea,
.casesContract .item-content .textArea {
  display: flex;
  margin-bottom: 5px;
  font-size: 16px;
}
/* line 214, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .item-content .itemTxt,
.casesContract .item-content .itemTxt {
  flex-grow: 1;
}
/* line 218, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .item-signature,
.casesContract .item-signature {
  width: 50%;
  margin-left: auto;
  display: flex;
  flex-direction: column;
}
/* line 224, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .item-signature .item .title,
.casesContract .item-signature .item .title {
  width: 8em;
}
/* line 227, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .item-signature .item .content,
.powerOfAttorney .item-signature .item .edit,
.casesContract .item-signature .item .content,
.casesContract .item-signature .item .edit {
  width: calc(100% - 8em);
}
@media screen and (max-width: 768px) {
  /* line 234, ../sass/_powerOfAttorney.scss */
  .powerOfAttorney .item-signature,
  .casesContract .item-signature {
    width: 100%;
  }
  /* line 237, ../sass/_powerOfAttorney.scss */
  .powerOfAttorney .item-signature .item .title,
  .casesContract .item-signature .item .title {
    width: 6em;
  }
  /* line 240, ../sass/_powerOfAttorney.scss */
  .powerOfAttorney .item-signature .item .content,
  .powerOfAttorney .item-signature .item .edit,
  .casesContract .item-signature .item .content,
  .casesContract .item-signature .item .edit {
    width: calc(100% - 5em);
  }
}
/* line 247, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .item-sign,
.casesContract .item-sign {
  border-top: 2px solid #171717;
  border-bottom: 2px solid #171717;
}
/* line 251, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .item-date,
.casesContract .item-date {
  padding: 30px;
  display: flex;
  text-align: center;
  font-size: 1.5rem;
}
/* line 257, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .item-date > span.text,
.casesContract .item-date > span.text {
  width: 2em;
  text-align: center;
}
/* line 258, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .item-date > span.text:first-child,
.casesContract .item-date > span.text:first-child {
  width: 5em;
}
/* line 264, ../sass/_powerOfAttorney.scss */
.powerOfAttorney .item-date > span.input,
.casesContract .item-date > span.input {
  width: 20%;
}
@media screen and (max-width: 768px) {
  /* line 270, ../sass/_powerOfAttorney.scss */
  .powerOfAttorney .item-date,
  .casesContract .item-date {
    padding: 15px;
  }
  /* line 272, ../sass/_powerOfAttorney.scss */
  .powerOfAttorney .item-date > span,
  .casesContract .item-date > span {
    width: 70%;
  }
  /* line 274, ../sass/_powerOfAttorney.scss */
  .powerOfAttorney .item-date > span.text,
  .casesContract .item-date > span.text {
    width: 2em;
    display: inline-block;
  }
  /* line 277, ../sass/_powerOfAttorney.scss */
  .powerOfAttorney .item-date > span.text:first-child,
  .casesContract .item-date > span.text:first-child {
    width: 100%;
    text-align: left;
  }
  /* line 282, ../sass/_powerOfAttorney.scss */
  .powerOfAttorney .item-date > span.input,
  .casesContract .item-date > span.input {
    width: 80%;
  }
}

@media print {
  /* line 291, ../sass/_powerOfAttorney.scss */
  * {
    -webkit-print-color-adjust: exact !important;
  }

  /* line 297, ../sass/_powerOfAttorney.scss */
  .powerOfAttorney,
  .casesContract {
    background-color: transparent;
    height: 100%;
  }

  /* line 302, ../sass/_powerOfAttorney.scss */
  .powerOfAttorney-report,
  .casesContract-report {
    width: 100%;
    height: 100%;
  }

  /* line 307, ../sass/_powerOfAttorney.scss */
  .break {
    display: block;
    page-break-before: always;
  }

  /* line 311, ../sass/_powerOfAttorney.scss */
  .noPrint,
  .tool.noPrint {
    display: none;
  }
}
@page {
  size: A4 portrait;
  margin-top: 1cm;
  margin-left: 1cm;
  margin-right: 1cm;
  margin-bottom: 1cm;
}
/* line 1, ../sass/_casesReceipt.scss */
.casesReceipt {
  background-color: #ececec;
  min-height: 100vh;
  padding: 15px;
}
/* line 5, ../sass/_casesReceipt.scss */
.casesReceipt button {
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: none;
  color: #104378;
}
/* line 11, ../sass/_casesReceipt.scss */
.casesReceipt button:focus {
  outline: none;
}
/* line 15, ../sass/_casesReceipt.scss */
.casesReceipt .casesReceipt-report {
  width: 900px;
  margin: auto;
}
/* line 19, ../sass/_casesReceipt.scss */
.casesReceipt .casesReceipt-report .navBar .title {
  text-align: center;
  font-size: 1.25rem;
  margin-bottom: 10px;
}
/* line 25, ../sass/_casesReceipt.scss */
.casesReceipt .casesReceipt-report .case-set {
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
}
/* line 30, ../sass/_casesReceipt.scss */
.casesReceipt .casesReceipt-report .case-set .set-item {
  width: calc(50% - 5px);
  padding: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  border-radius: 3px;
}
/* line 37, ../sass/_casesReceipt.scss */
.casesReceipt .casesReceipt-report .case-set .set-item:nth-child(odd) {
  margin-right: 0;
}
/* line 40, ../sass/_casesReceipt.scss */
.casesReceipt .casesReceipt-report .case-set .set-item .content {
  font-weight: bold;
}
/* line 44, ../sass/_casesReceipt.scss */
.casesReceipt .casesReceipt-report .case-set .set-item-row {
  width: 100%;
}
/* line 46, ../sass/_casesReceipt.scss */
.casesReceipt .casesReceipt-report .case-set .set-item-row .content {
  width: calc(100% - 5em);
}
@media screen and (max-width: 1024px) {
  /* line 53, ../sass/_casesReceipt.scss */
  .casesReceipt .casesReceipt-report {
    width: 100%;
  }
}

/* line 59, ../sass/_casesReceipt.scss */
.casesReceipt .case-receipt-wrap {
  background-color: #ffffff;
  padding: 10px;
}
/* line 63, ../sass/_casesReceipt.scss */
.casesReceipt .tool {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
/* line 67, ../sass/_casesReceipt.scss */
.casesReceipt .tool button {
  padding: 5px 10px;
  margin-left: 10px;
  color: #104378;
  border: 1px solid #104378;
  border-radius: 3px;
}
/* line 73, ../sass/_casesReceipt.scss */
.casesReceipt .tool button:hover {
  color: #2492eb;
  border: 1px solid #2492eb;
}

/* line 80, ../sass/_casesReceipt.scss */
.case-receipt {
  font-size: 24px;
  border: 5px solid #171717;
}
/* line 83, ../sass/_casesReceipt.scss */
.case-receipt:first-child {
  margin-bottom: 40px;
  position: relative;
}
/* line 86, ../sass/_casesReceipt.scss */
.case-receipt:first-child::after {
  content: '';
  position: absolute;
  top: calc(100% + 20px);
  right: 0;
  bottom: 0;
  left: 0;
  height: 5px;
  border-bottom: 2px dashed #3a3a3a;
}
/* line 97, ../sass/_casesReceipt.scss */
.case-receipt .case-receipt-title,
.case-receipt .case-receipt-name .title,
.case-receipt .case-receipt-reason .title,
.case-receipt .case-receipt-note .title,
.case-receipt .case-receipt-reason,
.case-receipt .case-receipt-cost div,
.case-receipt .case-receipt-category div,
.case-receipt .case-receipt-,
.case-receipt .case-receipt-lawer .data-item .title,
.case-receipt .case-receipt-lawer .data-item .content,
.case-receipt .case-receipt-date span,
.case-receipt .case-receipt-tip,
.case-receipt .text-editable {
  font-family: 'DFKai-sb', 'STKaiti';
}
/* line 112, ../sass/_casesReceipt.scss */
.case-receipt .text-editable {
  width: 100%;
  padding: 0 10px;
}
/* line 116, ../sass/_casesReceipt.scss */
.case-receipt div[class^="case-receipt"] {
  margin-bottom: 10px;
  padding: 0 15px;
}
/* line 120, ../sass/_casesReceipt.scss */
.case-receipt .case-receipt-title {
  text-align: center;
  font-size: 2.5rem;
  border-bottom: 3px solid #171717;
  padding: 10px;
}
/* line 126, ../sass/_casesReceipt.scss */
.case-receipt .case-receipt-name,
.case-receipt .case-receipt-category,
.case-receipt .case-receipt-reason,
.case-receipt .case-receipt-note {
  display: flex;
}
/* line 131, ../sass/_casesReceipt.scss */
.case-receipt .case-receipt-name .title,
.case-receipt .case-receipt-category .title,
.case-receipt .case-receipt-reason .title,
.case-receipt .case-receipt-note .title {
  flex-shrink: 0;
}
/* line 135, ../sass/_casesReceipt.scss */
.case-receipt .case-receipt-cost {
  display: flex;
}
/* line 137, ../sass/_casesReceipt.scss */
.case-receipt .case-receipt-cost .edit {
  width: 100%;
  flex-grow: 1;
  margin: 0 24px;
}
/* line 142, ../sass/_casesReceipt.scss */
.case-receipt .case-receipt-cost .text {
  flex-shrink: 0;
}
/* line 146, ../sass/_casesReceipt.scss */
.case-receipt .case-receipt-date {
  display: flex;
  justify-content: space-between;
}
/* line 149, ../sass/_casesReceipt.scss */
.case-receipt .case-receipt-date .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 0;
}
/* line 152, ../sass/_casesReceipt.scss */
.case-receipt .case-receipt-date .text {
  flex-shrink: 0;
}
/* line 155, ../sass/_casesReceipt.scss */
.case-receipt .case-receipt-date .edit {
  display: inline-block;
  margin: 0 5px;
}
/* line 160, ../sass/_casesReceipt.scss */
.case-receipt .case-receipt-lawer {
  width: 40%;
  margin-left: auto;
  padding: 30px 0;
}
/* line 164, ../sass/_casesReceipt.scss */
.case-receipt .case-receipt-lawer .input {
  width: 100%;
}
/* line 167, ../sass/_casesReceipt.scss */
.case-receipt .case-receipt-lawer .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 0;
}
/* line 170, ../sass/_casesReceipt.scss */
.case-receipt .case-receipt-lawer .data-item {
  display: flex;
}
/* line 172, ../sass/_casesReceipt.scss */
.case-receipt .case-receipt-lawer .data-item .data-name.content {
  display: flex;
}
/* line 176, ../sass/_casesReceipt.scss */
.case-receipt .case-receipt-lawer .title {
  width: 3em;
  text-align: right;
  flex-shrink: 0;
}
/* line 181, ../sass/_casesReceipt.scss */
.case-receipt .case-receipt-lawer .content {
  flex-grow: 1;
}
/* line 184, ../sass/_casesReceipt.scss */
.case-receipt .case-receipt-lawer .text {
  word-break: break-all;
}
/* line 188, ../sass/_casesReceipt.scss */
.case-receipt .case-receipt-tip {
  font-size: 20px;
}

@media screen and (max-width: 768px) {
  /* line 195, ../sass/_casesReceipt.scss */
  .case-receipt .case-receipt-lawer {
    width: 100%;
  }
  /* line 198, ../sass/_casesReceipt.scss */
  .case-receipt .case-receipt-date {
    flex-wrap: wrap;
  }
  /* line 200, ../sass/_casesReceipt.scss */
  .case-receipt .case-receipt-date .text {
    width: 10%;
  }
  /* line 201, ../sass/_casesReceipt.scss */
  .case-receipt .case-receipt-date .text:first-child {
    width: 100%;
  }
  /* line 206, ../sass/_casesReceipt.scss */
  .case-receipt .case-receipt-date .edit {
    width: 20%;
  }
}
@media print {
  /* line 214, ../sass/_casesReceipt.scss */
  * {
    -webkit-print-color-adjust: exact !important;
  }

  /* line 220, ../sass/_casesReceipt.scss */
  .casesReceipt {
    background-color: transparent;
  }
  /* line 222, ../sass/_casesReceipt.scss */
  .casesReceipt .tool {
    display: none;
  }

  /* line 226, ../sass/_casesReceipt.scss */
  .casesReceipt-report {
    width: 100%;
    height: 100%;
  }

  /* line 230, ../sass/_casesReceipt.scss */
  .break {
    display: block;
    page-break-before: always;
  }

  /* line 234, ../sass/_casesReceipt.scss */
  .noPrint {
    display: none;
  }
}
@page {
  size: A4 portrait;
  margin-top: 1cm;
  margin-left: 1cm;
  margin-right: 1cm;
  margin-bottom: 1cm;
}
/* line 2, ../sass/_exportConsultations.scss */
.exportConsultations {
  background-color: #ececec;
  min-height: 100vh;
  padding: 15px;
}
/* line 6, ../sass/_exportConsultations.scss */
.exportConsultations button {
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: none;
  color: #104378;
}
/* line 12, ../sass/_exportConsultations.scss */
.exportConsultations button:focus {
  outline: none;
}
/* line 16, ../sass/_exportConsultations.scss */
.exportConsultations .exportConsultations-report {
  width: 900px;
  margin: auto;
}
/* line 20, ../sass/_exportConsultations.scss */
.exportConsultations .exportConsultations-report .navBar .title {
  text-align: center;
  font-size: 1.25rem;
  margin-bottom: 10px;
}
/* line 26, ../sass/_exportConsultations.scss */
.exportConsultations .exportConsultations-report .case-set {
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
}
/* line 31, ../sass/_exportConsultations.scss */
.exportConsultations .exportConsultations-report .case-set .set-item {
  width: calc(50% - 5px);
  padding: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  border-radius: 3px;
}
/* line 38, ../sass/_exportConsultations.scss */
.exportConsultations .exportConsultations-report .case-set .set-item:nth-child(odd) {
  margin-right: 0;
}
/* line 41, ../sass/_exportConsultations.scss */
.exportConsultations .exportConsultations-report .case-set .set-item .content {
  font-weight: bold;
}
/* line 45, ../sass/_exportConsultations.scss */
.exportConsultations .exportConsultations-report .case-set .set-item-row {
  width: 100%;
}
/* line 47, ../sass/_exportConsultations.scss */
.exportConsultations .exportConsultations-report .case-set .set-item-row .content {
  width: calc(100% - 5em);
}

@media print {
  /* line 56, ../sass/_exportConsultations.scss */
  * {
    -webkit-print-color-adjust: exact !important;
  }

  /* line 62, ../sass/_exportConsultations.scss */
  .exportConsultations {
    background-color: transparent;
  }

  /* line 65, ../sass/_exportConsultations.scss */
  .exportConsultations-report {
    width: 100%;
    height: 100%;
  }

  /* line 69, ../sass/_exportConsultations.scss */
  .break {
    display: block;
    page-break-before: always;
  }

  /* line 73, ../sass/_exportConsultations.scss */
  .noPrint {
    display: none;
  }
}
@page {
  size: A4 portrait;
  margin-top: 1cm;
  margin-left: 1cm;
  margin-right: 1cm;
  margin-bottom: 1cm;
}
/* line 1, ../sass/_report.scss */
.report {
  padding: 10px;
}
/* line 3, ../sass/_report.scss */
.report .section-list {
  overflow-y: auto;
}

@media screen and (max-width: 768px) {
  /* line 12, ../sass/_report.scss */
  .report .tool-bar .search .search-col {
    margin: 0;
    width: 20%;
  }
  /* line 15, ../sass/_report.scss */
  .report .tool-bar .search .search-col:first-child {
    width: 60%;
  }
}
/* line 1, ../sass/_registration.scss */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* line 5, ../sass/_registration.scss */
input[type="number"] {
  -moz-appearance: textfield;
}

/* line 8, ../sass/_registration.scss */
.registration {
  padding-bottom: 200px;
}
/* line 10, ../sass/_registration.scss */
.registration h2 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
/* line 14, ../sass/_registration.scss */
.registration h4 {
  color: #2492eb;
  margin-top: 1.75rem;
  font-weight: normal;
}
/* line 19, ../sass/_registration.scss */
.registration ul > li {
  cursor: auto;
}
/* line 21, ../sass/_registration.scss */
.registration ul > li:hover {
  background-color: transparent;
}
/* line 26, ../sass/_registration.scss */
.registration .registration-nav .login-form-forgotpw {
  float: none;
}
/* line 28, ../sass/_registration.scss */
.registration .registration-nav .login-form-forgotpw .login-logo {
  margin-top: 20px;
}
/* line 32, ../sass/_registration.scss */
.registration .registration-nav .login-newmember {
  float: right;
}
/* line 36, ../sass/_registration.scss */
.registration .form-check {
  display: inline-block;
  width: 145px;
}
/* line 40, ../sass/_registration.scss */
.registration .form-check-input {
  position: relative;
  z-index: 0;
  opacity: 1;
  display: inline-block;
  margin-right: .5rem;
}
/* line 47, ../sass/_registration.scss */
.registration .tip {
  margin-top: .5rem;
  padding: 0 .75rem;
  font-size: .75rem;
  color: #777777;
}
/* line 53, ../sass/_registration.scss */
.registration .remit {
  border: 1px solid #2492eb;
  border-radius: .25rem;
  padding: .5rem 0;
  margin-bottom: 1rem;
}
/* line 59, ../sass/_registration.scss */
.registration .remit .remit-item div {
  display: inline-block;
  padding: 5px 15px;
}
/* line 62, ../sass/_registration.scss */
.registration .remit .remit-item div:first-child {
  width: 20%;
  border-right: 1px solid #2492eb;
}
/* line 66, ../sass/_registration.scss */
.registration .remit .remit-item div:last-child {
  width: 80%;
}

@media screen and (max-width: 768px) {
  /* line 76, ../sass/_registration.scss */
  .registration .registration-nav .login-logo {
    width: 100%;
    height: auto;
  }
  /* line 80, ../sass/_registration.scss */
  .registration .registration-nav .login-newmember {
    float: none;
    display: block;
    text-align: right;
    padding-top: 10px;
  }
  /* line 89, ../sass/_registration.scss */
  .registration .remit .remit-item div {
    padding: 3px 10px;
  }
  /* line 91, ../sass/_registration.scss */
  .registration .remit .remit-item div:first-child {
    width: 100%;
    border-right: none;
  }
  /* line 95, ../sass/_registration.scss */
  .registration .remit .remit-item div:last-child {
    width: 100%;
    border-bottom: 1px solid #2492eb;
  }
  /* line 102, ../sass/_registration.scss */
  .registration .remit .remit-item:last-child div:last-child {
    border-bottom: none;
  }
}
/* line 1, ../sass/_review.scss */
#page-review {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
}

/* line 11, ../sass/_review.scss */
.section.open .active-bar {
  width: 11px;
  height: 100%;
  top: 0;
  left: -11px;
  right: auto;
  bottom: auto;
  opacity: 1;
  font-size: 1rem;
  border-radius: 0;
}
/* line 22, ../sass/_review.scss */
.section.open .active-bar:hover {
  opacity: 0.8;
  width: 11px;
  transition: opacity 0.3s ease-in;
}

/* line 31, ../sass/_review.scss */
.main-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}
/* line 37, ../sass/_review.scss */
.main-container.changing {
  user-select: none;
}

/* line 42, ../sass/_review.scss */
#top_bar {
  flex: 1;
}
/* line 45, ../sass/_review.scss */
#top_bar .view_tool {
  padding: 0 8%;
  height: 65px;
  background-color: #f4f4f7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
}
/* line 54, ../sass/_review.scss */
#top_bar .view_tool .page-detail {
  align-self: flex-start;
}
/* line 57, ../sass/_review.scss */
#top_bar .view_tool .page-detail-title {
  margin: 0;
  color: #104378;
  font-size: 0.9rem;
  padding-right: 5%;
}
/* line 64, ../sass/_review.scss */
#top_bar .view_tool .page-detail-subtitle {
  margin: 0;
  font-style: italic;
  color: #636363;
}
@media screen and (max-width: 768px) {
  /* line 71, ../sass/_review.scss */
  #top_bar .view_tool {
    padding-left: 70px;
    padding-right: 70px;
    flex-direction: column;
  }
}
/* line 78, ../sass/_review.scss */
#top_bar .pages-controls-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 768px) {
  /* line 84, ../sass/_review.scss */
  #top_bar .step-text {
    display: none;
  }
}
/* line 90, ../sass/_review.scss */
#top_bar .pages-single-wrapper > * {
  vertical-align: middle;
}
/* line 95, ../sass/_review.scss */
#top_bar .pages-wrapper {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
/* line 101, ../sass/_review.scss */
#top_bar .pages-wrapper i {
  padding: 0 8px;
  width: auto;
  text-align: center;
  color: #cccccc;
  border: 1px solid #cccccc;
  height: 28px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 0.8rem;
  cursor: pointer;
}
/* line 115, ../sass/_review.scss */
#top_bar .pages-wrapper i.disabled {
  color: #e4e4e4;
  border-color: #e4e4e4;
}
/* line 120, ../sass/_review.scss */
#top_bar .pages-wrapper i:first-child {
  margin-right: 10px;
}
/* line 123, ../sass/_review.scss */
#top_bar .pages-wrapper i:first-child span {
  margin-left: 5px;
  font-size: 0.7rem;
}
/* line 129, ../sass/_review.scss */
#top_bar .pages-wrapper i:last-child {
  margin-left: 10px;
}
/* line 132, ../sass/_review.scss */
#top_bar .pages-wrapper i:last-child span {
  margin-right: 5px;
  font-size: 0.7rem;
}
/* line 138, ../sass/_review.scss */
#top_bar .pages-wrapper i span, #top_bar .pages-wrapper i svg {
  vertical-align: middle;
}
/* line 142, ../sass/_review.scss */
#top_bar .pages-wrapper i:not(.pages-section-mark):not(.disabled):hover {
  color: #2492eb;
  border: 1px solid #2492eb;
}
/* line 147, ../sass/_review.scss */
#top_bar .pages-wrapper i.pages-section-mark {
  border-color: #2492eb;
  background-color: #2492eb;
  color: #fff;
}
@media screen and (max-width: 768px) {
  /* line 155, ../sass/_review.scss */
  #top_bar .pages-wrapper i:first-child {
    margin-right: 3px;
  }
  /* line 158, ../sass/_review.scss */
  #top_bar .pages-wrapper i:last-child {
    margin-left: 3px;
  }
}
/* line 165, ../sass/_review.scss */
#top_bar .pages-controls {
  flex-shrink: 0;
}
/* line 167, ../sass/_review.scss */
#top_bar .pages-controls .pages-input {
  height: 26px;
  width: 40px;
  text-align: center;
}
/* line 173, ../sass/_review.scss */
#top_bar .pages-controls span {
  width: 45px;
  display: inline-block;
  text-align: center;
}
/* line 179, ../sass/_review.scss */
#top_bar .pages-controls .btn {
  line-height: 1;
  padding: 0.3rem 0.75rem;
  margin-right: 10px;
}
/* line 186, ../sass/_review.scss */
#top_bar .btn-label-wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
}
/* line 191, ../sass/_review.scss */
#top_bar .btn-label-wrapper .btn {
  font-size: 14px;
  padding: 0 5px;
  width: 140px;
}
/* line 195, ../sass/_review.scss */
#top_bar .btn-label-wrapper .btn:first-child {
  margin-bottom: 8px;
}
@media screen and (max-width: 768px) {
  /* line 201, ../sass/_review.scss */
  #top_bar .btn-label-wrapper {
    flex-direction: row;
    width: 100%;
  }
  /* line 204, ../sass/_review.scss */
  #top_bar .btn-label-wrapper .btn {
    width: 40%;
    margin: 0 2%;
  }
  /* line 207, ../sass/_review.scss */
  #top_bar .btn-label-wrapper .btn:first-child {
    margin-bottom: 0px;
  }
}
/* line 214, ../sass/_review.scss */
#top_bar .view-bottom {
  font-size: 14px;
  width: 100%;
  height: 40px;
  background-color: #ffffff;
  border-top: 1px solid #ccc;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
/* line 225, ../sass/_review.scss */
#top_bar .switch-wrapper {
  width: 200px;
  height: 30px;
  display: flex;
  border: 1px solid #ccc;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}
/* line 234, ../sass/_review.scss */
#top_bar .switch-wrapper .switch-item {
  margin: 0;
  width: 50%;
  line-height: 28px;
  text-align: center;
  z-index: 1;
  color: #104378;
  cursor: pointer;
}
/* line 243, ../sass/_review.scss */
#top_bar .switch-wrapper .switch-item.active {
  color: #ffffff;
}
/* line 248, ../sass/_review.scss */
#top_bar .switch-wrapper .switch-active {
  width: 50%;
  height: 100%;
  position: absolute;
  background-color: #104378;
  z-index: 0;
  top: 0;
  transition: left 0.3s ease-in;
}
/* line 257, ../sass/_review.scss */
#top_bar .switch-wrapper .switch-active.pdf-view {
  left: 0;
}
/* line 261, ../sass/_review.scss */
#top_bar .switch-wrapper .switch-active.text-view {
  left: 50%;
}
@media screen and (max-width: 768px) {
  /* line 267, ../sass/_review.scss */
  #top_bar .switch-wrapper {
    width: 120px;
  }
}
/* line 272, ../sass/_review.scss */
#top_bar .pdf-scare-wrapper {
  flex-shrink: 0;
}
/* line 274, ../sass/_review.scss */
#top_bar .pdf-scare-wrapper .pdf-scare-text {
  padding: 0 10px;
  line-height: 24px;
}
@media screen and (max-width: 768px) {
  /* line 279, ../sass/_review.scss */
  #top_bar .pdf-scare-wrapper .pdf-scare-text {
    padding: 0 2px;
  }
}
/* line 284, ../sass/_review.scss */
#top_bar .pdf-scare-wrapper .pdf-scare-icon {
  width: 24px;
  height: 24px;
  color: #656565;
  cursor: pointer;
  border: #656565 solid 2px;
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
/* line 295, ../sass/_review.scss */
#top_bar .pdf-scare-wrapper .pdf-scare-icon:hover {
  border-color: #2492eb;
  color: #2492eb;
}

/* line 303, ../sass/_review.scss */
#right_bar {
  width: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  border-left: 3px solid transparent;
  background-color: #fff;
  z-index: 6;
}
/* line 314, ../sass/_review.scss */
#right_bar.open {
  width: 25%;
}
/* line 316, ../sass/_review.scss */
#right_bar.open .right-item-wrapper {
  display: block;
}
/* line 318, ../sass/_review.scss */
#right_bar.open .right-item-wrapper ul.label-item-content li {
  padding: 5px 0;
}
/* line 325, ../sass/_review.scss */
#right_bar:not(.open) .active-bar {
  background-color: transparent;
  color: #104378;
  border-left: #ececec solid 1px;
}
/* line 330, ../sass/_review.scss */
#right_bar:not(.open) .active-bar:hover {
  background-color: #104378;
  color: #fff;
}
/* line 337, ../sass/_review.scss */
#right_bar .right-item-wrapper {
  display: none;
}
/* line 340, ../sass/_review.scss */
#right_bar .right-item-wrapper.open {
  height: calc(100% / 3);
  flex: 1 1 auto;
}
/* line 344, ../sass/_review.scss */
#right_bar .right-item-wrapper.open .right-item-title {
  border-bottom: #ccc solid thin;
}
/* line 348, ../sass/_review.scss */
#right_bar .right-item-wrapper.open .right-item-content {
  height: calc(100% - 45px);
  padding: 10px;
}
/* line 353, ../sass/_review.scss */
#right_bar .right-item-wrapper.open #label_Content {
  padding-bottom: 50px;
}
/* line 359, ../sass/_review.scss */
#right_bar .right-item-wrapper:last-of-type .right-item-title {
  border-bottom: #ccc solid thin;
}
/* line 365, ../sass/_review.scss */
#right_bar .right-item-title {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  cursor: pointer;
  border-top: #ccc solid thin;
  background-color: #f4f4f7;
}
/* line 377, ../sass/_review.scss */
#right_bar .title-wrapper {
  display: flex;
  align-items: center;
  color: #104378;
}
/* line 382, ../sass/_review.scss */
#right_bar .title-name {
  font-size: 1rem;
}
/* line 386, ../sass/_review.scss */
#right_bar .title-active-mark {
  margin-right: 8px;
}
/* line 390, ../sass/_review.scss */
#right_bar .title-number {
  margin-left: 5px;
  border-radius: 100%;
  background-color: #2492eb;
  width: 22px;
  height: 22px;
  text-align: center;
  color: white;
}
/* line 400, ../sass/_review.scss */
#right_bar .tool-wrapper .btn {
  line-height: 1;
  font-size: 0.85rem;
}
/* line 405, ../sass/_review.scss */
#right_bar .right-item-content {
  height: 0;
  overflow-y: auto;
}

/* line 413, ../sass/_review.scss */
#label_Content .label-item {
  padding-bottom: 20px;
}
/* line 416, ../sass/_review.scss */
#label_Content .label-item:not(:first-of-type) {
  padding-top: 10px;
}
/* line 420, ../sass/_review.scss */
#label_Content .label-item:not(:last-of-type) {
  border-bottom: #e3e4ef solid thin;
}
/* line 425, ../sass/_review.scss */
#label_Content .label-item-title {
  align-items: center;
  min-height: 40px;
}
/* line 429, ../sass/_review.scss */
#label_Content .label-item-title i {
  cursor: pointer;
}
/* line 433, ../sass/_review.scss */
#label_Content .label-item-title label {
  color: #104378;
  width: calc(100% - 100px);
}
/* line 437, ../sass/_review.scss */
#label_Content .label-item-title label span {
  width: calc(100% - 25px);
  display: inline-block;
  word-break: break-word;
  vertical-align: text-top;
}
/* line 445, ../sass/_review.scss */
#label_Content .label-item-title .label-userfolder {
  color: #104378;
  font-size: 1rem;
}
/* line 449, ../sass/_review.scss */
#label_Content .label-item-title .label-userfolder:hover {
  color: #2492eb;
}
/* line 454, ../sass/_review.scss */
#label_Content .label-item-title .label-download {
  color: #104378;
  margin-left: 18px;
  font-size: 1rem;
}
/* line 459, ../sass/_review.scss */
#label_Content .label-item-title .label-download:hover {
  color: #2492eb;
}
/* line 464, ../sass/_review.scss */
#label_Content .label-item-title .label-delete {
  color: #dc3545;
  margin-left: 18px;
  font-size: 1rem;
}
/* line 469, ../sass/_review.scss */
#label_Content .label-item-title .label-delete:hover {
  color: #dc626e;
}
/* line 475, ../sass/_review.scss */
#label_Content .label-item-content {
  padding-left: 15px;
}
/* line 478, ../sass/_review.scss */
#label_Content .label-item-content li {
  font-size: 14px;
}
/* line 481, ../sass/_review.scss */
#label_Content .label-item-content li:not(:last-of-type) {
  border-bottom: #cbcbdc dashed 1px;
}
/* line 485, ../sass/_review.scss */
#label_Content .label-item-content li:hover {
  background-color: transparent;
}
/* line 489, ../sass/_review.scss */
#label_Content .label-item-content li i {
  width: 20px;
  height: 20px;
  border: #ffc107 solid thin;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 20px;
  border-radius: 5px;
  color: #ffc107;
}
/* line 500, ../sass/_review.scss */
#label_Content .label-item-content li i:hover {
  background-color: #ffc107;
  color: #fff;
}
/* line 508, ../sass/_review.scss */
#label_Content .label-item-note {
  padding-top: 10px;
}

/* line 512, ../sass/_review.scss */
#notes-wrapper {
  position: relative;
  top: 0;
  left: 0;
  font-size: 14px;
}
/* line 517, ../sass/_review.scss */
#notes-wrapper li.sidebar-notes-item {
  padding: 5px;
  display: flex;
}
/* line 520, ../sass/_review.scss */
#notes-wrapper li.sidebar-notes-item:not(:last-of-type) {
  border-bottom: #cbcbdc dashed 1px;
}
/* line 523, ../sass/_review.scss */
#notes-wrapper li.sidebar-notes-item:hover {
  background-color: #e9e9e9;
  cursor: pointer;
}
/* line 527, ../sass/_review.scss */
#notes-wrapper li.sidebar-notes-item i {
  width: 24px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* line 534, ../sass/_review.scss */
#notes-wrapper li.sidebar-notes-item .item-date,
#notes-wrapper li.sidebar-notes-item .item-title {
  width: 80px;
  flex-shrink: 0;
}
/* line 539, ../sass/_review.scss */
#notes-wrapper li.sidebar-notes-item .item-content {
  width: calc(100% - 160px);
}
/* line 542, ../sass/_review.scss */
#notes-wrapper li.sidebar-notes-item .item-title,
#notes-wrapper li.sidebar-notes-item .item-content {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* line 550, ../sass/_review.scss */
.note-model {
  font-size: 14px;
  width: 300px;
  height: 200px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
  position: absolute;
  z-index: 100;
  right: calc( 25% - 150px );
  bottom: 10px;
}
/* line 560, ../sass/_review.scss */
.note-model button {
  background-color: transparent;
  border: none;
}
/* line 563, ../sass/_review.scss */
.note-model button:focus {
  outline: none;
}

/* line 568, ../sass/_review.scss */
.note-model-title {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dadada;
}
/* line 572, ../sass/_review.scss */
.note-model-title input {
  border: none;
  flex-grow: 1;
  padding: 0 10px;
  outline: none;
}
/* line 578, ../sass/_review.scss */
.note-model-title span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  padding: 5px 10px;
}
/* line 585, ../sass/_review.scss */
.note-model-title button {
  padding: 5px;
  width: 40px;
  color: #464646;
}
/* line 589, ../sass/_review.scss */
.note-model-title button:hover {
  color: #2492eb;
}

/* line 594, ../sass/_review.scss */
.note-model-content {
  padding: 5px;
}
/* line 596, ../sass/_review.scss */
.note-model-content textarea {
  width: 100%;
  padding: 5px;
  outline: none;
}
/* line 601, ../sass/_review.scss */
.note-model-content .note-model-content-text {
  padding: 5px;
  min-height: 110px;
}
/* line 605, ../sass/_review.scss */
.note-model-content .note-model-content-tool {
  display: flex;
  justify-content: flex-end;
}
/* line 608, ../sass/_review.scss */
.note-model-content .note-model-content-tool button {
  background-color: #2492eb;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 5px;
  margin-left: 3px;
}

/* line 619, ../sass/_review.scss */
#highlight-wrapper .sidebar-highlight {
  display: flex;
  align-items: center;
  min-height: 40px;
  cursor: pointer;
  flex-direction: column;
  transition: background 140ms ease-in;
  padding: 0;
}
/* line 628, ../sass/_review.scss */
#highlight-wrapper .sidebar-highlight:hover {
  background-color: transparent;
}
/* line 632, ../sass/_review.scss */
#highlight-wrapper .sidebar-highlight p {
  margin: 0;
}
/* line 636, ../sass/_review.scss */
#highlight-wrapper .sidebar-highlight .highlights-title {
  width: 100%;
  min-height: 30px;
  padding: 8px 5px;
  font-size: 0.85rem;
  color: #104378;
}
/* line 644, ../sass/_review.scss */
#highlight-wrapper .sidebar-highlight .highlight-wrapper {
  width: 100%;
  padding-left: 15px;
  min-height: 35px;
}
/* line 650, ../sass/_review.scss */
#highlight-wrapper .sidebar-highlight .highlight-content-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  border-bottom: #cbcbdc dashed 1px;
  min-height: 30px;
  padding: 5px 0;
}
/* line 659, ../sass/_review.scss */
#highlight-wrapper .sidebar-highlight .highlight-content-wrapper:hover {
  color: #104378;
  background: rgba(58, 56, 52, 0.08);
}
/* line 665, ../sass/_review.scss */
#highlight-wrapper .sidebar-highlight .highlight-pageindex {
  width: 60px;
  text-align: center;
  font-size: 0.8rem;
}
/* line 671, ../sass/_review.scss */
#highlight-wrapper .sidebar-highlight .highlight-content {
  width: calc(100% - 60px);
  cursor: pointer;
  word-break: break-word;
}
/* line 676, ../sass/_review.scss */
#highlight-wrapper .sidebar-highlight .highlight-content:not(:last-of-type) {
  border-bottom: #e3e4ef solid thin;
}
/* line 682, ../sass/_review.scss */
#highlight-wrapper blockquote {
  margin-top: 0.5rem;
  padding: 0;
  quotes: "\201c" "\201d";
}
/* line 687, ../sass/_review.scss */
#highlight-wrapper blockquote:before {
  content: open-quote;
}
/* line 691, ../sass/_review.scss */
#highlight-wrapper blockquote:after {
  content: close-quote;
}

/* line 697, ../sass/_review.scss */
#left_bar {
  width: 0;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  border-right: 3px solid transparent;
  background-color: #fff;
  z-index: 6;
}
/* line 706, ../sass/_review.scss */
#left_bar.open {
  width: 25%;
}
/* line 709, ../sass/_review.scss */
#left_bar.open .active-bar {
  left: auto;
  right: -11px;
}
/* line 716, ../sass/_review.scss */
#left_bar:not(.open) .generator {
  display: none;
}
/* line 720, ../sass/_review.scss */
#left_bar:not(.open) .active-bar {
  background-color: transparent;
  border-right: #ececec solid 1px;
}
/* line 724, ../sass/_review.scss */
#left_bar:not(.open) .active-bar:hover {
  background-color: #ececec;
}
/* line 730, ../sass/_review.scss */
#left_bar .active-bar {
  left: 0;
  right: auto;
}
/* line 734, ../sass/_review.scss */
#left_bar .active-bar .logo {
  width: 50px;
}
/* line 739, ../sass/_review.scss */
#left_bar .generator {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
/* line 746, ../sass/_review.scss */
#left_bar .generator_title {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
/* line 752, ../sass/_review.scss */
#left_bar .generator_title .generator-left {
  display: flex;
  flex-grow: 1;
  margin-right: 10px;
}
/* line 756, ../sass/_review.scss */
#left_bar .generator_title .generator-left:hover {
  cursor: pointer;
}
/* line 760, ../sass/_review.scss */
#left_bar .generator_title .user-logo-wrapper {
  width: 55px;
  height: 60px;
  display: inline-flex;
  align-items: center;
  margin: 0px;
  flex: 0 0 auto;
}
/* line 768, ../sass/_review.scss */
#left_bar .generator_title .user-logo {
  width: auto;
  height: 65%;
  margin: auto;
}
/* line 773, ../sass/_review.scss */
#left_bar .generator_title .user-title-wrapper {
  margin: 0;
}
/* line 776, ../sass/_review.scss */
#left_bar .generator_title .user-tab-wrapper {
  margin-bottom: 0;
  font-size: 24px;
  padding: 12px;
  margin-right: 10px;
  color: #13457a;
}
/* line 782, ../sass/_review.scss */
#left_bar .generator_title .user-tab-wrapper:hover {
  color: #2693ec;
  background-color: #dbe4ea;
  border-radius: 30px;
}
/* line 788, ../sass/_review.scss */
#left_bar .generator_title .user-mainTitle {
  color: #104378;
  font-size: 18px;
  font-weight: 600;
  display: block;
  line-height: 30px;
  letter-spacing: 2px;
  line-height: 60px;
}
/* line 797, ../sass/_review.scss */
#left_bar .generator_title .user-mainTitle .user-mainTitle-icon {
  height: 1rem;
  width: 20px;
  font-size: 0.9rem;
  text-align: right;
}
/* line 804, ../sass/_review.scss */
#left_bar .generator_title .btn-upload {
  margin-right: 10px;
}
/* line 808, ../sass/_review.scss */
#left_bar .generator_title .user-toolBar-popup {
  top: 75%;
}
/* line 812, ../sass/_review.scss */
#left_bar .generator_title .generator_title-icon {
  margin-left: 10px;
}
/* line 817, ../sass/_review.scss */
#left_bar .generator_tab {
  font-size: 14px;
  background-color: #104378;
  height: 45px;
  flex: 0 0 45px;
}
@media screen and (max-width: 768px) {
  /* line 825, ../sass/_review.scss */
  #left_bar .generator_tab .nav-item {
    width: 25%;
  }
}

/* line 832, ../sass/_review.scss */
.active-bar {
  position: absolute;
  width: 65px;
  height: 64px;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 2rem;
  z-index: 2;
  background-color: #104378;
  color: #fff;
}
/* line 847, ../sass/_review.scss */
.active-bar:hover {
  transition: all 0.3s ease-in;
}

/* line 853, ../sass/_review.scss */
.pagetype-wrapper .system_item ul {
  width: calc(100% - 12px);
}

/* line 858, ../sass/_review.scss */
.folder-wrapper {
  font-size: 14px;
}
/* line 862, ../sass/_review.scss */
.folder-wrapper.isFolder .folder-name-wrapper {
  padding-left: 10px;
  padding-right: 10px;
}
/* line 867, ../sass/_review.scss */
.folder-wrapper.isFolder .folder-children .system_item {
  padding-left: 30px;
}
/* line 870, ../sass/_review.scss */
.folder-wrapper.isFolder .folder-children .system_item .item_title {
  margin: 0;
}
/* line 876, ../sass/_review.scss */
.folder-wrapper .system_letters {
  padding: 0px 15px;
  margin-bottom: 5px;
}
/* line 881, ../sass/_review.scss */
.folder-wrapper .folder-name-wrapper {
  min-height: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #dee2e6;
  position: relative;
  color: #104378;
  font-weight: 600;
}
/* line 891, ../sass/_review.scss */
.folder-wrapper .folder-name-wrapper:hover {
  background-color: #f5f5f5;
}
/* line 894, ../sass/_review.scss */
.folder-wrapper .folder-name-wrapper:hover .folder-tools {
  display: inline-flex;
}
/* line 900, ../sass/_review.scss */
.folder-wrapper .folder-icon {
  width: 15px;
  margin: 0;
}
/* line 905, ../sass/_review.scss */
.folder-wrapper .folder-name {
  word-break: break-word;
  width: calc(90% - 15px);
  margin: 0;
}
/* line 911, ../sass/_review.scss */
.folder-wrapper .folder-tools {
  display: none;
  margin: 0;
  flex: 0 0;
}
/* line 917, ../sass/_review.scss */
.folder-wrapper .folder-tool {
  cursor: pointer;
  width: 22px;
  text-align: right;
  color: #999999;
}
/* line 923, ../sass/_review.scss */
.folder-wrapper .folder-tool:hover {
  color: #2492eb;
}
/* line 927, ../sass/_review.scss */
.folder-wrapper .folder-tool.folder-edit {
  right: calc(2% + 30px);
}
/* line 931, ../sass/_review.scss */
.folder-wrapper .folder-tool.folder-trash {
  right: 3%;
}

/* line 938, ../sass/_review.scss */
.folders-wrapper.changing {
  padding-bottom: 40px;
}

/* line 944, ../sass/_review.scss */
.pageType_wrapper .system_item {
  padding-left: 30px;
}
/* line 947, ../sass/_review.scss */
.pageType_wrapper .system_item .pageType_title {
  width: calc(100% - 12px);
}

/* line 954, ../sass/_review.scss */
.tag-wrapper .system_item {
  font-size: 14px;
}

/* line 960, ../sass/_review.scss */
.keyword-wrapper .system_item {
  font-size: 14px;
}

/* line 965, ../sass/_review.scss */
.selectedFileItem {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 300px;
  border-radius: 25px;
  border: #ccc solid thin;
  background-color: #ccc;
  padding: 5px 15px;
  z-index: 5;
}
/* line 977, ../sass/_review.scss */
.selectedFileItem.display {
  display: block;
}
/* line 981, ../sass/_review.scss */
.selectedFileItem .selectedFileItem-icon {
  padding-right: 5px;
}

/* line 986, ../sass/_review.scss */
.file-top-wrapper {
  display: flex;
  justify-content: flex-end;
  padding: 0 5%;
  height: 40px;
  align-items: flex-start;
}
/* line 993, ../sass/_review.scss */
.file-top-wrapper .btn-outline-primary {
  margin-right: 15px;
}

/* line 998, ../sass/_review.scss */
.file-bottom-wrapper {
  padding: 10px 5%;
  justify-content: flex-end;
  display: flex;
}
/* line 1003, ../sass/_review.scss */
.file-bottom-wrapper .btn-outline-primary {
  margin-right: 10px;
}

@media screen and (max-width: 1024px) {
  /* line 1009, ../sass/_review.scss */
  .main-container {
    overflow: hidden;
  }

  /* line 1012, ../sass/_review.scss */
  #left_bar {
    width: 330px;
    position: absolute;
    left: -330px;
    top: 0;
    transition: left 0.3s ease-in;
    z-index: 10;
  }
  /* line 1020, ../sass/_review.scss */
  #left_bar.open {
    left: 0;
    width: 330px;
  }
  /* line 1026, ../sass/_review.scss */
  #left_bar:not(.open) .active-bar {
    width: 70px;
    right: -70px;
    left: auto;
  }
  /* line 1033, ../sass/_review.scss */
  #left_bar .active-bar {
    opacity: 1;
    transition: none;
  }

  /* line 1039, ../sass/_review.scss */
  #right_bar {
    width: 330px;
    position: absolute;
    right: -330px;
    top: 0;
    transition: right 0.3s ease-in;
    z-index: 9;
  }
  /* line 1047, ../sass/_review.scss */
  #right_bar.open {
    right: 0;
    width: 350px;
  }
  /* line 1053, ../sass/_review.scss */
  #right_bar:not(.open) .active-bar {
    width: 70px;
    left: -70px;
    right: auto;
  }
  /* line 1060, ../sass/_review.scss */
  #right_bar .active-bar {
    opacity: 1;
    transition: none;
  }

  /* line 1067, ../sass/_review.scss */
  .folder-wrapper .folder-tool {
    display: inline-flex;
  }
}
/* line 1073, ../sass/_review.scss */
.note-draggable {
  position: fixed;
  z-index: 6;
}

/* pdf viewer */
/* line 2, ../sass/_pdf.scss */
.pdf-view-container {
  width: 100%;
  height: calc(100% - 105px);
  overflow: hidden;
  position: relative;
}
/* line 8, ../sass/_pdf.scss */
.pdf-view-container .pdfViewer.removePageBorders .page {
  margin: 0 auto;
}

/* line 13, ../sass/_pdf.scss */
.text-view-container {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: relative;
  padding: 2% 3%;
  line-height: 1.5;
  word-break: break-word;
  font-size: 16px;
}

/* line 25, ../sass/_pdf.scss */
.page-control-wrapper .icon_tool {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  border: #2492eb solid thin;
  top: calc(50% - 25px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  color: #2492eb;
  cursor: pointer;
  opacity: 0.5;
  background-color: rgba(204, 204, 204, 0.5);
  z-index: 3;
}
/* line 42, ../sass/_pdf.scss */
.page-control-wrapper .icon_tool:first-of-type {
  left: 10px;
}
/* line 46, ../sass/_pdf.scss */
.page-control-wrapper .icon_tool:last-of-type {
  right: 30px;
}
/* line 50, ../sass/_pdf.scss */
.page-control-wrapper .icon_tool span {
  position: absolute;
  font-size: 1rem;
  top: 105%;
  left: 0;
}

/* line 59, ../sass/_pdf.scss */
.pdf-viewer {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: relative;
  overflow-y: auto;
}
/* line 66, ../sass/_pdf.scss */
.pdf-viewer .viewer {
  width: 100%;
  height: 100%;
}

/* line 72, ../sass/_pdf.scss */
.pdfViewer.removePageBorders .page {
  margin: 0;
}

@media screen and (min-width: 1025px) {
  /* line 77, ../sass/_pdf.scss */
  .page-control-wrapper {
    display: none;
  }
}
/* line 1, ../sass/_loader.scss */
.loading-dna {
  display: inline-block;
  position: relative;
  width: 170px;
  height: 170px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
}

/* line 11, ../sass/_loader.scss */
.loading-item {
  width: 150px;
  height: 50px;
  display: block;
  position: absolute;
  top: 35%;
  left: 8%;
}
/* line 20, ../sass/_loader.scss */
.loading-item.blue div {
  animation: ani-loading-ball 1.2s ease-in-out infinite;
}
/* line 24, ../sass/_loader.scss */
.loading-item.green div {
  animation: ani-loading-ball-reverse 1.2s ease-in-out infinite;
}
/* line 28, ../sass/_loader.scss */
.loading-item div {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  z-index: 3;
}
/* line 36, ../sass/_loader.scss */
.loading-item div:nth-child(1) {
  animation-delay: 0s;
  left: 0px;
}
/* line 36, ../sass/_loader.scss */
.loading-item div:nth-child(2) {
  animation-delay: -0.1s;
  left: 15px;
}
/* line 36, ../sass/_loader.scss */
.loading-item div:nth-child(3) {
  animation-delay: -0.2s;
  left: 30px;
}
/* line 36, ../sass/_loader.scss */
.loading-item div:nth-child(4) {
  animation-delay: -0.3s;
  left: 45px;
}
/* line 36, ../sass/_loader.scss */
.loading-item div:nth-child(5) {
  animation-delay: -0.4s;
  left: 60px;
}
/* line 36, ../sass/_loader.scss */
.loading-item div:nth-child(6) {
  animation-delay: -0.5s;
  left: 75px;
}
/* line 36, ../sass/_loader.scss */
.loading-item div:nth-child(7) {
  animation-delay: -0.6s;
  left: 90px;
}
/* line 36, ../sass/_loader.scss */
.loading-item div:nth-child(8) {
  animation-delay: -0.7s;
  left: 105px;
}
/* line 36, ../sass/_loader.scss */
.loading-item div:nth-child(9) {
  animation-delay: -0.8s;
  left: 120px;
}
/* line 36, ../sass/_loader.scss */
.loading-item div:nth-child(10) {
  animation-delay: -0.9s;
  left: 135px;
}

/* line 44, ../sass/_loader.scss */
.loading-item-line {
  width: 150px;
  height: 50px;
  display: block;
  position: absolute;
  top: 35%;
  left: 8%;
}
/* line 53, ../sass/_loader.scss */
.loading-item-line div {
  position: absolute;
  width: 1px;
  height: 1px;
  background-color: #9e9d9d;
  animation: ani-loading-line 0.6s ease-in-out infinite;
  transform-origin: center;
  top: 50%;
  z-index: 1;
}
/* line 64, ../sass/_loader.scss */
.loading-item-line div:nth-child(1) {
  left: 3px;
  animation-delay: 0s;
}
/* line 64, ../sass/_loader.scss */
.loading-item-line div:nth-child(2) {
  left: 18px;
  animation-delay: -0.1s;
}
/* line 64, ../sass/_loader.scss */
.loading-item-line div:nth-child(3) {
  left: 33px;
  animation-delay: -0.2s;
}
/* line 64, ../sass/_loader.scss */
.loading-item-line div:nth-child(4) {
  left: 48px;
  animation-delay: -0.3s;
}
/* line 64, ../sass/_loader.scss */
.loading-item-line div:nth-child(5) {
  left: 63px;
  animation-delay: -0.4s;
}
/* line 64, ../sass/_loader.scss */
.loading-item-line div:nth-child(6) {
  left: 78px;
  animation-delay: -0.5s;
}
/* line 64, ../sass/_loader.scss */
.loading-item-line div:nth-child(7) {
  left: 93px;
  animation-delay: -0.6s;
}
/* line 64, ../sass/_loader.scss */
.loading-item-line div:nth-child(8) {
  left: 108px;
  animation-delay: -0.7s;
}
/* line 64, ../sass/_loader.scss */
.loading-item-line div:nth-child(9) {
  left: 123px;
  animation-delay: -0.8s;
}
/* line 64, ../sass/_loader.scss */
.loading-item-line div:nth-child(10) {
  left: 138px;
  animation-delay: -0.9s;
}

@keyframes ani-loading-ball {
  0%, 100% {
    transform: scale(1);
    top: 0;
    background-color: #104378;
  }
  50% {
    transform: scale(1.5);
    top: 90%;
    background-color: #2492eb;
  }
}
@keyframes ani-loading-ball-reverse {
  0%, 100% {
    transform: scale(1);
    top: 90%;
    background-color: #2492eb;
  }
  50% {
    transform: scale(1.5);
    top: 0;
    background-color: #104378;
  }
}
@keyframes ani-loading-line {
  0%, 100% {
    transform: scale(1, 25);
  }
  50% {
    transform: scale(1, 1);
  }
}
/* line 3, ../sass/_search.scss */
.search_result .search-result a {
  color: #007bff;
  text-decoration: none;
  display: inline-block;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
}
/* line 10, ../sass/_search.scss */
.search_result .search-result a:hover {
  background-color: #f5f5f5;
}
/* line 14, ../sass/_search.scss */
.search_result .search-result .item-head,
.search_result .search-result .item {
  display: flex;
}
/* line 17, ../sass/_search.scss */
.search_result .search-result .item-head ul.list-head,
.search_result .search-result .item-head .content,
.search_result .search-result .item ul.list-head,
.search_result .search-result .item .content {
  width: 75%;
}
/* line 20, ../sass/_search.scss */
.search_result .search-result .item-head ul.list-head .description,
.search_result .search-result .item-head .content .description,
.search_result .search-result .item ul.list-head .description,
.search_result .search-result .item .content .description {
  padding: 5px 3px;
}
/* line 24, ../sass/_search.scss */
.search_result .search-result .item-head ul.list-head,
.search_result .search-result .item-head ul.list-body,
.search_result .search-result .item ul.list-head,
.search_result .search-result .item ul.list-body {
  display: flex;
}
/* line 27, ../sass/_search.scss */
.search_result .search-result .item-head ul.list-head li,
.search_result .search-result .item-head ul.list-body li,
.search_result .search-result .item ul.list-head li,
.search_result .search-result .item ul.list-body li {
  padding: 3px;
}
/* line 30, ../sass/_search.scss */
.search_result .search-result .item-head ul.list-head li.add-case,
.search_result .search-result .item-head ul.list-body li.add-case,
.search_result .search-result .item ul.list-head li.add-case,
.search_result .search-result .item ul.list-body li.add-case {
  width: 5%;
}
/* line 32, ../sass/_search.scss */
.search_result .search-result .item-head ul.list-head li.add-case button,
.search_result .search-result .item-head ul.list-body li.add-case button,
.search_result .search-result .item ul.list-head li.add-case button,
.search_result .search-result .item ul.list-body li.add-case button {
  background-color: transparent;
  border: none;
  color: #b5b5b5;
  display: inline-block;
  width: 100%;
}
/* line 38, ../sass/_search.scss */
.search_result .search-result .item-head ul.list-head li.add-case button:focus,
.search_result .search-result .item-head ul.list-body li.add-case button:focus,
.search_result .search-result .item ul.list-head li.add-case button:focus,
.search_result .search-result .item ul.list-body li.add-case button:focus {
  outline: none;
}
/* line 41, ../sass/_search.scss */
.search_result .search-result .item-head ul.list-head li.add-case button:hover,
.search_result .search-result .item-head ul.list-body li.add-case button:hover,
.search_result .search-result .item ul.list-head li.add-case button:hover,
.search_result .search-result .item ul.list-body li.add-case button:hover {
  color: #007bff;
}
/* line 46, ../sass/_search.scss */
.search_result .search-result .item-head ul.list-head li.title,
.search_result .search-result .item-head ul.list-body li.title,
.search_result .search-result .item ul.list-head li.title,
.search_result .search-result .item ul.list-body li.title {
  width: 58%;
}
/* line 49, ../sass/_search.scss */
.search_result .search-result .item-head ul.list-head li.date,
.search_result .search-result .item-head ul.list-body li.date,
.search_result .search-result .item ul.list-head li.date,
.search_result .search-result .item ul.list-body li.date {
  width: 12%;
}
/* line 52, ../sass/_search.scss */
.search_result .search-result .item-head ul.list-head li.summary,
.search_result .search-result .item-head ul.list-body li.summary,
.search_result .search-result .item ul.list-head li.summary,
.search_result .search-result .item ul.list-body li.summary {
  width: 25%;
}
/* line 56, ../sass/_search.scss */
.search_result .search-result .item-head .law,
.search_result .search-result .item .law {
  width: 25%;
}
/* line 58, ../sass/_search.scss */
.search_result .search-result .item-head .law div,
.search_result .search-result .item .law div {
  padding: 3px 5px;
}
/* line 63, ../sass/_search.scss */
.search_result .search-result .item-head {
  font-weight: bold;
  border-bottom: 2px solid #c9c9c9;
}
/* line 66, ../sass/_search.scss */
.search_result .search-result .item-head .law {
  padding: 3px 8px;
}
/* line 70, ../sass/_search.scss */
.search_result .search-result .item {
  border-bottom: 1px solid #c9c9c9;
}

/* line 1, ../sass/_mailroom.scss */
.mailroom-section {
  position: relative;
  height: calc(100% - 15px);
  overflow: hidden;
}
/* line 5, ../sass/_mailroom.scss */
.mailroom-section .mailroomreceivelist,
.mailroom-section .mailroomsentlist {
  overflow-y: auto;
}
/* line 9, ../sass/_mailroom.scss */
.mailroom-section .mailroomreceivelist ul.case-list-head,
.mailroom-section .mailroomreceivelist ul.case-list-body,
.mailroom-section .mailroomsentlist ul.case-list-head,
.mailroom-section .mailroomsentlist ul.case-list-body {
  font-size: 1rem;
}
/* line 13, ../sass/_mailroom.scss */
.mailroom-section .mailroomreceivelist ul.case-list-head li[class$='-name'],
.mailroom-section .mailroomreceivelist ul.case-list-body li[class$='-name'],
.mailroom-section .mailroomsentlist ul.case-list-head li[class$='-name'],
.mailroom-section .mailroomsentlist ul.case-list-body li[class$='-name'] {
  text-align: left;
}
/* line 16, ../sass/_mailroom.scss */
.mailroom-section .mailroomreceivelist ul.case-list-head li[class$='-docketName'],
.mailroom-section .mailroomreceivelist ul.case-list-body li[class$='-docketName'],
.mailroom-section .mailroomsentlist ul.case-list-head li[class$='-docketName'],
.mailroom-section .mailroomsentlist ul.case-list-body li[class$='-docketName'] {
  flex-grow: 1;
  text-align: left;
}
/* line 20, ../sass/_mailroom.scss */
.mailroom-section .mailroomreceivelist ul.case-list-head li[class$='-type'], .mailroom-section .mailroomreceivelist ul.case-list-head li[class$='-court'], .mailroom-section .mailroomreceivelist ul.case-list-head li[class$='-sender'],
.mailroom-section .mailroomreceivelist ul.case-list-body li[class$='-type'],
.mailroom-section .mailroomreceivelist ul.case-list-body li[class$='-court'],
.mailroom-section .mailroomreceivelist ul.case-list-body li[class$='-sender'],
.mailroom-section .mailroomsentlist ul.case-list-head li[class$='-type'],
.mailroom-section .mailroomsentlist ul.case-list-head li[class$='-court'],
.mailroom-section .mailroomsentlist ul.case-list-head li[class$='-sender'],
.mailroom-section .mailroomsentlist ul.case-list-body li[class$='-type'],
.mailroom-section .mailroomsentlist ul.case-list-body li[class$='-court'],
.mailroom-section .mailroomsentlist ul.case-list-body li[class$='-sender'] {
  min-width: 120px;
}
/* line 26, ../sass/_mailroom.scss */
.mailroom-section .mailroomreceivelist ul.case-list-head li[class$='-action'] button,
.mailroom-section .mailroomreceivelist ul.case-list-body li[class$='-action'] button,
.mailroom-section .mailroomsentlist ul.case-list-head li[class$='-action'] button,
.mailroom-section .mailroomsentlist ul.case-list-body li[class$='-action'] button {
  border: none;
  background-color: transparent;
  border-radius: 3px;
}
/* line 30, ../sass/_mailroom.scss */
.mailroom-section .mailroomreceivelist ul.case-list-head li[class$='-action'] button:focus,
.mailroom-section .mailroomreceivelist ul.case-list-body li[class$='-action'] button:focus,
.mailroom-section .mailroomsentlist ul.case-list-head li[class$='-action'] button:focus,
.mailroom-section .mailroomsentlist ul.case-list-body li[class$='-action'] button:focus {
  outline: none;
}
/* line 33, ../sass/_mailroom.scss */
.mailroom-section .mailroomreceivelist ul.case-list-head li[class$='-action'] button:hover,
.mailroom-section .mailroomreceivelist ul.case-list-body li[class$='-action'] button:hover,
.mailroom-section .mailroomsentlist ul.case-list-head li[class$='-action'] button:hover,
.mailroom-section .mailroomsentlist ul.case-list-body li[class$='-action'] button:hover {
  background-color: #2492eb;
  color: #ffffff;
}
/* line 41, ../sass/_mailroom.scss */
.mailroom-section .mailroomreceivelist .case-file-extend,
.mailroom-section .mailroomsentlist .case-file-extend {
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}
/* line 45, ../sass/_mailroom.scss */
.mailroom-section .mailroomreceivelist .case-file-extend-head,
.mailroom-section .mailroomsentlist .case-file-extend-head {
  padding: 0;
}
/* line 48, ../sass/_mailroom.scss */
.mailroom-section .mailroomreceivelist .case-file-extend-head form .ant-upload-list-item,
.mailroom-section .mailroomsentlist .case-file-extend-head form .ant-upload-list-item {
  width: 510px;
}
/* line 51, ../sass/_mailroom.scss */
.mailroom-section .mailroomreceivelist .case-file-extend-head form button,
.mailroom-section .mailroomsentlist .case-file-extend-head form button {
  line-height: 27px;
}
/* line 54, ../sass/_mailroom.scss */
.mailroom-section .mailroomreceivelist .case-file-extend-head form .ant-upload-list-item-name,
.mailroom-section .mailroomsentlist .case-file-extend-head form .ant-upload-list-item-name {
  word-wrap: break-word;
}
/* line 58, ../sass/_mailroom.scss */
.mailroom-section .mailroomreceivelist .case-file-extend-head form .ant-upload-list-item-card-actions button.ant-btn,
.mailroom-section .mailroomsentlist .case-file-extend-head form .ant-upload-list-item-card-actions button.ant-btn {
  line-height: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  position: relative;
}
/* line 63, ../sass/_mailroom.scss */
.mailroom-section .mailroomreceivelist .case-file-extend-head form .ant-upload-list-item-card-actions button.ant-btn span,
.mailroom-section .mailroomsentlist .case-file-extend-head form .ant-upload-list-item-card-actions button.ant-btn span {
  position: absolute;
  top: 0;
  right: 7px;
  color: white;
}
/* line 74, ../sass/_mailroom.scss */
.mailroom-section .mailroom {
  padding: 10px;
  margin-top: 12px;
}
/* line 78, ../sass/_mailroom.scss */
.mailroom-section .mailroom-create-receive-section {
  background-color: #eef7ff;
  padding: 10px;
  margin-top: 50px;
  margin-bottom: 30px;
}
/* line 84, ../sass/_mailroom.scss */
.mailroom-section .col-item-group {
  display: flex;
  flex-wrap: wrap;
}
/* line 88, ../sass/_mailroom.scss */
.mailroom-section .col-item-title,
.mailroom-section .col-tool {
  width: 100%;
}
/* line 92, ../sass/_mailroom.scss */
.mailroom-section .col-tool {
  text-align: right;
}
/* line 94, ../sass/_mailroom.scss */
.mailroom-section .col-tool button {
  color: #2492eb;
  padding: 5px 10px;
  margin: 0 10px;
  margin-bottom: 10px;
  border: 1px solid #2492eb;
  border-radius: 3px;
}
/* line 101, ../sass/_mailroom.scss */
.mailroom-section .col-tool button:hover {
  color: #ffffff;
  background-color: #2492eb;
}
/* line 107, ../sass/_mailroom.scss */
.mailroom-section .col-item {
  width: 50%;
  flex-shrink: 0;
}
/* line 110, ../sass/_mailroom.scss */
.mailroom-section .col-item .upload {
  width: 100%;
}
/* line 112, ../sass/_mailroom.scss */
.mailroom-section .col-item .upload i {
  margin-right: 5px;
}
/* line 115, ../sass/_mailroom.scss */
.mailroom-section .col-item .upload button {
  display: inline-block;
  padding: 0px 10px;
  color: #2492eb;
}
/* line 119, ../sass/_mailroom.scss */
.mailroom-section .col-item .upload button:hover {
  color: white;
}

/* line 127, ../sass/_mailroom.scss */
.ant-upload.ant-upload-select {
  vertical-align: top;
}

/* line 132, ../sass/_mailroom.scss */
.edit-sent .col-item,
.edit-received .col-item {
  display: flex;
  margin-bottom: 10px;
}
/* line 136, ../sass/_mailroom.scss */
.edit-sent .col-title,
.edit-received .col-title {
  flex-grow: 1;
  width: 8em;
  text-align: right;
}
/* line 141, ../sass/_mailroom.scss */
.edit-sent .col-edit,
.edit-received .col-edit {
  flex-grow: 1;
  width: 100%;
}

@media screen and (max-width: 576px) {
  /* line 151, ../sass/_mailroom.scss */
  .mailroom .mailroomreceivelist ul.case-list-head,
  .mailroom .mailroomsentlist ul.case-list-head {
    display: none;
  }
  /* line 155, ../sass/_mailroom.scss */
  .mailroom .mailroomreceivelist .case-file-extend-head form,
  .mailroom .mailroomsentlist .case-file-extend-head form {
    position: relative;
  }
  /* line 157, ../sass/_mailroom.scss */
  .mailroom .mailroomreceivelist .case-file-extend-head form > span,
  .mailroom .mailroomsentlist .case-file-extend-head form > span {
    width: 100%;
  }
  /* line 160, ../sass/_mailroom.scss */
  .mailroom .mailroomreceivelist .case-file-extend-head form button.upload,
  .mailroom .mailroomsentlist .case-file-extend-head form button.upload {
    flex-shrink: 0;
    position: absolute;
    top: 0;
    right: 0;
  }
  /* line 166, ../sass/_mailroom.scss */
  .mailroom .mailroomreceivelist .case-file-extend-head form .ant-upload-list-item,
  .mailroom .mailroomsentlist .case-file-extend-head form .ant-upload-list-item {
    width: 100%;
  }
  /* line 171, ../sass/_mailroom.scss */
  .mailroom .mailroomreceivelist ul.case-list-body,
  .mailroom .mailroomsentlist ul.case-list-body {
    flex-wrap: wrap;
  }
  /* line 174, ../sass/_mailroom.scss */
  .mailroom .mailroomreceivelist ul.case-list-body li[class^='head-'], .mailroom .mailroomreceivelist ul.case-list-body li[class^='body-'],
  .mailroom .mailroomsentlist ul.case-list-body li[class^='head-'],
  .mailroom .mailroomsentlist ul.case-list-body li[class^='body-'] {
    text-align: left;
    width: 50%;
    border-bottom: 1px solid #dddddd;
    text-align: left;
  }
  /* line 179, ../sass/_mailroom.scss */
  .mailroom .mailroomreceivelist ul.case-list-body li[class^='head-']:last-child, .mailroom .mailroomreceivelist ul.case-list-body li[class^='body-']:last-child,
  .mailroom .mailroomsentlist ul.case-list-body li[class^='head-']:last-child,
  .mailroom .mailroomsentlist ul.case-list-body li[class^='body-']:last-child {
    border-bottom: none;
  }
  /* line 183, ../sass/_mailroom.scss */
  .mailroom .mailroomreceivelist ul.case-list-body li[class^='head-']::before, .mailroom .mailroomreceivelist ul.case-list-body li[class^='body-']::before,
  .mailroom .mailroomsentlist ul.case-list-body li[class^='head-']::before,
  .mailroom .mailroomsentlist ul.case-list-body li[class^='body-']::before {
    content: attr(data-th);
  }
  /* line 187, ../sass/_mailroom.scss */
  .mailroom .mailroomreceivelist ul.case-list-body li[class$='-name'], .mailroom .mailroomreceivelist ul.case-list-body li[class$='-docketName'],
  .mailroom .mailroomsentlist ul.case-list-body li[class$='-name'],
  .mailroom .mailroomsentlist ul.case-list-body li[class$='-docketName'] {
    width: 100%;
  }
  /* line 194, ../sass/_mailroom.scss */
  .mailroom .mailroomFile {
    flex-grow: 1;
  }
}
/* line 2, ../sass/_appointment.scss */
.appointmentList ul.RWD-appointmentdetail-section {
  display: none;
}
/* line 9, ../sass/_appointment.scss */
.appointmentList .section-list ul.case-list-head li[class$='-docket'],
.appointmentList .section-list ul.case-list-body li[class$='-docket'] {
  text-align: left;
  flex-grow: 1;
}
/* line 13, ../sass/_appointment.scss */
.appointmentList .section-list ul.case-list-head li[class$='-docket'] span.ext:last-child,
.appointmentList .section-list ul.case-list-body li[class$='-docket'] span.ext:last-child {
  display: inline-block;
  margin-left: 3px;
}
/* line 21, ../sass/_appointment.scss */
.appointmentList .section-list .RWD-appointmentdetail,
.appointmentList .section-list .RWD-detail-tool {
  display: none;
}
/* line 26, ../sass/_appointment.scss */
.appointmentList .appointmentdetail-section {
  border-radius: 3px;
  border: 1px solid #cecece;
  overflow-y: auto;
}
/* line 31, ../sass/_appointment.scss */
.appointmentList .appointmentdetail-header-title {
  background-color: #f5f5f5;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  min-height: 40px;
}
/* line 38, ../sass/_appointment.scss */
.appointmentList .appointmentdetail-header-docket {
  display: flex;
  justify-content: space-between;
}
/* line 41, ../sass/_appointment.scss */
.appointmentList .appointmentdetail-header-docket button {
  width: 30px;
  line-height: 30px;
  margin: 3px 0px;
  margin-right: 10px;
  color: #989898;
}
/* line 47, ../sass/_appointment.scss */
.appointmentList .appointmentdetail-header-docket button:hover {
  background-color: #2492eb;
  border-radius: 5px;
  color: #ffffff;
}
/* line 54, ../sass/_appointment.scss */
.appointmentList .appointmentdetail-header-docket-wrap {
  flex-grow: 1;
}
/* line 57, ../sass/_appointment.scss */
.appointmentList .docketList {
  line-height: 30px;
  margin: 3px 0;
  padding: 0 5px;
  padding-left: 10px;
  position: relative;
}
/* line 63, ../sass/_appointment.scss */
.appointmentList .docketList span {
  display: inline-block;
  color: #888888;
}
/* line 66, ../sass/_appointment.scss */
.appointmentList .docketList span.level {
  margin-right: 5px;
}
/* line 69, ../sass/_appointment.scss */
.appointmentList .docketList span.ext:last-child {
  margin-left: 3px;
}
/* line 73, ../sass/_appointment.scss */
.appointmentList .docketList:hover {
  background-color: #f5f5f5;
}
/* line 75, ../sass/_appointment.scss */
.appointmentList .docketList:hover button.btn-del,
.appointmentList .docketList:hover button.btn-edit {
  display: inline-block;
}
/* line 80, ../sass/_appointment.scss */
.appointmentList .docketList button.btn-del,
.appointmentList .docketList button.btn-edit {
  display: none;
  position: absolute;
  margin: 0;
}
/* line 86, ../sass/_appointment.scss */
.appointmentList .docketList button.btn-del {
  right: 5px;
}
/* line 89, ../sass/_appointment.scss */
.appointmentList .docketList button.btn-edit {
  right: 40px;
}
/* line 94, ../sass/_appointment.scss */
.appointmentList .detail-tool a {
  margin-right: 5px;
}
/* line 97, ../sass/_appointment.scss */
.appointmentList .detail-tool i {
  display: inline-block;
  width: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 3px;
  color: #989898;
}
/* line 104, ../sass/_appointment.scss */
.appointmentList .detail-tool i:hover {
  color: #ffffff;
  background-color: #2492eb;
}
/* line 111, ../sass/_appointment.scss */
.appointmentList .appointmentdetail-tabpane .ant-tabs-nav {
  margin-bottom: 5px;
}
/* line 114, ../sass/_appointment.scss */
.appointmentList .appointmentdetail-tabpane .ant-tabs-nav-wrap {
  background-color: #f0f0f0;
}
/* line 117, ../sass/_appointment.scss */
.appointmentList .appointmentdetail-tabpane .ant-tabs-tab {
  padding-left: 10px;
  padding-right: 10px;
  margin: 0;
}
/* line 121, ../sass/_appointment.scss */
.appointmentList .appointmentdetail-tabpane .ant-tabs-tab + .ant-tabs-tab {
  margin: 0;
  margin-right: 5px;
}
/* line 125, ../sass/_appointment.scss */
.appointmentList .appointmentdetail-tabpane .ant-tabs-tab.ant-tabs-tab-active {
  color: #ffffff;
  background-color: #2492eb;
}
/* line 128, ../sass/_appointment.scss */
.appointmentList .appointmentdetail-tabpane .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffffff;
}
/* line 133, ../sass/_appointment.scss */
.appointmentList .appointmentdetail-tabpane .disabled {
  padding: 15px;
  display: inline-block;
}
/* line 141, ../sass/_appointment.scss */
.appointmentList .appointmentdates-section .col-item-group .col-item:first-child {
  width: 100%;
  margin-top: 34px;
}
/* line 145, ../sass/_appointment.scss */
.appointmentList .appointmentdates-section .col-item-group .col-item .col-title {
  width: 195px;
}
/* line 148, ../sass/_appointment.scss */
.appointmentList .appointmentdates-section .col-item-group .col-item .col-text {
  display: flex;
  justify-content: space-between;
}
/* line 152, ../sass/_appointment.scss */
.appointmentList .appointmentdates-section .col-item-group .col-item .col-edit {
  width: 170px;
  flex-grow: 0;
}
/* line 160, ../sass/_appointment.scss */
.appointmentList .case-file-extend-head .btn-empty {
  padding: 5px 7px;
}
/* line 162, ../sass/_appointment.scss */
.appointmentList .case-file-extend-head .btn-empty.spin {
  color: #2492eb;
  background-color: #fff;
  outline: 1px solid #2492eb;
}

@media screen and (max-width: 1024px) {
  /* line 172, ../sass/_appointment.scss */
  .appointmentList .section-list {
    padding-bottom: 50px;
  }
  /* line 174, ../sass/_appointment.scss */
  .appointmentList .section-list ul.case-list-head {
    display: none;
  }
  /* line 177, ../sass/_appointment.scss */
  .appointmentList .section-list ul.case-list-body {
    flex-wrap: wrap;
  }
  /* line 179, ../sass/_appointment.scss */
  .appointmentList .section-list ul.case-list-body li[class^='body-'] {
    width: 50%;
    text-align: left;
  }
  /* line 182, ../sass/_appointment.scss */
  .appointmentList .section-list ul.case-list-body li[class^='body-']::before {
    content: attr(data-th);
  }
  /* line 185, ../sass/_appointment.scss */
  .appointmentList .section-list ul.case-list-body li[class^='body-']:last-child {
    width: 100%;
  }
  /* line 190, ../sass/_appointment.scss */
  .appointmentList .section-list .RWD-appointmentdetail {
    display: block;
    border-left: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
  }
  /* line 195, ../sass/_appointment.scss */
  .appointmentList .section-list .RWD-detail-tool {
    display: block;
    border-top: 1px solid #e8e8e8;
  }
  /* line 198, ../sass/_appointment.scss */
  .appointmentList .section-list .RWD-detail-tool a,
  .appointmentList .section-list .RWD-detail-tool button {
    position: relative;
    display: inline-block;
    width: 25%;
    text-align: center;
    padding: 5px;
  }
  /* line 206, ../sass/_appointment.scss */
  .appointmentList .section-list .RWD-detail-tool a::after {
    content: '';
    padding-right: 1px;
    position: absolute;
    right: 0;
    height: 24px;
    background-color: #104378;
  }
  /* line 216, ../sass/_appointment.scss */
  .appointmentList ul.RWD-appointmentdetail-section {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    color: #ffffff;
    background-color: #104378;
    display: flex;
    flex-wrap: wrap;
  }
  /* line 225, ../sass/_appointment.scss */
  .appointmentList ul.RWD-appointmentdetail-section li {
    width: 25%;
  }
  /* line 228, ../sass/_appointment.scss */
  .appointmentList ul.RWD-appointmentdetail-section button {
    display: inline-block;
    width: 100%;
    padding: 15px 0;
    position: relative;
  }
  /* line 233, ../sass/_appointment.scss */
  .appointmentList ul.RWD-appointmentdetail-section button::after {
    content: '';
    width: 50%;
    padding-bottom: 1px;
    background-color: #ffffff;
    position: absolute;
    left: 25%;
    bottom: 7px;
  }
  /* line 244, ../sass/_appointment.scss */
  .appointmentList .appointmentdetail-section {
    display: none;
  }
}
/* line 2, ../sass/_attachment.scss */
#attachment-container .sideBar-container {
  width: 360px;
  height: 100%;
  transition: all 0.5s ease-in-out;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
}
/* line 12, ../sass/_attachment.scss */
#attachment-container .sideBar-container:not(.open) {
  position: absolute;
  left: -360px;
}
/* line 17, ../sass/_attachment.scss */
#attachment-container .sideBar-container:not(.open) + #top_bar .view_tool {
  padding-left: 75px;
}
/* line 25, ../sass/_attachment.scss */
#attachment-container .sideBar-container:hover .user-container .user-toolBar-active {
  opacity: 1;
}
/* line 31, ../sass/_attachment.scss */
#attachment-container .sideBar-container .user-container {
  position: relative;
  min-height: 60px;
  flex: 0 0 auto;
}
/* line 36, ../sass/_attachment.scss */
#attachment-container .sideBar-container .user-container:hover {
  cursor: pointer;
}
/* line 40, ../sass/_attachment.scss */
#attachment-container .sideBar-container .user-container .user-left {
  width: calc(100% - 40px);
  height: 100%;
  display: flex;
  align-items: center;
}
/* line 47, ../sass/_attachment.scss */
#attachment-container .sideBar-container .user-container .user-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
}
/* line 55, ../sass/_attachment.scss */
#attachment-container .sideBar-container .user-container .user-logo-wrapper {
  width: 55px;
  height: 60px;
  display: inline-flex;
  align-items: center;
  margin: 0;
  flex: 0 0 auto;
}
/* line 64, ../sass/_attachment.scss */
#attachment-container .sideBar-container .user-container .user-tab-wrapper {
  margin-bottom: 0;
  font-size: 24px;
  padding: 12px;
  margin-right: 10px;
  color: #13457a;
}
/* line 70, ../sass/_attachment.scss */
#attachment-container .sideBar-container .user-container .user-tab-wrapper:hover {
  color: #2693ec;
  background-color: #dbe4ea;
  border-radius: 30px;
}
/* line 77, ../sass/_attachment.scss */
#attachment-container .sideBar-container .user-container .user-logo {
  width: auto;
  height: 65%;
  margin: auto;
}
/* line 83, ../sass/_attachment.scss */
#attachment-container .sideBar-container .user-container .user-title-wrapper {
  width: 100%;
  margin: 0;
  display: inline-flex;
  flex-direction: column;
  align-self: baseline;
}
/* line 91, ../sass/_attachment.scss */
#attachment-container .sideBar-container .user-container .user-mainTitle {
  color: #104378;
  font-size: 18px;
  font-weight: 600;
  display: block;
  line-height: 30px;
  letter-spacing: 2px;
  line-height: 60px;
}
/* line 100, ../sass/_attachment.scss */
#attachment-container .sideBar-container .user-container .user-mainTitle .user-mainTitle-icon {
  height: 1rem;
  width: 20px;
  font-size: 0.9rem;
  text-align: right;
}
/* line 108, ../sass/_attachment.scss */
#attachment-container .sideBar-container .user-container .user-subTitle {
  font-size: 14px;
  vertical-align: middle;
  display: block;
  line-height: normal;
  word-break: break-all;
}
/* line 116, ../sass/_attachment.scss */
#attachment-container .sideBar-container .user-container .user-toolBar-active {
  width: 40px;
  text-align: center;
  opacity: 0;
}
/* line 122, ../sass/_attachment.scss */
#attachment-container .sideBar-container .user-container .toolBar-active-icon {
  width: 30px;
  height: 30px;
  font-size: 0.9rem;
  margin: 0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #454545;
}
/* line 134, ../sass/_attachment.scss */
#attachment-container .sideBar-container .user-container .toolBar-active-icon:hover {
  background-color: #2492eb;
  color: #ffffff;
}
/* line 143, ../sass/_attachment.scss */
#attachment-container .sideBar-container .generator-tool-container {
  padding: 3% 0;
  color: #454545;
  font-size: 0.95rem;
  cursor: pointer;
  flex: 0 0 auto;
}
/* line 150, ../sass/_attachment.scss */
#attachment-container .sideBar-container .generator-tool-container .generator-tool {
  margin: 0;
  line-height: 35px;
  padding: 0 5%;
}
/* line 155, ../sass/_attachment.scss */
#attachment-container .sideBar-container .generator-tool-container .generator-tool:hover {
  background-color: #dbe4ea;
  color: #104378;
}
/* line 161, ../sass/_attachment.scss */
#attachment-container .sideBar-container .generator-tool-container .generator-tool-label {
  padding: 0 10px;
}
/* line 165, ../sass/_attachment.scss */
#attachment-container .sideBar-container .generator-tool-container .generator-tool-icon {
  width: 20px;
  text-align: left;
}
/* line 169, ../sass/_attachment.scss */
#attachment-container .sideBar-container .generator-tool-container .generator-tool-icon.fa-folder-plus {
  font-size: 1rem;
}
/* line 175, ../sass/_attachment.scss */
#attachment-container .sideBar-container .active-bar {
  position: fixed;
  background-color: transparent;
  color: #454545;
  left: 0;
  right: auto;
}
/* line 182, ../sass/_attachment.scss */
#attachment-container .sideBar-container .active-bar:hover {
  background-color: #dbe4ea;
  color: #2492eb;
}
/* line 188, ../sass/_attachment.scss */
#attachment-container .sideBar-container .file-container {
  width: 100%;
  height: 100%;
  overflow: auto;
}
/* line 193, ../sass/_attachment.scss */
#attachment-container .sideBar-container .file-container.isSearchStage {
  font-size: .95rem;
  background-color: #f2f1ee;
}
/* line 197, ../sass/_attachment.scss */
#attachment-container .sideBar-container .file-container.isSearchStage .system_item {
  align-items: center;
  cursor: pointer;
  padding: 5px 10px;
}
/* line 202, ../sass/_attachment.scss */
#attachment-container .sideBar-container .file-container.isSearchStage .system_item:hover {
  background-color: #e8e7e4;
}
/* line 206, ../sass/_attachment.scss */
#attachment-container .sideBar-container .file-container.isSearchStage .system_item.selected {
  background-color: #e8e7e4;
}
/* line 211, ../sass/_attachment.scss */
#attachment-container .sideBar-container .file-container.isSearchStage .item_title-wrapper {
  display: inline-flex;
  flex-direction: column;
  width: calc(100% - 40px);
  margin: 0;
}
/* line 217, ../sass/_attachment.scss */
#attachment-container .sideBar-container .file-container.isSearchStage .item_title-wrapper .item_title {
  width: 100%;
}
/* line 221, ../sass/_attachment.scss */
#attachment-container .sideBar-container .file-container.isSearchStage .item_title-wrapper .item_subtitle {
  font-size: 0.9rem;
  color: #009688;
}
/* line 228, ../sass/_attachment.scss */
#attachment-container .sideBar-container .file-container .file-title {
  margin: 0 0 8px 0;
  color: #606060;
  font-weight: 600;
  font-size: 0.8rem;
  padding: 0 3%;
}
/* line 236, ../sass/_attachment.scss */
#attachment-container .sideBar-container .file-container .folder-wrapper {
  font-size: 15px;
}
/* line 240, ../sass/_attachment.scss */
#attachment-container .sideBar-container .file-container .folder-wrapper:hover .folder-name-wrapper {
  color: #104378;
  background-color: #bed2e0;
}
/* line 247, ../sass/_attachment.scss */
#attachment-container .sideBar-container .file-container .folder-name-wrapper {
  min-height: 35px;
  font-size: 1rem;
  border: none;
  font-weight: normal;
  color: #454545;
  cursor: pointer;
}
/* line 255, ../sass/_attachment.scss */
#attachment-container .sideBar-container .file-container .folder-name-wrapper.selected {
  color: #104378;
  background-color: #bed2e0;
}
/* line 262, ../sass/_attachment.scss */
#attachment-container .sideBar-container .file-container .folder-children .system_item {
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: 35px;
  height: auto;
  position: relative;
}
/* line 269, ../sass/_attachment.scss */
#attachment-container .sideBar-container .file-container .folder-children .system_item:hover, #attachment-container .sideBar-container .file-container .folder-children .system_item.selected {
  background-color: #dbe4ea;
}
/* line 275, ../sass/_attachment.scss */
#attachment-container .sideBar-container .file-container .item_title {
  width: calc(100% - 40px);
}
/* line 279, ../sass/_attachment.scss */
#attachment-container .sideBar-container .file-container .item_status {
  margin-right: 8px;
}
/* line 283, ../sass/_attachment.scss */
#attachment-container .sideBar-container .file-container .edit_tool-wrapper {
  width: 25px;
  height: 25px;
  font-size: 0.8rem;
  margin: 0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #454545;
  padding: 0;
}
/* line 296, ../sass/_attachment.scss */
#attachment-container .sideBar-container .file-container .edit_tool-wrapper:hover {
  background-color: #2492eb;
  color: #ffffff;
}
/* line 302, ../sass/_attachment.scss */
#attachment-container .sideBar-container .file-container .file-tool-popup {
  position: absolute;
  width: 250px;
  left: 90%;
  top: 55%;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
  border-radius: 5px;
  border: #cccccc solid 1px;
  padding: 5px 0;
  z-index: 10;
  color: #454545;
}
/* line 315, ../sass/_attachment.scss */
#attachment-container .sideBar-container .file-container .file-tool-popup .file-tool-wrapper {
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  margin: 0;
  cursor: pointer;
}
/* line 323, ../sass/_attachment.scss */
#attachment-container .sideBar-container .file-container .file-tool-popup .file-tool-wrapper:not(:last-of-type) {
  border-bottom: #dbe4ea solid thin;
}
/* line 327, ../sass/_attachment.scss */
#attachment-container .sideBar-container .file-container .file-tool-popup .file-tool-wrapper:hover {
  background-color: #dbe4ea;
}
/* line 332, ../sass/_attachment.scss */
#attachment-container .sideBar-container .file-container .file-tool-popup .file-tool-label {
  padding: 0 10px;
}
/* line 338, ../sass/_attachment.scss */
#attachment-container .sideBar-container .sideBar-line {
  margin: 12px 18px;
  flex: 0 0 auto;
}
/* line 345, ../sass/_attachment.scss */
#attachment-container .folder-children .system_item {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
/* line 352, ../sass/_attachment.scss */
#attachment-container .folder-children .system_item.failed {
  color: #cccccc;
  cursor: default;
}
/* line 357, ../sass/_attachment.scss */
#attachment-container .folder-children .system_item.pending {
  color: #cccccc;
  cursor: default;
}
/* line 364, ../sass/_attachment.scss */
#attachment-container .search-wrapper {
  position: relative;
  padding: 15px 20px;
  background-color: #f2f1ee;
}
/* line 369, ../sass/_attachment.scss */
#attachment-container .search-wrapper .search-icon {
  position: absolute;
  top: 23px;
  left: 28px;
  font-size: 0.9rem;
  color: #cccccc;
  margin-right: 0.5%;
  z-index: 1;
}
/* line 378, ../sass/_attachment.scss */
#attachment-container .search-wrapper .search-icon.search-clean {
  color: #2492eb;
  cursor: pointer;
}
/* line 382, ../sass/_attachment.scss */
#attachment-container .search-wrapper .search-icon.search-clean:hover {
  opacity: 0.8;
}
/* line 388, ../sass/_attachment.scss */
#attachment-container .search-wrapper .search-input {
  width: 100%;
  height: 40px;
  font-size: 0.95rem;
  padding: 5px 30px;
  border-radius: 0;
  box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
}
/* line 397, ../sass/_attachment.scss */
#attachment-container .search-wrapper .search-enter {
  position: absolute;
  top: 15px;
  right: 20px;
  width: 30px;
  height: calc(100% - 30px);
  font-size: 1rem;
  background-color: transparent;
  margin: 0;
  padding: 0;
  color: #606060;
  cursor: pointer;
  text-align: center;
  z-index: 1;
  border: none;
  outline: none;
}
/* line 414, ../sass/_attachment.scss */
#attachment-container .search-wrapper .search-enter:hover {
  background-color: #2492eb;
  color: #ffffff;
}
/* line 421, ../sass/_attachment.scss */
#attachment-container .search-close {
  font-size: 1.1rem;
  width: 100%;
  height: 60px;
  border: none;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  background-color: #dc3545;
}
/* line 431, ../sass/_attachment.scss */
#attachment-container .search-close .search-close-icon {
  margin-right: 1rem;
}
/* line 435, ../sass/_attachment.scss */
#attachment-container .search-close:hover {
  opacity: 0.8;
}
/* line 440, ../sass/_attachment.scss */
#attachment-container #left-bar {
  background-color: #f4f8fb;
}
/* line 444, ../sass/_attachment.scss */
#attachment-container #top_bar {
  position: relative;
}
/* line 447, ../sass/_attachment.scss */
#attachment-container #top_bar .view_tool {
  width: calc(100% - 15px);
  height: 60px;
  background-color: #ffffff;
  border: none;
  padding: 0 2% 0 3%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
  box-shadow: none;
}
/* line 460, ../sass/_attachment.scss */
#attachment-container #top_bar .pdf-view-container {
  height: 100%;
}
/* line 463, ../sass/_attachment.scss */
#attachment-container #top_bar .pdf-view-container .PdfHighlighter {
  padding: 60px 0;
}
/* line 468, ../sass/_attachment.scss */
#attachment-container #top_bar .view-bottom {
  width: calc(100% - 15px);
  height: 60px;
  background-color: #ffffff;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 9;
}
/* line 478, ../sass/_attachment.scss */
#attachment-container #top_bar .pages-wrapper {
  display: flex;
  align-items: center;
  width: 350px;
  height: 55px;
  border-radius: 10px;
}
/* line 485, ../sass/_attachment.scss */
#attachment-container #top_bar .pages-wrapper .page-count-wrapper {
  display: inline-flex;
  align-items: center;
}
/* line 490, ../sass/_attachment.scss */
#attachment-container #top_bar .pages-wrapper .pages-input-value {
  width: 50px;
  padding: 0;
  text-align: center;
}
/* line 496, ../sass/_attachment.scss */
#attachment-container #top_bar .pages-wrapper .pages-input-count {
  padding: 0 10px;
}
/* line 499, ../sass/_attachment.scss */
#attachment-container #top_bar .pages-wrapper .pages-input-count:before {
  content: "/";
  padding-right: 10px;
}
/* line 506, ../sass/_attachment.scss */
#attachment-container #top_bar .view-tool-left {
  width: 50%;
}
/* line 510, ../sass/_attachment.scss */
#attachment-container #top_bar .page-detail-title {
  font-weight: 600;
  letter-spacing: 1px;
  color: #454545;
}
/* line 516, ../sass/_attachment.scss */
#attachment-container #top_bar .view-tool-right {
  width: 50%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}
/* line 524, ../sass/_attachment.scss */
#attachment-container #top_bar .view-tool-wrapper {
  font-size: 0.95rem;
  cursor: pointer;
  min-width: 80px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 0 15px;
  margin: 0 0 0 15px;
  color: #454545;
}
/* line 537, ../sass/_attachment.scss */
#attachment-container #top_bar .view-tool-wrapper:hover {
  color: #104378;
  background-color: #dbe4ea;
}
/* line 542, ../sass/_attachment.scss */
#attachment-container #top_bar .view-tool-wrapper .view-tool-label {
  padding: 0 5px;
}
/* line 547, ../sass/_attachment.scss */
#attachment-container #top_bar .view-bottom {
  border: none;
}
/* line 553, ../sass/_attachment.scss */
#attachment-container .upload-attachment .modalbox-content {
  width: 400px;
  top: 45%;
  left: 50%;
  max-height: 70vh;
  overflow-y: auto;
}
/* line 561, ../sass/_attachment.scss */
#attachment-container .upload-attachment .modalbox-title {
  font-size: 1.1rem;
  text-align: center;
  line-height: 45px;
  margin-bottom: 5%;
  letter-spacing: 1px;
}
/* line 569, ../sass/_attachment.scss */
#attachment-container .upload-attachment .modalbox-upload-label {
  padding-left: 8px;
  font-style: normal;
}
/* line 578, ../sass/_attachment.scss */
#attachment-container .upload-attachment .col-item-tool .btn-outline-secondary {
  margin-right: 10px;
}
/* line 585, ../sass/_attachment.scss */
#attachment-container .text-view-container {
  padding: 70px 10%;
}

/* line 590, ../sass/_attachment.scss */
.attachment-popup {
  padding: 5% 0;
}
/* line 594, ../sass/_attachment.scss */
.attachment-popup .modal-uploadFile .popup-item-file {
  align-items: baseline;
}
/* line 599, ../sass/_attachment.scss */
.attachment-popup .popup-item-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
/* line 605, ../sass/_attachment.scss */
.attachment-popup .popup-item-label {
  width: 100px;
  flex: 0 0 auto;
  display: inline-block;
  font-weight: 600;
}
/* line 612, ../sass/_attachment.scss */
.attachment-popup .popup-item-value {
  width: calc(100% - 100px);
  flex: 1;
  display: inline-block;
  color: #2492eb;
}
/* line 618, ../sass/_attachment.scss */
.attachment-popup .popup-item-value .upload-btn {
  height: auto;
}
/* line 622, ../sass/_attachment.scss */
.attachment-popup .popup-item-value .upload-label {
  padding: 0 10px;
}

/* line 628, ../sass/_attachment.scss */
.user-toolBar-popup {
  position: absolute;
  width: 70%;
  left: 15%;
  top: 90%;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
  border-radius: 5px;
  border: #cccccc solid 1px;
  padding: 1% 0;
  z-index: 10;
  font-size: 0.9rem;
  color: #454545;
}
/* line 642, ../sass/_attachment.scss */
.user-toolBar-popup .toolBar-wrapper {
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  margin: 0;
  cursor: pointer;
}
/* line 650, ../sass/_attachment.scss */
.user-toolBar-popup .toolBar-wrapper:not(:last-of-type) {
  border-bottom: #dbe4ea solid thin;
}
/* line 654, ../sass/_attachment.scss */
.user-toolBar-popup .toolBar-wrapper:hover {
  background-color: #dbe4ea;
}
/* line 658, ../sass/_attachment.scss */
.user-toolBar-popup .toolBar-wrapper .toolBar-label {
  padding: 0 10px;
}

@media screen and (max-width: 1024px) {
  /* line 666, ../sass/_attachment.scss */
  #attachment-container .sideBar-container {
    position: fixed;
    width: 450px;
  }
  /* line 670, ../sass/_attachment.scss */
  #attachment-container .sideBar-container:not(.open) {
    position: fixed;
    left: -450px;
  }
  /* line 676, ../sass/_attachment.scss */
  #attachment-container .sideBar-container .user-container .user-toolBar-active {
    opacity: 1;
  }
  /* line 680, ../sass/_attachment.scss */
  #attachment-container .sideBar-container .user-container .toolBar-active-icon {
    background-color: #2492eb;
    color: #ffffff;
  }
}
/* line 1, ../sass/_appointmentNotes.scss */
.appointmentNotes-section {
  padding: 10px;
}
/* line 3, ../sass/_appointmentNotes.scss */
.appointmentNotes-section .case-list-head,
.appointmentNotes-section .case-list-body {
  display: flex;
  border: 1px solid #c1c1c1;
}
/* line 7, ../sass/_appointmentNotes.scss */
.appointmentNotes-section .case-list-head li,
.appointmentNotes-section .case-list-body li {
  padding: 5px 3px;
  flex-shrink: 0;
}
/* line 10, ../sass/_appointmentNotes.scss */
.appointmentNotes-section .case-list-head li[class$='-date'],
.appointmentNotes-section .case-list-body li[class$='-date'] {
  width: 80px;
  text-align: center;
}
/* line 14, ../sass/_appointmentNotes.scss */
.appointmentNotes-section .case-list-head li[class$='-fileName'],
.appointmentNotes-section .case-list-body li[class$='-fileName'] {
  width: 20%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/* line 20, ../sass/_appointmentNotes.scss */
.appointmentNotes-section .case-list-head li[class$='-filePage'],
.appointmentNotes-section .case-list-body li[class$='-filePage'] {
  width: 65px;
  text-align: center;
}
/* line 24, ../sass/_appointmentNotes.scss */
.appointmentNotes-section .case-list-head li[class$='-notes'],
.appointmentNotes-section .case-list-body li[class$='-notes'] {
  text-align: center;
  flex-grow: 1;
  width: 30%;
  flex-wrap: wrap;
}
/* line 29, ../sass/_appointmentNotes.scss */
.appointmentNotes-section .case-list-head li[class$='-notes'] div,
.appointmentNotes-section .case-list-body li[class$='-notes'] div {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/* line 33, ../sass/_appointmentNotes.scss */
.appointmentNotes-section .case-list-head li[class$='-notes'] div + div,
.appointmentNotes-section .case-list-body li[class$='-notes'] div + div {
  border-top: 1px solid #c1c1c1;
  padding-top: 3px;
  margin-top: 3px;
}
/* line 40, ../sass/_appointmentNotes.scss */
.appointmentNotes-section .case-list-head li[class$='-action'],
.appointmentNotes-section .case-list-body li[class$='-action'] {
  width: 30px;
  text-align: center;
}
/* line 48, ../sass/_appointmentNotes.scss */
.appointmentNotes-section .case-list-body + .case-list-body {
  border-top: none;
}
/* line 53, ../sass/_appointmentNotes.scss */
.appointmentNotes-section .case-list-body li[class$='-notes']:hover {
  color: #ffffff;
  background-color: #2492eb;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  /* line 67, ../sass/_appointmentNotes.scss */
  .appointmentNotes-section .case-list-body li[class$='-notes'] {
    width: 20%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex-wrap: wrap;
  }
}
/* line 1, ../sass/_previewNotes.scss */
.previewNotes-section {
  background-color: #ececec;
  min-height: 100vh;
}
/* line 4, ../sass/_previewNotes.scss */
.previewNotes-section .previewNotes-wrap {
  width: 900px;
  margin: auto;
}
/* line 8, ../sass/_previewNotes.scss */
.previewNotes-section .previewNotes-title {
  padding: 15px 0;
}
/* line 10, ../sass/_previewNotes.scss */
.previewNotes-section .previewNotes-title button {
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
}
/* line 15, ../sass/_previewNotes.scss */
.previewNotes-section .previewNotes-title button:focus {
  outline: none;
}
/* line 19, ../sass/_previewNotes.scss */
.previewNotes-section .previewNotes-title a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
/* line 24, ../sass/_previewNotes.scss */
.previewNotes-section .previewNotes-title .title {
  text-align: center;
  font-size: 1.25rem;
  margin-bottom: 10px;
}
/* line 30, ../sass/_previewNotes.scss */
.previewNotes-section .note-wrap {
  background-color: white;
  display: flex;
  min-height: 500px;
}
/* line 34, ../sass/_previewNotes.scss */
.previewNotes-section .note-wrap .image {
  width: 66.6%;
  padding-right: 10px;
  border-right: 2px dashed #dddddd;
}
/* line 39, ../sass/_previewNotes.scss */
.previewNotes-section .note-wrap .notes {
  padding: 10px;
  width: 33.3%;
}
/* line 44, ../sass/_previewNotes.scss */
.previewNotes-section .note-wrap .note + .note {
  border-top: 1px solid #c1c1c1;
  margin-top: 5px;
  padding-top: 5px;
}
/* line 50, ../sass/_previewNotes.scss */
.previewNotes-section .note-wrap .note-item {
  display: flex;
  margin-bottom: 5px;
}
/* line 53, ../sass/_previewNotes.scss */
.previewNotes-section .note-wrap .note-item .note-item-title {
  flex-shrink: 0;
}
/* line 57, ../sass/_previewNotes.scss */
.previewNotes-section .note-wrap .note-notes {
  border-top: 1px solid #c1c1c1;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

@media screen and (max-width: 768px) {
  /* line 67, ../sass/_previewNotes.scss */
  .previewNotes-section .previewNotes-wrap {
    width: 100%;
  }
  /* line 70, ../sass/_previewNotes.scss */
  .previewNotes-section .note-wrap {
    flex-direction: column;
  }
  /* line 72, ../sass/_previewNotes.scss */
  .previewNotes-section .note-wrap .image {
    width: 100%;
    padding-right: 0px;
    border-right: none;
    overflow: auto;
  }
  /* line 78, ../sass/_previewNotes.scss */
  .previewNotes-section .note-wrap .notes {
    width: 100%;
  }
  /* line 82, ../sass/_previewNotes.scss */
  .previewNotes-section .previewNotes-title {
    padding: 15px;
  }
}
@media print {
  /* line 89, ../sass/_previewNotes.scss */
  * {
    -webkit-print-color-adjust: exact !important;
  }

  /* line 92, ../sass/_previewNotes.scss */
  .previewNotes-section {
    background-color: transparent;
  }

  /* line 95, ../sass/_previewNotes.scss */
  .note-wrap {
    width: 100%;
    height: 100%;
  }

  /* line 99, ../sass/_previewNotes.scss */
  .break {
    display: block;
    page-break-before: always;
  }

  /* line 103, ../sass/_previewNotes.scss */
  .noPrint {
    display: none;
  }
}
@page {
  size: A4 portrait;
  margin-top: 1cm;
  margin-left: 1cm;
  margin-right: 1cm;
  margin-bottom: 1cm;
}
/* line 1, ../sass/_plan.scss */
#page-plan {
  font-size: 1.5rem;
}
/* line 3, ../sass/_plan.scss */
#page-plan ul.list-body {
  display: flex;
}
/* line 5, ../sass/_plan.scss */
#page-plan ul.list-body li {
  box-sizing: border-box;
  width: 20%;
  padding: 15px 10px;
}
/* line 9, ../sass/_plan.scss */
#page-plan ul.list-body li[class$='-plantContent'] {
  flex-grow: 1;
}
/* line 12, ../sass/_plan.scss */
#page-plan ul.list-body li[class$='-plus'] {
  text-align: center;
}
/* line 15, ../sass/_plan.scss */
#page-plan ul.list-body li button {
  border: none;
  border-radius: 5px;
  background-color: #2492eb;
  color: #ffffff;
  padding: 0 25px;
}
/* line 21, ../sass/_plan.scss */
#page-plan ul.list-body li button:focus {
  outline: none;
}
/* line 24, ../sass/_plan.scss */
#page-plan ul.list-body li button:hover {
  background-color: #53a9ef;
}
/* line 28, ../sass/_plan.scss */
#page-plan ul.list-body li.body-state {
  color: #2196f3;
}
/* line 33, ../sass/_plan.scss */
#page-plan .current-title,
#page-plan .current-capacity-title,
#page-plan .pay-cycle-title {
  padding: 10px;
  font-weight: bold;
  color: #4b4b4b;
  margin-bottom: 10px;
}
/* line 41, ../sass/_plan.scss */
#page-plan .current-title {
  border-bottom: 1px solid #4b4b4b;
}
/* line 44, ../sass/_plan.scss */
#page-plan .current-data {
  display: flex;
}
/* line 47, ../sass/_plan.scss */
#page-plan .current,
#page-plan .current-capacity,
#page-plan .pay-cycle {
  margin: 10px;
  padding: 20px 30px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
/* line 54, ../sass/_plan.scss */
#page-plan .current-capacity,
#page-plan .pay-cycle {
  width: 50%;
}
/* line 58, ../sass/_plan.scss */
#page-plan .current-capacity-use {
  padding: 0 10px;
  margin-top: 30px;
}
/* line 62, ../sass/_plan.scss */
#page-plan .pay-cycle-content {
  text-align: center;
}
/* line 65, ../sass/_plan.scss */
#page-plan .plus-plan {
  display: flex;
  margin-bottom: 60px;
}
/* line 69, ../sass/_plan.scss */
#page-plan .plan-item {
  width: 33.3%;
  margin: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  align-items: center;
}
/* line 74, ../sass/_plan.scss */
#page-plan .plan-item [class^='plan-item-'] {
  padding: 10px;
  text-align: center;
}
/* line 78, ../sass/_plan.scss */
#page-plan .plan-item .plan-item-title {
  margin-bottom: 15px;
  font-size: 1.85rem;
}
/* line 83, ../sass/_plan.scss */
#page-plan .plan-item .plan-item-plus button {
  border: none;
  border-radius: 5px;
  background-color: #2492eb;
  color: #ffffff;
  padding: 5px 0;
  width: 80%;
}
/* line 90, ../sass/_plan.scss */
#page-plan .plan-item .plan-item-plus button:focus {
  outline: none;
}
/* line 93, ../sass/_plan.scss */
#page-plan .plan-item .plan-item-plus button:hover {
  background-color: #53a9ef;
}
/* line 98, ../sass/_plan.scss */
#page-plan .plan-item .plan-item-txt {
  font-size: 1rem;
}

/* line 2, ../sass/_payment.scss */
#page-payment ul.list-head,
#page-payment ul.list-body {
  display: flex;
}
/* line 5, ../sass/_payment.scss */
#page-payment ul.list-head li,
#page-payment ul.list-body li {
  box-sizing: border-box;
  width: 20%;
  text-align: center;
  padding: 15px 10px;
}
/* line 10, ../sass/_payment.scss */
#page-payment ul.list-head li[class$='-plan'],
#page-payment ul.list-body li[class$='-plan'] {
  flex-grow: 1;
}
/* line 15, ../sass/_payment.scss */
#page-payment ul.list-head {
  background-color: #2492eb;
  color: #ffffff;
}
/* line 18, ../sass/_payment.scss */
#page-payment ul.list-head li + li {
  border-left: 1px solid #ffffff;
}
/* line 23, ../sass/_payment.scss */
#page-payment ul.list-body li {
  border-right: 1px solid #2492eb;
  border-bottom: 1px solid #2492eb;
}
/* line 26, ../sass/_payment.scss */
#page-payment ul.list-body li:first-child {
  border-left: 1px solid #2492eb;
}

/* line 1, ../sass/_folder.scss */
.creat-folder {
  display: flex;
}
/* line 3, ../sass/_folder.scss */
.creat-folder button {
  flex-shrink: 0;
}

/* line 7, ../sass/_folder.scss */
.folder {
  margin: 10px 15px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24);
}
/* line 11, ../sass/_folder.scss */
.folder ul li + li {
  border-top: thin solid #eeeeee;
}
/* line 14, ../sass/_folder.scss */
.folder ul li.folder-item {
  padding: 10px;
  line-height: 32px;
  display: flex;
  justify-content: space-between;
}
/* line 19, ../sass/_folder.scss */
.folder ul li.folder-item:hover {
  background-color: #eeeeee;
}
/* line 21, ../sass/_folder.scss */
.folder ul li.folder-item:hover .folder-item-tool {
  display: block;
}
/* line 25, ../sass/_folder.scss */
.folder ul li.folder-item button {
  width: 30px;
  height: 30px;
  border: 1px solid transparent;
  border-radius: 3px;
  margin-left: 3px;
}
/* line 31, ../sass/_folder.scss */
.folder ul li.folder-item button:hover {
  background-color: #2492eb;
  color: #ffffff;
}
/* line 36, ../sass/_folder.scss */
.folder ul li.folder-item .folder-item-text {
  flex-grow: 1;
}
/* line 39, ../sass/_folder.scss */
.folder ul li.folder-item .folder-item-tool {
  display: none;
  flex-shrink: 0;
}
/* line 42, ../sass/_folder.scss */
.folder ul li.folder-item .folder-item-tool.editing {
  display: block;
}

/* line 1, ../sass/_consultationsForm.scss */
#consultationsForm-component {
  background-color: #fbfbfb;
  min-height: 100vh;
  padding-bottom: 30px;
}
/* line 5, ../sass/_consultationsForm.scss */
#consultationsForm-component button {
  border: none;
  background-color: transparent;
}
/* line 8, ../sass/_consultationsForm.scss */
#consultationsForm-component button:focus {
  outline: none;
}
/* line 12, ../sass/_consultationsForm.scss */
#consultationsForm-component .lawyer-title-wrap {
  background-color: #ffffff;
  padding: 10px 0;
}
/* line 16, ../sass/_consultationsForm.scss */
#consultationsForm-component .lawyer-title {
  display: flex;
  color: #2492eb;
  font-size: 2rem;
}
/* line 20, ../sass/_consultationsForm.scss */
#consultationsForm-component .lawyer-title img {
  max-height: 72px;
}
/* line 23, ../sass/_consultationsForm.scss */
#consultationsForm-component .lawyer-title .lawyer {
  font-size: 1.5rem;
  margin: 0 30px;
}
/* line 28, ../sass/_consultationsForm.scss */
#consultationsForm-component .qr-title {
  text-align: center;
  font-size: 2.5rem;
  margin: 30px 0;
  letter-spacing: 3px;
}
@media screen and (max-width: 576px) {
  /* line 35, ../sass/_consultationsForm.scss */
  #consultationsForm-component .lawyer-title {
    flex-direction: column;
    font-size: 1rem;
  }
  /* line 38, ../sass/_consultationsForm.scss */
  #consultationsForm-component .lawyer-title img {
    max-height: 60px;
    width: auto;
    align-self: center;
  }
  /* line 43, ../sass/_consultationsForm.scss */
  #consultationsForm-component .lawyer-title .lawyer {
    font-size: 1rem;
    margin: 5px;
    align-self: center;
  }
  /* line 49, ../sass/_consultationsForm.scss */
  #consultationsForm-component .qr-title {
    font-size: 1.5rem;
    margin: 10px;
  }
}
/* line 54, ../sass/_consultationsForm.scss */
#consultationsForm-component .col-item {
  display: flex;
  margin-bottom: 10px;
}
/* line 58, ../sass/_consultationsForm.scss */
#consultationsForm-component .col-title {
  flex-shrink: 0;
  width: 6em;
  text-align: right;
  line-height: 40px;
}
/* line 64, ../sass/_consultationsForm.scss */
#consultationsForm-component .col-edit {
  flex-grow: 1;
}
/* line 66, ../sass/_consultationsForm.scss */
#consultationsForm-component .col-edit input[type="radio"] {
  display: none;
  opacity: 0;
}
/* line 69, ../sass/_consultationsForm.scss */
#consultationsForm-component .col-edit input[type="radio"]:checked + label[class^='edit-'] {
  border: 1px solid #2492eb;
  background-color: #2492eb;
  color: #ffffff;
}
/* line 75, ../sass/_consultationsForm.scss */
#consultationsForm-component .col-edit label[class^='edit-'] {
  margin-right: 10px;
  margin-bottom: 0;
  padding: 0 10px;
  line-height: 38px;
  border: 1px solid #dddddd;
  border-radius: 3px;
  color: #949494;
}
/* line 83, ../sass/_consultationsForm.scss */
#consultationsForm-component .col-edit label[class^='edit-']:hover {
  cursor: pointer;
  color: #2492eb;
  border: 1px solid #2492eb;
}
/* line 90, ../sass/_consultationsForm.scss */
#consultationsForm-component .submit {
  text-align: right;
  margin: 20px 0;
}
/* line 93, ../sass/_consultationsForm.scss */
#consultationsForm-component .submit button {
  border-radius: 10px;
  background-color: #2492eb;
  color: #ffffff;
  padding: 10px 15px;
}
/* line 99, ../sass/_consultationsForm.scss */
#consultationsForm-component .submit button:hover {
  background-color: #104378;
}

/* line 2, ../sass/_seminar.scss */
#page-seminar .index-header-logo {
  width: 400px;
  margin: auto;
  padding: 30px;
}
/* line 7, ../sass/_seminar.scss */
#page-seminar .seminar-main {
  width: 100%;
}
/* line 10, ../sass/_seminar.scss */
#page-seminar img {
  width: 100%;
}
/* line 13, ../sass/_seminar.scss */
#page-seminar a.link {
  margin-top: 10px;
  margin-bottom: 30px;
  font-size: 30px;
  display: block;
  margin: auto;
  width: 350px;
  background-color: #007bff;
  color: #ffffff;
  text-decoration: none;
  border-radius: 30px;
  text-align: center;
  padding: 10px 15px;
}

@media screen and (max-width: 768px) {
  /* line 30, ../sass/_seminar.scss */
  #page-seminar .index-header-logo {
    width: 100%;
  }
  /* line 33, ../sass/_seminar.scss */
  #page-seminar .seminar-main {
    margin-top: 30px;
  }
}
