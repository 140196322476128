.AreaHighlight {
  border: 1px solid #333;
  background-color: rgba(252, 232, 151, 1.0);
  opacity: 1;
  mix-blend-mode: multiply;
}

.AreaHighlight.Highlight--scrolledTo {
  background-color: #ff4141;
}
