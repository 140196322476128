@font-face {
  font-family: 'icon';
  src:  url('../fonts/icon.eot?b1yukp');
  src:  url('../fonts/icon.eot?b1yukp#iefix') format('embedded-opentype'),
    url('../fonts/icon.ttf?b1yukp') format('truetype'),
    url('../fonts/icon.woff?b1yukp') format('woff'),
    url('../fonts/icon.svg?b1yukp#icon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bin:before {
  content: "\e9ac";
}
.icon-plus:before {
  content: "\ea0a";
}
